import { APIQueryParamConfiguration } from './useAPIQueryParams.types'

export const USE_API_QUERY_PARAMS_DEFAULT_CONFIGURATION: APIQueryParamConfiguration = Object.freeze({
    isPersisted: true
})

export const PERSISTED_STORE_STATE: Record<string, any> = Object.create(null)
export const PERSISTED_STORE_VISIBILITY: Record<string, boolean> = Object.create(null)
export const GROUP_BY_CONFIG_FLAGS = {
    IS_GROUP_BY_RECORD_COLUMNS_VISIBLE: true,
    IS_GROUP_BY_WITH_EMPTY_RECORDS_VISIBLE: true
}

export const API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES = {
    COLLAPSED: -1,
    EXPANDED: 0
}
