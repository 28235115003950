import { SUPPORT_EMAIL } from '@/constants'
import { debounce } from 'lodash'
import { CONTACT_SUPPORT_DEBOUNCE_OPTIONS, CONTACT_SUPPORT_DEBOUNCE_WAIT } from './contactSupport.const'

const openMailToSupport = () => {
    globalThis.window.location.href = `mailto:${SUPPORT_EMAIL}`
}

const debouncedOpenMailToSupport = debounce(
    openMailToSupport,
    CONTACT_SUPPORT_DEBOUNCE_WAIT,
    CONTACT_SUPPORT_DEBOUNCE_OPTIONS
)

export const contactSupport = () => {
    debouncedOpenMailToSupport()
}
