export enum SupportedQueryParam {
    LEGAL_ENTITY_ID = 'legal_entity_id'
}

export type SupportedQueryParamValue = string | object | undefined

export type QueryParamsProviderContextValue = {
    getQueryParam: (key: SupportedQueryParam) => SupportedQueryParamValue
    setQueryParam: (key: SupportedQueryParam, value: SupportedQueryParamValue) => void
    removeQueryParam: (key: SupportedQueryParam) => void
}
