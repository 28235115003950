import { useMemo } from 'react'
import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { If } from '@/components/@misc'
import { WidgetProps, WidgetVariants } from './Widget.types'
import { isString } from 'lodash'

export const Widget: React.FC<WidgetProps> = ({ title, options, variant, children }) => {
    const hasTitle = useMemo(() => globalThis.Boolean(title), [title])
    const hasStringTitle = useMemo(() => isString(title), [title])
    const hasLiteWidgetVariant = useMemo(() => variant === WidgetVariants.Lite, [variant])
    const propsContent = useMemo(() => {
        return {
            minHeight: hasLiteWidgetVariant ? '28px' : '400px'
        }
    }, [hasLiteWidgetVariant])

    return (
        <Stack className="Widget" height="100%">
            <Flex className="Widget-Header" marginBottom="8px" gap="8px" justifyContent="space-between">
                <If condition={hasTitle}>
                    <If condition={hasStringTitle}>
                        <Text
                            className="Widget-Header-Title"
                            fontSize="16px"
                            lineHeight="24px"
                            color="gray.800"
                            fontWeight="400"
                            noOfLines={1}
                            aria-label={title as string}
                            title={title as string}>
                            {title}
                        </Text>
                    </If>
                    <If condition={!hasStringTitle}>{title}</If>
                </If>
                <If condition={!!options}>
                    <Box className="Widget-Header-Options">{options}</Box>
                </If>
            </Flex>
            <Box className="Widget-Content" height="inherit" {...propsContent}>
                {children}
            </Box>
        </Stack>
    )
}
