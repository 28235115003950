import { ApiObjectType, ApiObjectTypeSchema } from '@webapps/numeral-ui-core'
import { NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS } from '@/providers'
import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { addDays, differenceInMonths } from 'date-fns'
import { TableColumnCustomizers } from '@/components'
import { ReconciliationItem } from '@/services'
import { RECONCILIATIONS_WIDGET_COLUMN_CUSTOMIZERS } from './ReconciliationsWidget.const'
import { Box } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { TableCellReconciledStatus } from './@components'

/**
 * Converts a number of days into months using `date-fns`.
 * @returns The number of months between the start date and the calculated end date.
 */
export function daysToMonths(startDate: Date, days: number): number {
    const endDate = addDays(startDate, days)
    return differenceInMonths(endDate, startDate)
}

export function geReconciliationsWidgetColumnCustomizers(
    automationRatePeriod: number,
    timeUnit: string
): TableColumnCustomizers<ReconciliationItem> {
    const customizers = { ...RECONCILIATIONS_WIDGET_COLUMN_CUSTOMIZERS }

    customizers.total_reconciled = {
        header(info) {
            return (
                <Box whiteSpace="pre" textAlign="right">
                    <FormattedMessage
                        id="app.table.header.total_reconciled"
                        values={{ value: automationRatePeriod, unit: timeUnit }}
                    />
                </Box>
            )
        },
        cell(info) {
            const { reconciled_rate, object } = info.row.original
            return <TableCellReconciledStatus objectType={object} reconciledRate={reconciled_rate} />
        }
    }

    return customizers
}

export function getNavigationRouteByObjectType(objectType: ApiObjectType) {
    const { PAYMENTS, ACCOUNTS } = NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS

    switch (objectType) {
        case ApiObjectTypeSchema.enum.payment_order: {
            return PAYMENTS.PAYMENT_ORDERS
        }

        case ApiObjectTypeSchema.enum.incoming_payment: {
            return PAYMENTS.INCOMING_PAYMENTS
        }

        case ApiObjectTypeSchema.enum.expected_payment: {
            return PAYMENTS.EXPECTED_PAYMENTS
        }

        case ApiObjectTypeSchema.enum.return: {
            return PAYMENTS.RETURNS
        }

        case ApiObjectTypeSchema.enum.payment_capture: {
            return PAYMENTS.PAYMENT_CAPTURES
        }

        case ApiObjectTypeSchema.enum.transaction: {
            return ACCOUNTS.TRANSACTIONS
        }

        default: {
            return EMPTY_CHAR_SYMBOL
        }
    }
}
