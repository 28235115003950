import { LinkWithFilterBy } from '@/components/@misc'
import { NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS } from '@/providers'
import { Flex, UseDisclosureProps } from '@chakra-ui/react'
import {
    ExternalAccountsServiceFindAllQueryOptions,
    InternalAccountsServiceFindAllQueryOptions,
    PaymentOrdersServiceFindAllQueryOptions,
    Uuid
} from '@webapps/numeral-ui-core'
import { useMemo } from 'react'

interface AccountHolderDetailsRelatedObjectsProps extends UseDisclosureProps {
    accountHolderId?: Uuid
}

export const AccountHolderDetailsRelatedObjects: React.FC<AccountHolderDetailsRelatedObjectsProps> = ({
    accountHolderId
}) => {
    const { PAYMENTS, COUNTERPARTIES } = NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS

    const filterBy = useMemo(
        () => ({
            account_holder_id: accountHolderId
        }),
        [accountHolderId]
    )

    return (
        <Flex className="EntityDetailFileRelatedObjects" flexDirection="column" gap="8px">
            <LinkWithFilterBy<InternalAccountsServiceFindAllQueryOptions>
                labelKey="app.counterparties.account_holders.tabs.details.related_objects.internal_accounts_link.title"
                path={COUNTERPARTIES.INTERNAL_ACCOUNTS}
                filterBy={filterBy}
            />
            <LinkWithFilterBy<ExternalAccountsServiceFindAllQueryOptions>
                labelKey="app.counterparties.account_holders.tabs.details.related_objects.external_accounts_link.title"
                path={COUNTERPARTIES.EXTERNAL_ACCOUNTS}
                filterBy={filterBy} // TODO add the filter
            />
            <LinkWithFilterBy<PaymentOrdersServiceFindAllQueryOptions>
                labelKey="app.counterparties.account_holders.tabs.details.related_objects.payment_orders_link.title"
                path={PAYMENTS.PAYMENT_ORDERS}
                filterBy={filterBy}
            />
        </Flex>
    )
}
