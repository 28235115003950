import { Form, FormPageFooter, FormSection } from '@/components'
import {
    AmountInput,
    ConnectedAccountsInput,
    CurrencyInput,
    DateInput,
    PaymentDirectionInput,
    TextInput
} from '@/components/@inputs'
import { HIGHLIGHTED_CURRENCY_CODES } from '@/constants'
import { enabledConnectedAccountsFilter } from '@/services'
import { amountIntervalValidator, dateIntervalValidator } from '@/utils/@validators'
import { Box, Flex } from '@chakra-ui/react'
import { ExpectedPaymentCreateData } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { useMemo } from 'react'
import { ExpectedPaymentExternalAccountSection, ExpectedPaymentInternalAccountSection } from './@components'
import { ExpectedPaymentFormProps } from './ExpectedPayment.form.types'
import { getExpectedPaymentFormInitialValues } from './ExpectedPayment.form.utils'

export const ExpectedPaymentForm: React.FC<ExpectedPaymentFormProps> = ({
    data,
    disabled,
    loading,
    onSubmit = noop
}) => {
    const initialValues = useMemo<ExpectedPaymentCreateData>(() => {
        return getExpectedPaymentFormInitialValues(data)
    }, [data])

    return (
        <Form<ExpectedPaymentCreateData> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, dirty }) => {
                return (
                    <>
                        <FormSection titleId="app.common.form.sections.general_information.title">
                            <ConnectedAccountsInput
                                name="connected_account_id"
                                isPlaceholderDisplayed={false}
                                queryDataFilter={enabledConnectedAccountsFilter}
                                isRequired={true}
                            />
                            <PaymentDirectionInput name="direction" isRequired={true} />
                            <Flex gap="16px">
                                <Box width="320px">
                                    <CurrencyInput
                                        name="currency"
                                        highlightedCurrencyCodes={HIGHLIGHTED_CURRENCY_CODES}
                                        isRequired={true}
                                    />
                                </Box>
                                <AmountInput
                                    name="amount_from"
                                    placeholder="150.00"
                                    isDisabled={disabled}
                                    validator={amountIntervalValidator}
                                    isRequired={true}
                                />
                                <AmountInput
                                    name="amount_to"
                                    placeholder="200.00"
                                    isDisabled={disabled}
                                    validator={amountIntervalValidator}
                                    isRequired={true}
                                />
                            </Flex>
                            <Flex gap="16px">
                                <DateInput
                                    name="start_date"
                                    validator={dateIntervalValidator}
                                    max={values.end_date}
                                    isRequired={true}
                                />
                                <DateInput
                                    name="end_date"
                                    validator={dateIntervalValidator}
                                    min={values.start_date}
                                    isRequired={true}
                                />
                            </Flex>
                            <TextInput
                                name="descriptions[0]"
                                customLabelKey="app.common.form.input.description.label"
                                customPlaceholderKey="app.common.form.input.description.alternative.placeholder"
                                isDisabled={disabled}
                            />
                        </FormSection>

                        <ExpectedPaymentInternalAccountSection />
                        <ExpectedPaymentExternalAccountSection />

                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.payments.expected_payments.create.form.actions.submit.label"
                        />
                    </>
                )
            }}
        </Form>
    )
}
