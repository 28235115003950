import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'

export function useMutationResendByIdEvent(id: Uuid, options?: MutationOptions<void, Error>) {
    const { selectedUserAccess } = useAuth()
    const { eventsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<void, Error>({
        ...options,
        mutationKey: [eventsService.url, { id }],
        mutationFn(data) {
            return eventsService.resend(id, { params: paramsWithUserAccess })
        }
        // async onSuccess(...args) {
        //     options?.onSuccess?.(...args)

        //     await queryClient
        //         .invalidateQueries({
        //             queryKey: [eventsService.url]
        //         })
        //         .catch(noop)
        // }
    })
}
