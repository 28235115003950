import { RestrictedSection } from '@/components'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Box } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMemo } from 'react'
import { RoleCreateErrorNotification, RoleForm } from '../@components'
import { useMutationCreateRole, useNavigationRoutes, useToasts } from '@/hooks'
import { TOAST_VARIANTS } from '@/providers'
import { useNavigate } from 'react-router-dom'

export const RoleCreatePage: React.FC = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { paths, relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const title = useMemo(() => intl.formatMessage({ id: 'app.settings.roles.create.title' }), [intl])
    const mutation = useMutationCreateRole({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const { SETTINGS } = paths
            const path = [SETTINGS.ROLES, response.id, relativePaths.SETTINGS.DETAILS].join('/')

            navigate(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.settings.roles.create.form.validation.create.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.settings.roles.create.form.validation.create.success.toast.description" />
                )
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.settings.roles.create.form.validation.create.error.toast.title" />,
                description: <RoleCreateErrorNotification error={error} />
            })
        }
    })

    return (
        <PageLayout className="RoleCreate">
            <PageHeaderLayout title={title} />
            <RestrictedSection feature={ENTITY_FEATURE.CUSTOM_ROLE} permission={PERMISSION.SETTINGS_CREATE_ROLES}>
                <Box marginTop="8px">
                    <RoleForm
                        onSubmit={mutation.mutate}
                        loading={mutation.isPending}
                        disabled={mutation.isPending}
                        submitLabelMessageId="app.settings.roles.create.form.actions.submit.label"
                    />
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
