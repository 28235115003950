import { LinkWithFilterBy } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useNavigationRoutes } from '@/hooks'
import { Flex } from '@chakra-ui/react'
import {
    IncomingPaymentsServiceFindAllQueryOptions,
    InternalAccount,
    PaymentOrdersServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'

interface InternalAccountDetailsRelatedObjectsProps {
    internalAccount?: InternalAccount
}

export const InternalAccountDetailsRelatedObjects: React.FC<InternalAccountDetailsRelatedObjectsProps> = ({
    internalAccount
}) => {
    const { paths } = useNavigationRoutes()

    if (!internalAccount?.id) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Flex flexDirection="column" gap="8px">
            <LinkWithFilterBy<PaymentOrdersServiceFindAllQueryOptions>
                path={paths.PAYMENTS.PAYMENT_ORDERS}
                filterBy={{ internal_account_id: internalAccount.id }}
                labelKey="app.page.details.cells.related_objects.payment_orders.link"
            />
            <LinkWithFilterBy<IncomingPaymentsServiceFindAllQueryOptions>
                path={paths.PAYMENTS.INCOMING_PAYMENTS}
                filterBy={{ internal_account_id: internalAccount.id }}
                labelKey="app.page.details.cells.related_objects.incoming_payments.link"
            />
        </Flex>
    )
}
