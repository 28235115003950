import { MutableRefObject, useEffect, useMemo } from 'react'
import { Chart, ChartData, ChartTypeRegistry } from 'chart.js'
import { uniqueId } from 'lodash'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

export function useChartJSCleanupChartInstance<T>(
    chartRef: MutableRefObject<ChartJSOrUndefined<keyof ChartTypeRegistry, T>>,
    data?: ChartData<'bar' | 'line' | ('bar' & 'line'), T>
): string {
    const chartId = useMemo(() => uniqueId(chartRef.current?.id), [chartRef])

    useEffect(() => {
        return () => {
            const chartJSInstanceById = Chart.getChart(chartId)

            if (chartJSInstanceById) {
                chartJSInstanceById?.destroy()
            }
        }
    }, [data, chartId])

    return chartId
}
