import { useAuth, useServices } from '@/hooks'
import { User, filterUserAccessAPIQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationUpdateByIdUser(id?: Uuid, options?: MutationOptions<User, Error, User>) {
    const { selectedUserAccess } = useAuth()
    const { userService, usersService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = { ...filterUserAccessAPIQueryParams(selectedUserAccess) }

    return useMutation<User, Error, User>({
        ...options,
        mutationKey: [usersService.url, { id, ...paramsWithUserAccess }],
        mutationFn(data) {
            return usersService.updateById(id as string, data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            queryClient.setQueryData([usersService.url, { id }], args[0])

            await queryClient
                .invalidateQueries({
                    queryKey: [usersService.url, userService.url]
                })
                .catch(noop)
        }
    })
}
