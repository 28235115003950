import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams, PERMISSION, Webhook } from '@/services'
import { useQuery } from '@tanstack/react-query'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'
import { Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdWebhook(id?: Uuid, options?: Partial<UndefinedInitialDataOptions<Webhook, Error>>) {
    const { webhooksService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.DEVELOPERS_VIEW_WEBHOOKS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<Webhook, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [webhooksService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return webhooksService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
