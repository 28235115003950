import { FormikInput } from '@/types'

export enum GROUP_ACCORDION_DEFAULT_WILDCARDS {
    ARRAY_WILDCARD = 'admin',
    RECORD_WILDCARD = '*'
}

export type GroupRecordType = Record<string, string[]>
export type GroupItemType = string | { toString(): string }
export type GroupAccordionSupportedInputType = GroupItemType | GroupRecordType

export type GroupOptions = {
    [key: string]: string[]
}

export type GroupStats = {
    [key: string]: { total: number; selected: number }
}

export interface GroupAccordionInputBaseFormatters {
    // Group level formatters:
    getGroupLabel?(group: string, selected: number, available: number): string | undefined
    getGroupCounterLabel?(group: string, selected: number, available: number): string | undefined
    // Group option level formatters:
    getGroupOptionLabel?(group: string, value: string, selected: number, available: number): string | undefined
}

export interface GroupAccordionInputBaseProps<T extends GroupAccordionSupportedInputType>
    extends GroupAccordionInputBaseFormatters {
    value?: T[] | T
    options?: GroupAccordionSupportedInputType
    groupOrder?: string[]
    wildcardSymbol?: string

    optionsFilter?(option: string, index: number): boolean
}

export type GroupAccordionInputProps<T extends GroupAccordionSupportedInputType> = GroupAccordionInputBaseProps<T> &
    Omit<FormikInput, 'value'>

export type GroupAccordionInputTypeHookProps<T extends GroupAccordionSupportedInputType> =
    GroupAccordionInputProps<T> & {
        normalizedGroupOptions: GroupOptions
    }

export type GroupAccordionInputLabelFormattersHookProps = GroupAccordionInputBaseFormatters & {
    hasArrayShape?: boolean
}
