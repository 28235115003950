import { useActivatedFeatures, useAuth, usePermissions, useServices } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessAPIQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { Balance, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdBalance(id?: Uuid, options?: Partial<UndefinedInitialDataOptions<Balance, Error>>) {
    const { balancesService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isUserLoggedIn &&
        isFeatureActivated(ENTITY_FEATURE.BALANCE) &&
        hasPermission(PERMISSION.ACCOUNTS_VIEW_BALANCES) &&
        options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<Balance, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [balancesService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return balancesService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
