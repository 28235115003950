import { FormSection, InternalAccountDetailsSummary } from '@/components'
import { AccountDetailsForm, OptionalAccountHolderAddressForm } from '@/components/@forms'
import { InternalAccountInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import {
    createAccountFromConnectedAccount,
    enabledOwnInternalAccountFilter,
    isFinancialInstitutionConnectedAccount,
    isSDDPayment
} from '@/services'
import { Switch, Text } from '@chakra-ui/react'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { computePartialPaymentOrderFormFields } from '../PaymentOrderCreate.form.utils'

export interface PaymentOrderOriginatingAccountSectionProps {
    isDisabled?: boolean
}

export const PaymentOrderOriginatingAccountSection: React.FC<PaymentOrderOriginatingAccountSectionProps> = ({
    isDisabled
}) => {
    const { getFieldProps, setFieldValue } = useFormikContext<Partial<PaymentOrder>>()
    const { value: connectedAccountId } = getFieldProps('connected_account_id')
    const { value: type } = getFieldProps('type')
    const { value: direction } = getFieldProps('direction')
    const { value: originatingAccountId } = getFieldProps('originating_account_id')
    const { value: directDebitMandateId } = getFieldProps('direct_debit_mandate_id')

    const [useExistingInternalAccount, setUseExistingInternalAccount] = useState(false)

    const hasDirectDebitMandateId = useMemo(() => globalThis.Boolean(directDebitMandateId), [directDebitMandateId])

    const hasConnectedAccountId = useMemo(() => globalThis.Boolean(connectedAccountId), [connectedAccountId])
    const queryConnectedAccount = useQueryFindByIdConnectedAccount(connectedAccountId, {
        enabled: hasConnectedAccountId && !isDisabled
    })
    const hasFinancialInstitutionConnectedAccount = useMemo(
        () => isFinancialInstitutionConnectedAccount(queryConnectedAccount.data),
        [queryConnectedAccount]
    )
    const hasSDDPaymentType = useMemo(() => isSDDPayment(type, direction), [type, direction])

    const onToggleUseExistingInternalAccount = useCallback(() => {
        setUseExistingInternalAccount((prevState) => {
            return !prevState
        })
    }, [])

    useEffect(() => {
        if (!hasFinancialInstitutionConnectedAccount || hasDirectDebitMandateId) {
            setFieldValue('originating_account_id', undefined)
            setFieldValue('originating_account', undefined)
            return
        }

        if (useExistingInternalAccount) {
            setFieldValue('originating_account', undefined)
        }

        if (!useExistingInternalAccount) {
            const { originating_account } = computePartialPaymentOrderFormFields(queryConnectedAccount.data)
            const originatingAccount = hasFinancialInstitutionConnectedAccount
                ? createAccountFromConnectedAccount(queryConnectedAccount.data, hasSDDPaymentType, originating_account)
                : undefined

            setFieldValue('originating_account_id', undefined)
            setFieldValue('originating_account', originatingAccount)
        }
    }, [
        hasDirectDebitMandateId,
        hasFinancialInstitutionConnectedAccount,
        useExistingInternalAccount,
        hasSDDPaymentType,
        queryConnectedAccount.data
    ])

    useEffect(() => {
        if (hasDirectDebitMandateId) {
            setUseExistingInternalAccount(false)
            setFieldValue('receiving_account_id', undefined)
            setFieldValue('receiving_account', undefined)
        }
    }, [hasDirectDebitMandateId])

    return (
        <If condition={queryConnectedAccount.isLoading || hasFinancialInstitutionConnectedAccount}>
            <FormSection
                titleId="app.payments.payment_orders.create.form.sections.originating_account.title"
                title={
                    <Switch
                        data-testid="toggle-existing-originating-account-switch"
                        size="sm"
                        isChecked={useExistingInternalAccount}
                        onChange={onToggleUseExistingInternalAccount}
                        disabled={isDisabled || hasDirectDebitMandateId}>
                        <FormattedMessage id="app.payments.payment_orders.create.form.sections.originating_account.use_existing.label" />
                    </Switch>
                }>
                <If condition={hasDirectDebitMandateId}>
                    <Text color="gray.400">
                        <FormattedMessage id="app.payments.payment_orders.create.form.sections.originating_account.has_direct_debit_mandate_id.title" />
                    </Text>
                </If>

                <If condition={useExistingInternalAccount && !hasDirectDebitMandateId}>
                    <InternalAccountInput
                        name="originating_account_id"
                        isPlaceholderDisplayed={true}
                        isAccountVerificationStatusDisplayed={true}
                        customPlaceholderKey="app.payments.payment_orders.create.form.inputs.originating_account_id.placeholder"
                        queryDataFilter={enabledOwnInternalAccountFilter}
                        isDisabled={isDisabled}
                        isRequired={true}
                    />
                    <InternalAccountDetailsSummary internalAccountId={originatingAccountId} />
                </If>
                <If condition={!useExistingInternalAccount && !hasDirectDebitMandateId}>
                    <AccountDetailsForm
                        inputsNamePrefix="originating_account"
                        isCreditorIdentifierInputDisplayed={hasSDDPaymentType}
                        isBankCodeInputReadOnly={true}
                        isDisabled={isDisabled}
                        isRequired={true}
                    />
                    <OptionalAccountHolderAddressForm sectionName="originating_account" isDisabled={isDisabled} />
                </If>
            </FormSection>
        </If>
    )
}
