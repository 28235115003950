import {
    BaseHttpClientService,
    extractResponseData,
    List,
    Pagination,
    ServicesConfiguration,
    Uuid
} from '@webapps/numeral-ui-core'
import { Role } from './Roles.service.types'

export class RolesService extends BaseHttpClientService {
    get url() {
        return '/v1/roles'
    }

    async findAll(params: Partial<Pagination>, config?: ServicesConfiguration): Promise<List<Role>> {
        return this.httpClient
            .get<List<Role>>(this.url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    async findById(id: Uuid, config?: ServicesConfiguration): Promise<Role> {
        return this.httpClient.get<Role>(`${this.url}/${id}`, config).then(extractResponseData)
    }

    async createRole(data: Role, config?: ServicesConfiguration): Promise<Role> {
        return this.httpClient.post<Role>(this.url, data, config).then(extractResponseData)
    }

    async updateById(id: Uuid, data: Role, config?: ServicesConfiguration): Promise<Role> {
        return this.httpClient.post<Role>(`${this.url}/${id}`, data, config).then(extractResponseData)
    }
}
