import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { MutationOptions, useMutation } from '@tanstack/react-query'
import { ExportPayload, PaymentFilesServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export function useMutationExportPaymentFiles(
    options?: MutationOptions<void, Error, ExportPayload<PaymentFilesServiceFindAllQueryOptions>>
) {
    const { selectedUserAccess } = useAuth()
    const { paymentFilesService } = useServices()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<void, Error, ExportPayload<PaymentFilesServiceFindAllQueryOptions>>({
        ...options,
        mutationKey: [paymentFilesService.url],
        mutationFn(data) {
            return paymentFilesService.export(data, {
                params: paramsWithUserAccess
            })
        }
    })
}
