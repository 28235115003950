import {
    FilterInputs,
    TableCellAmount,
    TableCellShortDate,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import {
    ConnectedAccountsInput,
    CurrencyInput,
    DateInput,
    PaymentOrderTypeInput,
    ReconciliationStatusInput,
    ReturnRequestRelatedPaymentTypeInput,
    ReturnRequestStatusInput,
    TextInput
} from '@/components/@inputs'
import {
    ConnectedAccountName,
    DetailPageLink,
    FormattedTextIntl,
    ReconciliationStatusLabel,
    StatusLabel
} from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, HIGHLIGHTED_CURRENCY_CODES, MIN_COLUMN_WIDTH } from '@/constants'
import { APIQueryParamQuickFilters } from '@/hooks'
import { uuidValidator } from '@/utils/@validators'
import {
    ReconciliationStatusSchema,
    Return,
    ReturnsServiceFindAllQueryOptions,
    ReturnStatusSchema
} from '@webapps/numeral-ui-core'
import { ReturnsCustomColumns } from './Returns.page.types'

export const RETURN_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/return-object`

export const RETURN_TABLE_STATIC_STATE: TableStateWithEntity<Return, ReturnsCustomColumns> = {
    columnOrder: Object.freeze([
        'created_at',
        'direction',
        'returned_amount',
        'return_reason',
        'connected_account_id',
        'related_objects',
        'value_date',
        'status',
        'reconciliation_status'
    ])
}

export const RETURN_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<Return, ReturnsCustomColumns> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    direction: {
        size: COLUMN_WIDTH_RATIO.DIRECTION,
        minSize: MIN_COLUMN_WIDTH.DIRECTION,
        cell(info) {
            const { related_payment_type } = info.row.original

            return <FormattedTextIntl messageId={`app.payments.returns.direction.${related_payment_type}.label`} />
        }
    },
    returned_amount: {
        minSize: MIN_COLUMN_WIDTH.AMOUNT,
        cell(info) {
            const { currency } = info.row.original
            return <TableCellAmount amount={info.getValue()} currency={currency} />
        }
    },
    connected_account_id: {
        size: COLUMN_WIDTH_RATIO.CONNECTED_ACCOUNT,
        cell(info) {
            return <ConnectedAccountName connectedAccountId={info.getValue()} asPlainText={true} />
        }
    },
    related_objects: {
        cell(info) {
            const { related_payment_type, related_payment_id } = info.row.original
            return <DetailPageLink objectId={related_payment_id} objectType={related_payment_type} />
        }
    },
    value_date: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    status: {
        minSize: MIN_COLUMN_WIDTH.SHORT_STATUS,
        cell(info) {
            return <StatusLabel value={info.getValue()} />
        }
    },
    reconciliation_status: {
        minSize: MIN_COLUMN_WIDTH.RECONCILIATION_STATUS,
        cell(info) {
            return <ReconciliationStatusLabel value={info.getValue()} />
        }
    }
}

export const RETURN_FILTERS: FilterInputs<ReturnsServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    start_value_date: <DateInput name="start_value_date" />,
    end_value_date: <DateInput name="end_value_date" />,
    type: <PaymentOrderTypeInput name="type" />,
    currency: <CurrencyInput name="currency" highlightedCurrencyCodes={HIGHLIGHTED_CURRENCY_CODES} />,
    status: <ReturnRequestStatusInput name="status" />,
    related_payment_type: <ReturnRequestRelatedPaymentTypeInput name="related_payment_type" />,
    related_payment_id: <TextInput name="related_payment_id" validator={uuidValidator} />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    file_id: <TextInput name="file_id" validator={uuidValidator} />,
    reconciliation_status: <ReconciliationStatusInput name="reconciliation_status" />
}

export const RETURN_QUICK_FILTERS: APIQueryParamQuickFilters<ReturnsServiceFindAllQueryOptions> = {
    status: [ReturnStatusSchema.enum.rejected],
    reconciliation_status: [ReconciliationStatusSchema.enum.unreconciled]
}
