import { ConnectedAccountsInput } from '@/components/@inputs'
import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { createDirectDebitMandateConnectedAccountsCombinedFilter } from '@/services'
import { FormikInput } from '@/types'
import { DirectDebitMandate, Uuid } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback } from 'react'

export const DirectDebitMandateConnectedAccountInput: React.FC<FormikInput> = ({ name, isDisabled, ...inputProps }) => {
    const { setFieldValue, resetForm } = useFormikContext<DirectDebitMandate>()
    const onChange = useCallback(
        (value: Uuid) => {
            setFieldValue('connected_account_id', value)
            // Derived fields
            setFieldValue('type', EMPTY_CHAR_SYMBOL, false)
            setFieldValue('sequence', EMPTY_CHAR_SYMBOL, false)
        },
        [setFieldValue]
    )

    return (
        <ConnectedAccountsInput
            {...inputProps}
            name={name}
            onChange={onChange}
            isDisabled={isDisabled}
            isPlaceholderDisplayed={false}
            queryDataFilter={createDirectDebitMandateConnectedAccountsCombinedFilter}
        />
    )
}
