import {
    Amount,
    ApiObjectType,
    ApiObjectTypeSchema,
    ExpectedPayment,
    ExpectedPaymentsServiceFindAllQueryOptions,
    IncomingPaymentsServiceFindAllQueryOptions,
    PaymentCaptureServiceFindAllQueryOptions,
    PaymentOrdersServiceFindAllQueryOptions,
    ReconciliationStatusSchema,
    ReturnsServiceFindAllQueryOptions,
    Transaction,
    TransactionsServiceFindAllQueryOptions,
    Uuid
} from '@webapps/numeral-ui-core'
import {
    getReconcilableAmount,
    isEntityReconciled,
    PERMISSION,
    SourceReconciliationObject,
    TargetReconciliationObject
} from '@/services'
import {
    BulkActions,
    NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS,
    NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
} from '@/providers'
import { ReconcileEntityFooterAction, ReconcileEntityFooterContent } from './@components'
import { Currency, dinero, equal } from 'dinero.js'
import { getDineroCurrency } from '@/utils'
import { Payment } from '@/types'
import { chain } from 'lodash'

export function areMoreThanOneTargetObjectsReconciling<T extends Transaction | Payment>(objects?: T[]): boolean {
    if (!objects) {
        return false
    }

    return objects?.length > 1
}

export function aggregateReconcilableAmounts<T extends Transaction | Payment>(objects: T[]): Amount {
    return objects?.reduce((acc, item) => {
        acc += getReconcilableAmount(item)
        return acc
    }, 0)
}

export function areExpectedPaymentsReconciledAmountsValid(objects?: ExpectedPayment[]) {
    return objects
        ?.filter((value) => {
            return value?.object === ApiObjectTypeSchema.enum.expected_payment
        })
        .every((value) => {
            const dineroCurrency = getDineroCurrency(value?.currency) as Currency<number>
            const amountFrom = dinero({
                amount: value.amount_from,
                currency: dineroCurrency
            })
            const amountTo = dinero({
                amount: value.amount_to,
                currency: dineroCurrency
            })

            return equal(amountFrom, amountTo)
        })
}

/**
 * @description
 * Selected (target {object_type}) statuses have to be different from "reconciled"
 * the source's {object_type} amount.
 */
export function areTargetReconciledStatusesValid<T extends Transaction | Payment>(objects: T[]): boolean {
    return !objects?.some(isEntityReconciled)
}

/**
 * @description
 * Selected (target {object_type}) amounts have to lower and equal compared to
 * the source's {object_type} amount.
 */
export function isTargetReconcilableAmountLowerOrEqualThanSource<T extends Transaction | Payment>(
    sourceObject?: SourceReconciliationObject<T>,
    targetObjects?: TargetReconciliationObject<T>[]
): boolean {
    if (!sourceObject || !targetObjects?.length) {
        return false
    }

    const sourceObjectReconcilableAmount: Amount = getReconcilableAmount(sourceObject)
    const targetObjectsReconcilableAmount: Amount = aggregateReconcilableAmounts(targetObjects)

    return targetObjectsReconcilableAmount <= sourceObjectReconcilableAmount
}

export function getReconcilableTargetObjectAbsolutePath(targetObjectType: ApiObjectType) {
    const { PAYMENTS, ACCOUNTS } = NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS

    switch (targetObjectType) {
        case ApiObjectTypeSchema.enum.payment_order: {
            return PAYMENTS.PAYMENT_ORDERS
        }

        case ApiObjectTypeSchema.enum.incoming_payment: {
            return PAYMENTS.INCOMING_PAYMENTS
        }

        case ApiObjectTypeSchema.enum.expected_payment: {
            return PAYMENTS.EXPECTED_PAYMENTS
        }

        case ApiObjectTypeSchema.enum.return: {
            return PAYMENTS.RETURNS
        }

        case ApiObjectTypeSchema.enum.payment_capture: {
            return PAYMENTS.PAYMENT_CAPTURES
        }

        case ApiObjectTypeSchema.enum.transaction: {
            return ACCOUNTS.TRANSACTIONS
        }
    }
}

export function getReconcilableTargetObjectRelativeReconciliationsPath(targetObjectType: ApiObjectType) {
    const { PAYMENTS, ACCOUNTS } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS

    switch (targetObjectType) {
        case ApiObjectTypeSchema.enum.payment_order: {
            return PAYMENTS.RECONCILIATIONS
        }

        case ApiObjectTypeSchema.enum.incoming_payment: {
            return PAYMENTS.RECONCILIATIONS
        }

        case ApiObjectTypeSchema.enum.expected_payment: {
            return PAYMENTS.RECONCILIATIONS
        }

        case ApiObjectTypeSchema.enum.return: {
            return PAYMENTS.RECONCILIATIONS
        }

        case ApiObjectTypeSchema.enum.payment_capture: {
            return PAYMENTS.RECONCILIATIONS
        }

        case ApiObjectTypeSchema.enum.transaction: {
            return ACCOUNTS.RECONCILIATIONS
        }
    }
}

export function enrichReconciliationTargetObjectsSelection<T extends Transaction | Payment>(
    selection?: Set<Uuid>,
    targetObjects?: TargetReconciliationObject<T>[]
) {
    const targetObjectsWrapper = chain(targetObjects)

    return chain(selection)
        .toArray()
        .map((id) => targetObjectsWrapper.find((item) => item?.id === id).value())
        .value()
}

export function getReconcileEntityBulkActions(
    isSingleSelect?: boolean
): BulkActions<
    | PaymentOrdersServiceFindAllQueryOptions
    | IncomingPaymentsServiceFindAllQueryOptions
    | ExpectedPaymentsServiceFindAllQueryOptions
    | ReturnsServiceFindAllQueryOptions
    | PaymentCaptureServiceFindAllQueryOptions
    | TransactionsServiceFindAllQueryOptions
> {
    const configuration = {
        configuration: {
            isSingleSelect,
            isSelectAllDisabled: true,
            permissions: [PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS]
        },
        actionComponent: ReconcileEntityFooterAction,
        contentComponent: ReconcileEntityFooterContent
    }

    return {
        [ReconciliationStatusSchema.enum.partially_reconciled]: configuration,
        [ReconciliationStatusSchema.enum.unreconciled]: configuration
    }
}
