import { Table, TableProps } from '@/components'
import { useBulkActions, usePermissions, useQuickFilter } from '@/hooks'
import { useMemo } from 'react'
import { getTableRowSelectColumnDef, isBulkActionPermissionEnabled, isBulkActionsActive } from '../..'
import { BulkActionsTableCustomColumn } from '../../BulkActionsProvider.types'

export function BulkActionsTable<T, C = void>(props: TableProps<T, BulkActionsTableCustomColumn<C>>) {
    const { state } = useQuickFilter()
    const { actions, isActive } = useBulkActions()
    const { hasPermission } = usePermissions()
    const hasBulkActionPermissionEnabled = useMemo(() => {
        return isBulkActionPermissionEnabled(hasPermission, actions)
    }, [hasPermission, actions])

    const tableState = useMemo(() => {
        if (hasBulkActionPermissionEnabled && isActive)
            return {
                ...props.state,
                columnOrder: ['select' as keyof BulkActionsTableCustomColumn, ...props.state.columnOrder]
            }

        return props.state
    }, [hasBulkActionPermissionEnabled, isActive, props.state])

    const tableCustomizers = useMemo(() => {
        if (hasBulkActionPermissionEnabled && isActive) {
            return {
                ...props?.columnCustomizers,
                ['select' as keyof BulkActionsTableCustomColumn]: getTableRowSelectColumnDef<T>()
            }
        }

        return props?.columnCustomizers
    }, [hasBulkActionPermissionEnabled, isActive, actions, props?.columnCustomizers, state])

    return (
        <Table<T, BulkActionsTableCustomColumn<C>>
            {...props}
            state={tableState}
            columnCustomizers={tableCustomizers as any /*@todo Types fix later*/}
        />
    )
}
