import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams, Role } from '@/services'
import { addTransformRequestsToHttpClientInstance, markedFieldsTransform } from '@/services/@transformRequests'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { noop } from 'lodash'
import { APIError } from '@/types'
import { AxiosError } from 'axios'

export function useMutationCreateRole(
    options?: MutationOptions<Partial<Role> | void, AxiosError<APIError<Role>>, Role>
) {
    const { selectedUserAccess } = useAuth()
    const { rolesService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<Role | void, AxiosError<APIError<Role>>, Role>({
        ...options,
        mutationKey: [rolesService.url],
        mutationFn(data) {
            return rolesService.createRole(data, {
                params: paramsWithUserAccess,
                transformRequest: addTransformRequestsToHttpClientInstance(rolesService, markedFieldsTransform)
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [rolesService.url]
                })
                .catch(noop)
        }
    })
}
