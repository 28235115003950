import './WidgetLiteTable.scss'
import { Table, TableProps } from '@/components'
import { Box } from '@chakra-ui/react'
import { useRef } from 'react'
import classNames from 'classnames'

interface WidgetLiteTableProps<T> extends TableProps<T> {
    className?: string
}

export function WidgetLiteTable<T>({ className, ...props }: WidgetLiteTableProps<T>) {
    const extendedClassname: string = useRef(classNames('WidgetLiteTable', className)).current

    return (
        <Box className={extendedClassname}>
            <Table<T>
                {...props}
                hasStickyHeader={false}
                propsTableContainer={{
                    border: 'unset !important'
                }}
                propsHeader={{
                    backgroundColor: 'transparent !important',
                    borderBottom: '1px solid transparent',
                    borderColor: 'gray.100'
                }}
            />
        </Box>
    )
}
