import {
    EntityDetailCellFileSize,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers
} from '@/components'
import {
    ConnectedAccountsName,
    FileFormat,
    FormattedDateTime,
    FormattedTextIntl,
    StatusLabel
} from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { NumeralFile } from '@webapps/numeral-ui-core'
import { FileDetailsRelatedObjects } from './@components'
import { FileDetailCustomRows } from './FileDetails.page.types'

export const FILE_DETAILS_ROWS: EntityDetailRow<NumeralFile, FileDetailCustomRows>[] = [
    'id',
    'created_at',
    'filename',
    'direction',
    'category',
    'format',
    'size',
    'connected_account_ids',
    'related_objects',
    'status',
    'status_details',
    'bank_data'
]

export const FILE_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<NumeralFile, FileDetailCustomRows> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    filename: {
        cell(item) {
            return item.filename || EMPTY_VALUE_PLACEHOLDER
        }
    },
    direction: {
        cell(item) {
            return (
                <FormattedTextIntl
                    messageId={`api.files.direction.${item.direction}`}
                    defaultMessage={item.direction}
                />
            )
        }
    },
    format: {
        cell(item) {
            return <FileFormat value={item.format} />
        }
    },
    size: {
        cell(item) {
            return <EntityDetailCellFileSize sizeInBytes={item.size} />
        }
    },
    connected_account_ids: {
        cell(item) {
            return <ConnectedAccountsName connectedAccountIds={item.connected_account_ids} />
        }
    },
    related_objects: {
        cell(item) {
            return <FileDetailsRelatedObjects file={item} />
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    bank_data: {
        nested: true
    }
}
