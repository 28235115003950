import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdAccountHolder } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { AccountHolder, ApiObjectTypeSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { ACCOUNT_HOLDER_DETAILS_ROW_CUSTOMIZERS, ACCOUNT_HOLDER_DETAILS_ROWS } from './AccountHolderDetails.page.const'
import { AccountHolderCustomRows } from './AccountHolderDetails.page.types'

export const AccountHolderDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdAccountHolder(uuid)

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.COUNTERPARTIES_VIEW_ACCOUNT_HOLDERS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.account_holder}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<AccountHolder, AccountHolderCustomRows>
                        data={query.data}
                        rows={ACCOUNT_HOLDER_DETAILS_ROWS}
                        rowCustomizers={ACCOUNT_HOLDER_DETAILS_ROW_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
