import { QueryFallback, RestrictedSection, Table, TableHeader, useTableRowNavigation } from '@/components'
import { useQueryFindAllRoles } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION, Role, User } from '@/services'
import { queryDataAggregation } from '@/utils'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'
import { ROLES_TABLE_COLUMN_CUSTOMIZERS, ROLES_TABLE_STATIC_STATE } from './Roles.page.const'
import { useNavigate } from 'react-router-dom'
import { useActivatedFeatures, useNavigationRoutes, usePermissions } from '@/hooks'
import { useIntl } from 'react-intl'

export const RolesPage: React.FC = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()
    const { isFeatureActivated } = useActivatedFeatures()
    const query = useQueryFindAllRoles()
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const onNavigateToNew = useCallback(() => {
        navigate(relativePaths.NEW)
    }, [navigate, relativePaths])
    const onTableRowClick = useTableRowNavigation<Role>('id')
    const actions = useMemo(() => {
        if (isFeatureActivated(ENTITY_FEATURE.CUSTOM_ROLE) && hasPermission(PERMISSION.SETTINGS_CREATE_ROLES)) {
            return [
                {
                    onClick: onNavigateToNew,
                    children: intl.formatMessage({
                        id: 'app.settings.roles.create.title'
                    })
                }
            ]
        }
    }, [hasPermission, isFeatureActivated])

    return (
        <PageLayout className="Roles">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_ROLES}>
                <TableHeader<Role> objectType={ApiObjectTypeExtendedSchema.enum.role} actions={actions} />
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.role}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}>
                    <Table<Role>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        columnCustomizers={ROLES_TABLE_COLUMN_CUSTOMIZERS}
                        state={ROLES_TABLE_STATIC_STATE}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
