import { FilterInputs, TableHeaderForm } from '@/components'
import { EMPTY_CHAR_SYMBOL } from '@/constants'
import {
    API_QUERY_PARAMS_RESERVED_NAMES,
    APIQueryParamConfigurationFilterBy,
    APIQueryParamStateFilterBy
} from '@/hooks'
import { flattenDeep } from '@/utils'
import { chain, get, set } from 'lodash'
import { pickFilterByValues } from '../../TableHeader.utils'

export function countActiveFiltersValues<T>(filterState?: TableHeaderForm<T>) {
    const filters = flattenDeep(pickFilterByValues<T>(filterState))
    return chain(filters).values().filter(globalThis.Boolean).filter().size().value()
}

export function removeReservedWordsFilter(filterName: string) {
    return !Object.values(API_QUERY_PARAMS_RESERVED_NAMES).includes(filterName as API_QUERY_PARAMS_RESERVED_NAMES)
}

export function getFilterByInitialValues<T>(filters?: FilterInputs<T>): APIQueryParamStateFilterBy<T> {
    if (!filters) {
        return Object.create(null)
    }

    return Object.keys(filters)
        .filter(removeReservedWordsFilter)
        .reduce((acc, key) => {
            return set(acc, key, EMPTY_CHAR_SYMBOL)
        }, Object.create(null))
}

/**
 * @description
 * Check if 'inputName' property exists in the quickFilters of truth and is present in both obj1 and obj2
 */
export function isFilterByInputDisabled<T>(
    inputName: string,
    quickFilters?: APIQueryParamConfigurationFilterBy<T>['quickFilters'],
    quickFilterState?: APIQueryParamStateFilterBy<T>,
    formState?: APIQueryParamStateFilterBy<T>
): boolean {
    return chain(quickFilters)
        .get(inputName)
        .thru((resultArray) => {
            if (globalThis.Array.isArray(resultArray) && resultArray.length > 0) {
                const targetValueOne = get(quickFilterState, inputName)
                const targetValueTwo = get(formState, inputName)

                return resultArray.some((result) => targetValueOne === result && targetValueTwo === result)
            }
            return false
        })
        .value()
}
