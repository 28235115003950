import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useNavigationRoutes, usePermissions, useQueryFindByIdAccountHolder } from '@/hooks'
import { PERMISSION } from '@/services'
import { Link, Skeleton, Text } from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React, { MouseEvent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RestrictedData } from '../RestrictedData'

interface AccountHolderNameProps {
    accountHolderId?: Uuid
    asPlainText?: boolean
}

export const AccountHolderName: React.FC<AccountHolderNameProps> = React.memo(({ accountHolderId, asPlainText }) => {
    const navigate = useNavigate()
    const { paths, relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdAccountHolder(accountHolderId, {
        enabled: globalThis.Boolean(accountHolderId)
    })
    const onClick = useCallback(
        (event: MouseEvent<HTMLAnchorElement>) => {
            const path = `${paths.COUNTERPARTIES.ACCOUNT_HOLDERS}/${accountHolderId}/${relativePaths.COUNTERPARTIES.DETAILS}`
            event.stopPropagation()
            navigate(path)
        },
        [paths, relativePaths, navigate, accountHolderId]
    )

    switch (true) {
        case !hasPermission(PERMISSION.COUNTERPARTIES_VIEW_ACCOUNT_HOLDERS): {
            return <RestrictedData />
        }
        case query.isLoading: {
            return <Skeleton height="14px" width="50%" />
        }

        case !accountHolderId:
        case query.isError:
        case isEmpty(query.data?.name): {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        case asPlainText: {
            return <Text as="span">{query.data?.name}</Text>
        }

        default: {
            return (
                <Link onClick={onClick} title={query.data?.name}>
                    {query.data?.name}
                </Link>
            )
        }
    }
})
