import { useAuth, usePermissions, useServices } from '@/hooks'
import {
    filterUserAccessAPIQueryParams,
    PERMISSION,
    ProcessedPaymentsTotalAmount,
    WIDGET_TYPE,
    WidgetsServiceFindAllQueryOptions
} from '@/services'
import { APIError } from '@/types'
import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { List } from '@webapps/numeral-ui-core'

export function useQueryProcessedPaymentsTotalAmounts(
    params: WidgetsServiceFindAllQueryOptions,
    options?: Partial<UndefinedInitialDataOptions<List<ProcessedPaymentsTotalAmount>, APIError>>
) {
    const { widgetsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()
    const isQueryEnabled =
        isUserLoggedIn && hasPermission(PERMISSION.HOME_VIEW_PROCESSED_PAYMENTS_TOTAL_AMOUNTS) && options?.enabled

    const paramsWithUserAccess: typeof params = {
        ...params,
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<List<ProcessedPaymentsTotalAmount>, APIError>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [widgetsService.url, paramsWithUserAccess, WIDGET_TYPE.ProcessedPaymentsTotalAmounts],
        queryFn({ signal }) {
            return widgetsService.processedPaymentsTotalAmounts(paramsWithUserAccess, { signal })
        }
    })
}
