import { ExternalAccountDetailsSummary, FormSection } from '@/components'
import { AccountDetailsForm, OptionalAccountHolderAddressForm } from '@/components/@forms'
import { ExternalAccountsInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { approvedExternalAccountsFilter } from '@/services'
import { Switch, Text } from '@chakra-ui/react'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

export interface PaymentOrderReceivingAccountSectionProps {
    isDisabled?: boolean
}

export const PaymentOrderReceivingAccountSection: React.FC<PaymentOrderReceivingAccountSectionProps> = ({
    isDisabled
}) => {
    const { getFieldProps, setFieldValue } = useFormikContext<PaymentOrder>()
    const { value: receivingAccountId } = getFieldProps('receiving_account_id')
    const { value: directDebitMandateId } = getFieldProps('direct_debit_mandate_id')

    const [useExistingExternalAccount, setUseExistingExternalAccount] = useState(false)

    const hasDirectDebitMandateId = useMemo(() => globalThis.Boolean(directDebitMandateId), [directDebitMandateId])

    const onToggleUseExistingExternalAccount = useCallback(() => {
        setFieldValue('receiving_account_id', undefined)
        setFieldValue('receiving_account', undefined)

        setUseExistingExternalAccount((prevState) => {
            return !prevState
        })
    }, [])

    useEffect(() => {
        if (hasDirectDebitMandateId) {
            setUseExistingExternalAccount(false)
            setFieldValue('receiving_account_id', undefined)
            setFieldValue('receiving_account', undefined)
        }
    }, [hasDirectDebitMandateId])

    return (
        <FormSection
            titleId="app.payments.payment_orders.create.form.sections.receiving_account.title"
            title={
                <Switch
                    data-testid="toggle-existing-receiving-account-switch"
                    size="sm"
                    isChecked={useExistingExternalAccount}
                    onChange={onToggleUseExistingExternalAccount}
                    disabled={isDisabled || hasDirectDebitMandateId}>
                    <FormattedMessage id="app.payments.payment_orders.create.form.sections.receiving_account.use_existing.label" />
                </Switch>
            }>
            <If condition={hasDirectDebitMandateId}>
                <Text color="gray.400">
                    <FormattedMessage id="app.payments.payment_orders.create.form.sections.receiving_account.has_direct_debit_mandate_id.title" />
                </Text>
            </If>

            <If condition={useExistingExternalAccount && !hasDirectDebitMandateId}>
                <ExternalAccountsInput
                    name="receiving_account_id"
                    value={receivingAccountId}
                    isPlaceholderDisplayed={true}
                    isAccountVerificationStatusDisplayed={true}
                    customPlaceholderKey="app.payments.payment_orders.create.form.inputs.receiving_account_id.placeholder"
                    queryDataFilter={approvedExternalAccountsFilter}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <ExternalAccountDetailsSummary externalAccountId={receivingAccountId} />
            </If>
            <If condition={!useExistingExternalAccount && !hasDirectDebitMandateId}>
                <AccountDetailsForm
                    inputsNamePrefix="receiving_account"
                    isLookupAvailable={true}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <OptionalAccountHolderAddressForm sectionName="receiving_account" isDisabled={isDisabled} />
            </If>
        </FormSection>
    )
}
