import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { MutationOptions, useMutation } from '@tanstack/react-query'
import { ExportPayload, TransactionsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export function useMutationExportBalances(
    options?: MutationOptions<void, Error, ExportPayload<TransactionsServiceFindAllQueryOptions>>
) {
    const { selectedUserAccess } = useAuth()
    const { balancesService } = useServices()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<void, Error, ExportPayload<TransactionsServiceFindAllQueryOptions>>({
        ...options,
        mutationKey: [balancesService.url],
        mutationFn(data) {
            return balancesService.export(data, {
                params: paramsWithUserAccess
            })
        }
    })
}
