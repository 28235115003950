import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Flex } from '@chakra-ui/react'
import { ExternalAccount, ExternalAccountVerificationService } from '@webapps/numeral-ui-core'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { getVerificationMessageIconFromResult } from './ExternalAccountVerificationResultDetails.utils'

type CounterpartyAccountVerificationResultDetailsProps = {
    externalAccount: ExternalAccount
    verificationService: ExternalAccountVerificationService
}

export const ExternalAccountVerificationResultDetails: React.FC<CounterpartyAccountVerificationResultDetailsProps> =
    memo(({ externalAccount, verificationService }) => {
        const verification = externalAccount?.account_verification?.[verificationService]
        const result = verification?.result
        const reason = verification?.details.reason
        const expectedValue = verification?.details?.expected_value
        const accountNumber = externalAccount?.account_number
        const message = verification?.details.message

        const icon = getVerificationMessageIconFromResult(result)
        let messageKey = `app.counterparties.external_accounts.tabs.verifications.${verificationService}.${result}`

        if (!externalAccount?.account_verification || !result) {
            return <>{EMPTY_VALUE_PLACEHOLDER} </>
        }

        if (reason) {
            messageKey += `.${reason}`
        }

        return (
            <Flex alignItems="center" gap="8px">
                {icon}
                <FormattedMessage id={messageKey} defaultMessage={message} values={{ expectedValue, accountNumber }} />
            </Flex>
        )
    })
