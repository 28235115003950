import {
    EntityDetailCellBoolean,
    EntityDetailCellHolderAddress,
    EntityDetailCellServicesActivated,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers
} from '@/components'
import { BankCode, ConnectedAccountLatestBalances, EnabledStatus, FormattedDateTime } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { accountNumberFormatter, whitespaceFormatter } from '@/utils/@formatters'
import { ConnectedAccount } from '@webapps/numeral-ui-core'
import { FormattedMessage } from 'react-intl'
import { ConnectedAccountDetailsRelatedObjects } from './@components'
import { ConnectedAccountDetailsCustomRows } from './ConnectedAccountDetails.types'

export const CONNECTED_ACCOUNT_DETAILS_ROWS: EntityDetailRow<ConnectedAccount, ConnectedAccountDetailsCustomRows>[] = [
    'id',
    'name',
    'account_number',
    'bank_name',
    'bank_code',
    'address',
    'latest_balances',
    'status',
    'type',
    'services_activated', // Features activated
    'creditor_identifier',
    'file_auto_approval',
    'disabled_at',
    'created_at',
    'related_objects',
    'metadata'
]

export const CONNECTED_ACCOUNT_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    ConnectedAccount,
    ConnectedAccountDetailsCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    name: {
        label() {
            return <FormattedMessage id="app.page.details.fields.account_name.label" />
        }
    },
    account_number: {
        cell(item) {
            const formattedValue = accountNumberFormatter(item.account_number)
            return <EntityDetailCellTextWithCopyButton value={formattedValue} formatter={whitespaceFormatter} />
        }
    },
    bank_code: {
        cell(item) {
            return <BankCode value={item.bank_code} />
        }
    },
    address: {
        cell(item) {
            return <EntityDetailCellHolderAddress value={item.address} />
        }
    },
    latest_balances: {
        cell(item) {
            return <ConnectedAccountLatestBalances connectedAccountId={item.id} />
        }
    },
    status: {
        cell(item) {
            const isDisabled = !!item.disabled_at
            return <EnabledStatus isDisabled={isDisabled} />
        }
    },
    type: {
        label() {
            return <FormattedMessage id="app.page.details.fields.account_type.label" />
        },
        cell(item) {
            return (
                <FormattedMessage
                    id={`api.connected_accounts.type.${item.type}`}
                    defaultMessage={EMPTY_VALUE_PLACEHOLDER}
                />
            )
        }
    },
    services_activated: {
        cell(item) {
            return <EntityDetailCellServicesActivated value={item.services_activated} />
        }
    },
    file_auto_approval: {
        cell(item) {
            return <EntityDetailCellBoolean value={item.file_auto_approval} />
        }
    },
    disabled_at: {
        cell(item) {
            return <FormattedDateTime value={item.disabled_at} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    related_objects: {
        cell(item) {
            return <ConnectedAccountDetailsRelatedObjects connectedAccountId={item.id} />
        }
    },

    metadata: {
        nested: true
    }
}
