import {
    QueryFallback,
    RestrictedSection,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import { SupportEmailMailtoLink } from '@/components/@misc'
import {
    APIQueryParamTypes,
    useAPIQueryParams,
    useMutationExportReturn,
    useNavigationRoutes,
    usePage,
    useQueryFindAllReturns,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { getReconcileEntityBulkActions, ReconcileEntityDynamicHeader } from '@/pages'
import { ReturnsCustomColumns } from '@/pages/Payments/Returns/Returns.page.types'
import { BulkActionsProvider, BulkActionsTable, QuickFilterProvider, TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { ApiObjectTypeSchema, Return, ReturnsServiceFindAllQueryOptions, Transaction } from '@webapps/numeral-ui-core'
import { get, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import {
    RETURN_DOCUMENTATION_LINK,
    RETURN_FILTERS,
    RETURN_QUICK_FILTERS,
    RETURN_TABLE_COLUMN_CUSTOMIZERS,
    RETURN_TABLE_STATIC_STATE
} from './Returns.page.const'

export const ReturnsPage: React.FC = () => {
    const page = usePage<Transaction>()
    const location = useLocation()
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.returns.title' }), [intl])
    const { search, filterBy } = useAPIQueryParams<ReturnsServiceFindAllQueryOptions>('search', {
        filterBy: {
            configuration: {
                preselected: get(location.state, APIQueryParamTypes.FilterBy),
                filters: RETURN_FILTERS,
                quickFilters: RETURN_QUICK_FILTERS
            }
        }
    })

    const query = useQueryFindAllReturns({ ...search.parsedState, ...filterBy.parsedState })
    const mutationExport = useMutationExportReturn({
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.INFO,
                status: 'info',
                title: <FormattedMessage id="app.common.export.success.toast.title" />,
                description: <FormattedMessage id="app.common.export.success.toast.description" />
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.export.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.common.export.error.toast.description"
                        values={{ supportEmailMailtoLink: <SupportEmailMailtoLink /> }}
                    />
                )
            })
        }
    })

    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const actions = useMemo<TableHeaderActionProps[]>(() => {
        if (page.hasActiveContext) {
            return []
        }

        return [
            {
                onClick() {
                    mutationExport.mutate({ search_parameters: { ...search.parsedState, ...filterBy.parsedState } })
                },
                children: intl.formatMessage({ id: 'app.common.actions.export' }),
                isDisabled: isDataEmpty || mutationExport.isPending
            }
        ]
    }, [page, intl, isDataEmpty, mutationExport, search.parsedState, filterBy.parsedState])

    const bulkActions = useMemo(() => {
        return getReconcileEntityBulkActions(!page.hasActiveContext)
    }, [page])

    const onTableRowClick = useTableRowNavigation<Return>('id', relativePaths.PAYMENTS.DETAILS)

    return (
        <PageLayout className="Returns">
            <ReconcileEntityDynamicHeader>
                <PageHeaderLayout title={title} showBackButton={false} />
            </ReconcileEntityDynamicHeader>
            <RestrictedSection feature={ENTITY_FEATURE.RETURN} permission={PERMISSION.PAYMENTS_VIEW_RETURNS}>
                <QuickFilterProvider<ReturnsServiceFindAllQueryOptions> filterBy={filterBy}>
                    <BulkActionsProvider<Return, ReturnsServiceFindAllQueryOptions>
                        targetObject={ApiObjectTypeSchema.enum.return}
                        actions={bulkActions}
                        filterBy={filterBy}
                        search={search}
                        data={data}>
                        <TableHeader<ReturnsServiceFindAllQueryOptions>
                            objectType={ApiObjectTypeExtendedSchema.enum.return}
                            isLoading={query.isLoading}
                            actions={actions}
                            filterBy={filterBy}
                            search={search}
                        />
                        <QueryFallback
                            objectType={ApiObjectTypeSchema.enum.return}
                            isLoading={query.isLoading}
                            isSuccess={query.isSuccess}
                            isError={query.isError}
                            isDataEmpty={isDataEmpty}
                            isFilteringOrSearching={!!search?.isActive || !!filterBy?.isActive}
                            learnMoreLinkUrl={RETURN_DOCUMENTATION_LINK}>
                            <BulkActionsTable<Return, ReturnsCustomColumns>
                                data={data}
                                onRowClick={onTableRowClick}
                                onScrollToBottom={query.fetchNextPage}
                                isLoading={query.hasNextPage}
                                state={RETURN_TABLE_STATIC_STATE}
                                columnCustomizers={RETURN_TABLE_COLUMN_CUSTOMIZERS}
                            />
                        </QueryFallback>
                    </BulkActionsProvider>
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
