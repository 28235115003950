import { Icon, IconProps } from '@chakra-ui/react'

export const CollapseIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0001 15.85V8.14998L12.1501 12L16.0001 15.85ZM5.1001 18.9H7.9251V5.09998H5.1001V18.9ZM9.8001 18.9H18.9001V5.09998H9.8001V18.9ZM3.2251 20.775V3.22498H20.7751V20.775H3.2251Z"
        />
    </Icon>
)
