import { QueryParamContext } from '@/providers/QueryParamsProvider/QueryParamsProvider.context'
import { useContext } from 'react'

export function useQueryParams() {
    const context = useContext(QueryParamContext)

    if (!context) {
        throw new Error(`Must be used in the scope of ${QueryParamContext.displayName}`)
    }

    return context
}
