import React, { MutableRefObject, useState } from 'react'
import { Circle, Flex, Skeleton, Text, Tooltip } from '@chakra-ui/react'
import { ChartDataset, ChartTypeRegistry } from 'chart.js/dist/types'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { SPACE_SYMBOL } from '@/constants'
import { ChartProps } from '@/components/@charts'

export interface ChartLegendProps<T> extends Pick<ChartProps, 'isDisabled' | 'isLoading'> {
    chartRef: MutableRefObject<ChartJSOrUndefined<keyof ChartTypeRegistry, T>>
    dataset?: ChartDataset<any, T>[]
}

export function ChartLegend<T>({ chartRef, dataset, isDisabled, isLoading }: ChartLegendProps<T>) {
    const [datasetVisibility, setDatasetVisibility] = useState<Record<number, boolean>>(Object.create(null))

    if (!dataset) {
        return null
    }

    return (
        <Flex className="ChartLegend" marginTop="24px" gap="8px" userSelect="none" flexWrap="wrap">
            {dataset?.map((item, index) => {
                const backgroundColor = datasetVisibility[index] ? 'gray.100' : 'transparent'

                const onLegendClick = () => {
                    if (isDisabled) {
                        return
                    }

                    setDatasetVisibility((prevState) => ({
                        ...prevState,
                        [index]: !prevState[index]
                    }))

                    if (chartRef.current) {
                        chartRef.current?.setDatasetVisibility(index, datasetVisibility[index])
                        chartRef.current?.update()
                    }
                }

                return (
                    <Skeleton isLoaded={!isLoading} key={index}>
                        <Flex
                            data-testid="chart-legend"
                            borderWidth="1px"
                            alignContent="center"
                            fontWeight="normal"
                            paddingY="5px"
                            paddingX="8px"
                            gap="8px"
                            borderRadius="var(--numeral-ui-primary-border-radius)"
                            borderColor="gray.100"
                            alignItems="center"
                            flexWrap="nowrap"
                            backgroundColor={backgroundColor}
                            onClick={onLegendClick}
                            _hover={{
                                backgroundColor: 'unset'
                            }}>
                            <Circle
                                size="8px"
                                backgroundColor={item?.backgroundColor as string}
                                data-testid="chart-legend-circle"
                            />
                            {SPACE_SYMBOL}
                            <Tooltip hasArrow={true} label={item?.label}>
                                <Text
                                    color="gray.500"
                                    maxWidth="180px"
                                    fontSize="12px"
                                    noOfLines={1}
                                    data-testid="chart-legend-label">
                                    {item?.label}
                                </Text>
                            </Tooltip>
                        </Flex>
                    </Skeleton>
                )
            })}
        </Flex>
    )
}
