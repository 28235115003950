import { useCallback } from 'react'
import { useField } from 'formik'
import {
    GROUP_ACCORDION_DEFAULT_WILDCARDS,
    GroupAccordionInputTypeHookProps,
    GroupAccordionSupportedInputType,
    GroupRecordType
} from '../../GroupAccordionInput.types'
import { anythingValidator } from '@/utils/@validators'
import { useIntl } from 'react-intl'

export function useGroupAccordionInputRecord<T extends GroupAccordionSupportedInputType>({
    name,
    isDisabled,
    isReadOnly,
    isRequired,
    normalizedGroupOptions,
    validator = anythingValidator,
    wildcardSymbol = GROUP_ACCORDION_DEFAULT_WILDCARDS.RECORD_WILDCARD
}: GroupAccordionInputTypeHookProps<T>) {
    const intl = useIntl()
    const [field, meta, helpers] = useField<GroupRecordType>({
        name,
        readOnly: isReadOnly,
        disabled: isDisabled,
        validate: validator?.(intl, isRequired)
    })
    const { setValue } = helpers
    const handleCheckboxChange = useCallback(
        (group: string, value: string) => {
            if (!field.value) {
                return
            }

            const validateFieldsWhileChanging = true
            const record = { ...field.value }
            const hasGlobalWildcard = record[wildcardSymbol]?.includes(wildcardSymbol)

            if (hasGlobalWildcard) {
                // Remove global wildcard, expand all groups
                const newRecord: GroupRecordType = Object.create(null)

                Object.entries(normalizedGroupOptions).forEach(([g, items]) => {
                    if (g === group) {
                        // For clicked group, add all values except the one being unchecked
                        newRecord[g] = items.filter((item) => item !== value)
                    } else {
                        // Keep wildcard for other groups
                        newRecord[g] = [wildcardSymbol]
                    }
                })

                setValue(newRecord, validateFieldsWhileChanging)
                return
            }

            // Handle normal group selection
            const groupItems = normalizedGroupOptions[group] || []
            const currentValues = new Set(record[group] || [])

            if (currentValues.has(wildcardSymbol)) {
                // If group had wildcard, expand to all values except unchecked one
                record[group] = groupItems.filter((item) => item !== value)
            } else {
                if (currentValues.has(value)) {
                    currentValues.delete(value)
                } else {
                    currentValues.add(value)
                    // If all items are now selected, convert to wildcard
                    if (currentValues.size === groupItems.length) {
                        record[group] = [wildcardSymbol]

                        setValue(record, validateFieldsWhileChanging)
                        return
                    }
                }
                record[group] = [...currentValues]
            }

            if (record[group]?.length === 0) {
                delete record[group]
            }

            setValue(record, validateFieldsWhileChanging)
        },
        [field.value, wildcardSymbol]
    )

    const handleGroupCheckboxChange = useCallback(
        (group: string) => {
            if (!field.value) {
                return
            }

            const validateFieldsWhileChanging = true
            const record = { ...field.value }
            const hasGlobalWildcard = record[wildcardSymbol]?.includes(wildcardSymbol)
            const groupItems = normalizedGroupOptions[group] || []

            if (hasGlobalWildcard) {
                // Remove global wildcard, keep other groups as wildcard
                const newRecord: GroupRecordType = Object.create(null)

                Object.entries(normalizedGroupOptions).forEach(([g]) => {
                    if (g !== group) {
                        newRecord[g] = [wildcardSymbol]
                    }
                })

                setValue(newRecord, validateFieldsWhileChanging)
                return
            }

            const allSelected =
                record[group]?.includes(wildcardSymbol) || groupItems.every((item) => record[group]?.includes(item))

            if (allSelected) {
                delete record[group]
            } else {
                record[group] = [wildcardSymbol]
            }

            setValue(record, validateFieldsWhileChanging)
        },
        [field.value, wildcardSymbol]
    )

    return {
        field,
        meta,
        //
        handleCheckboxChange,
        handleGroupCheckboxChange
    }
}
