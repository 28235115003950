import { AxiosResponse } from 'axios'
import { chain } from 'lodash'

export async function resolverLimitCheckInterceptor(response: AxiosResponse) {
    const hasReachedLimit = chain(response.data?.items).size().gt(response.data?.limit).value()

    if (response?.status === 200 && hasReachedLimit) {
        return Promise.reject({
            message: `Cannot select more than ${response.data?.limit} items.\n Please narrow down your selection.`
        })
    }

    return response
}
