import { get } from 'lodash'
import {
    APIQueryParamStateMap,
    APIQueryParamTypes,
    ExtractQueryType,
    UseAPIQueryParamsArg,
    UseAPIQueryParamsResult
} from './useAPIQueryParams.types'
import {
    APIqueryParamArgTypeResolver,
    createUseAPIQueryParam,
    getQueryConfigurationByType
} from './useAPIQueryParams.utils'

/**
 * @usage
 * const { search, filterBy } = useAPIQueryParams<PaymentOrdersServiceFindAllQueryOptions>('search', {
 *      filterBy: { configuration: { filters: PAYMENT_ORDER_FILTERS } }
 * })
 */
export function useAPIQueryParams<T = void>(...args: UseAPIQueryParamsArg<T>[]) {
    type UseQuery = Record<
        APIQueryParamTypes,
        UseAPIQueryParamsResult<ExtractQueryType<any /* @todo extract type from item*/, APIQueryParamStateMap<T>>>
    >
    return args?.reduce<UseQuery>((acc, item) => {
        const type = APIqueryParamArgTypeResolver<T>(item)
        const config = getQueryConfigurationByType<T>(type, get(item, `${type}.configuration`))

        if (type) {
            acc[type] = createUseAPIQueryParam<ExtractQueryType<typeof type, APIQueryParamStateMap<T>>>(
                type,
                acc
            )(config)
        }

        return acc
    }, Object.create(null))
}
