import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Text } from '@chakra-ui/react'
import { ExternalAccountCompanyRegistrationNumberType as NumeralExternalAccountCompanyRegistrationNumberType } from '@webapps/numeral-ui-core'
import { FormattedMessage } from 'react-intl'

export const ExternalAccountCompanyRegistrationNumberType: React.FC<{
    companyRegistrationNumberType?: NumeralExternalAccountCompanyRegistrationNumberType | null
}> = ({ companyRegistrationNumberType }) => {
    if (!companyRegistrationNumberType) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Text>
            <FormattedMessage id={`api.company_registration_number_type.${companyRegistrationNumberType}`} />
        </Text>
    )
}
