import { Select } from '@/components/@misc'
import { API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES } from '@/hooks'
import { prettyPrintCasedWords } from '@/utils'
import { Button, Flex, Stack } from '@chakra-ui/react'
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik'
import { chain, isEmpty, isUndefined, noop, uniq } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Options } from 'react-select/dist/declarations/src/types'
import { TableHeaderPanel, TableHeaderPanelHeader } from '../TableHeaderPanel'
import { TABLE_HEADER_GROUP_BY_REQUIRED_GROUPS } from './TableHeaderGroupBy.const'
import { TableHeaderGroupByProps } from './TableHeaderGroupBy.types'
import { getGroupByButtonColor, getTableHeaderGroupByFormItemCompiledTemplate } from './TableHeaderGroupBy.utils'

export function TableHeaderGroupBy({
    value,
    isLoading,
    isDisabled,
    isVisible,

    groups,
    isExpanded,
    isExpandedEntries,

    APIQueryParamKey,
    toggleIsExpanded = noop,
    onClose = noop
}: TableHeaderGroupByProps) {
    const intl = useIntl()
    const { setFieldValue } = useFormikContext<string[]>()
    const onReset = useCallback(() => {
        if (APIQueryParamKey) {
            setFieldValue(APIQueryParamKey, undefined).finally(onClose)
        }
    }, [setFieldValue, APIQueryParamKey, onClose])

    const filteredGroups = useMemo(() => {
        return chain(groups).uniq().value()
    }, [groups])

    const collapsedStates = useMemo(() => {
        const indexes = chain(isExpandedEntries).values().value()
        const hasCollapsedGroups = indexes.some(
            (value) => value === API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES.COLLAPSED
        )
        const hasExpandedGroups = indexes.some(
            (value) => value === API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES.EXPANDED
        )

        const isCollapseAllDisabled = isDisabled || (!isExpanded && !hasExpandedGroups)
        const isExpandAllDisabled = isDisabled || isExpanded || (isExpanded && !hasCollapsedGroups)

        return {
            isCollapseAllDisabled,
            isExpandAllDisabled
        }
    }, [isExpandedEntries, isDisabled, isExpanded])

    const panelTitle = useMemo(() => {
        return intl.formatMessage({ id: 'app.table.header.group_by.title' })
    }, [intl])
    const onCollapseAll = useCallback(() => toggleIsExpanded(false), [toggleIsExpanded])
    const onExpandAll = useCallback(() => toggleIsExpanded(true), [toggleIsExpanded])

    useEffect(() => {
        if (isVisible && isEmpty(value)) {
            uniq(TABLE_HEADER_GROUP_BY_REQUIRED_GROUPS).forEach((requiredGroup, index) => {
                const compiledTemplate = getTableHeaderGroupByFormItemCompiledTemplate()
                const fieldGroupByEntryName = compiledTemplate({
                    group_by_form_item: APIQueryParamKey,
                    group_by_form_item_index: index
                })

                setFieldValue(fieldGroupByEntryName, requiredGroup)
            })
        }
    }, [isVisible, APIQueryParamKey])

    if (!APIQueryParamKey) {
        return null
    }

    return (
        <TableHeaderPanel className="TableHeaderGroupBy" flexDirection="column">
            <TableHeaderPanelHeader title={panelTitle}>
                <Button variant="ghost" colorScheme="blue" color="numeralAccent.500" onClick={onReset}>
                    <FormattedMessage id="app.table.header.group_by.actions.clear.label" />
                </Button>
                <Button
                    variant="ghost"
                    colorScheme="blue"
                    color={getGroupByButtonColor(collapsedStates.isCollapseAllDisabled)}
                    onClick={onCollapseAll}
                    isDisabled={collapsedStates.isCollapseAllDisabled}>
                    <FormattedMessage id="app.table.header.group_by.actions.collapse_all.label" />
                </Button>
                <Button
                    variant="ghost"
                    colorScheme="blue"
                    color={getGroupByButtonColor(collapsedStates.isExpandAllDisabled)}
                    onClick={onExpandAll}
                    isDisabled={collapsedStates.isExpandAllDisabled}>
                    <FormattedMessage id="app.table.header.group_by.actions.expand_all.label" />
                </Button>
            </TableHeaderPanelHeader>
            <Flex width="100%" justifyContent="space-between">
                <FieldArray
                    name={APIQueryParamKey}
                    render={({ push, remove }) => {
                        const isAddGroupDisabled = isDisabled || value?.length === filteredGroups?.length

                        const onAddGroup = () => {
                            push(undefined)
                        }

                        return (
                            <Stack minWidth="460px" maxWidth="90%">
                                {value?.map((group, index: number) => {
                                    const compiledTemplate = getTableHeaderGroupByFormItemCompiledTemplate()
                                    const fieldGroupByEntryName = compiledTemplate({
                                        group_by_form_item: APIQueryParamKey,
                                        group_by_form_item_index: index
                                    })
                                    const isGroupRequired = TABLE_HEADER_GROUP_BY_REQUIRED_GROUPS.includes(group)

                                    return (
                                        <Field
                                            key={index}
                                            name={fieldGroupByEntryName}
                                            as={(props: FieldProps) => {
                                                const isSelectDisabled = isDisabled || isLoading || isGroupRequired
                                                const placeholder = intl.formatMessage({
                                                    id: 'app.table.header.group_by.actions.select_group.placeholder'
                                                })

                                                const onChange = (value: typeof group) => {
                                                    if (isUndefined(value)) {
                                                        remove(index)
                                                    } else {
                                                        setFieldValue(fieldGroupByEntryName, value)
                                                    }
                                                }
                                                const isSelectOptionDisabled = (
                                                    option: any,
                                                    selectValue: Options<typeof group>
                                                ) => {
                                                    return !!chain(value)
                                                        .find((_) => _ === option?.value)
                                                        .value()
                                                }

                                                return (
                                                    <Select<typeof group>
                                                        {...props.field}
                                                        value={group}
                                                        options={filteredGroups}
                                                        isOptionDisabled={isSelectOptionDisabled}
                                                        onChange={onChange}
                                                        getOptionLabel={prettyPrintCasedWords}
                                                        isDisabled={isSelectDisabled}
                                                        placeholder={placeholder}
                                                    />
                                                )
                                            }}
                                        />
                                    )
                                })}
                                <Button
                                    variant="ghost"
                                    justifyContent="left"
                                    paddingX="0"
                                    color="numeralAccent.500"
                                    onClick={onAddGroup}
                                    leftIcon={<>+</>}
                                    isDisabled={isAddGroupDisabled}>
                                    <FormattedMessage id="app.table.header.group_by.actions.add_group.label" />
                                </Button>
                            </Stack>
                        )
                    }}
                />
            </Flex>
        </TableHeaderPanel>
    )
}
