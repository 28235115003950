import React, { useMemo, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { StickyFooter } from '@/components'
import { chain } from 'lodash'
import { useQuickFilter } from '@/hooks'
import { BulkActionsFooterProps } from './BulkActionsFooter.types'
import { BulkActionsFooterDefault } from './@components'
import { getQuickFilterStateValue } from '@/providers'

export const BulkActionsFooter = React.memo(<T,>({ targetObject, selection, actions }: BulkActionsFooterProps<T>) => {
    const [marginBottom, setMarginBottom] = useState(0)
    const { quickFilters, state } = useQuickFilter<T>()
    const { ContentComponent, ActionComponent } = useMemo(() => {
        const activeQuickFilterState = getQuickFilterStateValue<T>(quickFilters, state)
        const actionsWrapper = chain(actions)
        const ContentComponent = actionsWrapper
            .get(activeQuickFilterState)
            .get('contentComponent', BulkActionsFooterDefault)
            .value()
        const ActionComponent = actionsWrapper
            .get(activeQuickFilterState)
            .get('actionComponent', React.Fragment)
            .value()

        return {
            ContentComponent,
            ActionComponent
        }
    }, [actions, quickFilters, state])

    if (!selection?.size) {
        return null
    }

    return (
        <Box className="BulkActionsFooter" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Flex gap="16px" flex="1" paddingY="16px" justifyContent="space-between">
                    <Flex gap="8px" direction="column">
                        <ContentComponent targetObject={targetObject} selection={selection} />
                    </Flex>
                    <Box margin="auto 0">
                        <ActionComponent targetObject={targetObject} />
                    </Box>
                </Flex>
            </StickyFooter>
        </Box>
    )
})
