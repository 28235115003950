import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { prettyPrintCasedWords } from '@/utils'
import { Text } from '@chakra-ui/react'
import { ExternalAccountType as NumeralExternalAccountType } from '@webapps/numeral-ui-core'

export const ExternalAccountType: React.FC<{ accountType?: NumeralExternalAccountType | null }> = ({ accountType }) => {
    if (!accountType) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return <Text>{prettyPrintCasedWords(accountType)}</Text>
}
