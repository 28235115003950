import { Text } from '@chakra-ui/react'
import { OptionProps } from 'react-select/dist/declarations/src/components/Option'
import { get } from 'lodash'
import { SelectOption } from '@/components/@misc/Select/@components'

export const BankNameInputSelectOption: React.FC<OptionProps<string>> = ({
    children,
    innerRef,
    innerProps,
    ...props
}) => {
    const name = get(props, 'data.original')
    return (
        <SelectOption innerRef={innerRef} innerProps={innerProps} {...props}>
            <Text noOfLines={1} title={name}>
                {children}
            </Text>
        </SelectOption>
    )
}
