import { DetailPageLink, LinkWithFilterBy } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useNavigationRoutes, useQueryFindAllReturnRequests, useQueryFindAllReturns } from '@/hooks'
import { queryDataAggregation } from '@/utils'
import { Flex } from '@chakra-ui/react'
import { ApiObjectTypeSchema, PaymentType, Return, ReturnRequest, Uuid } from '@webapps/numeral-ui-core'
import { ReactNode, useMemo } from 'react'

interface EntityDetailCellRelatedObjectsLinksProps {
    showRelatedPaymentLink?: boolean
    showRelatedReturnRequestLink?: boolean
    showRelatedReturnLink?: boolean
    relatedAccountHolderId?: Uuid
    relatedInternalAccountId?: Uuid
    relatedExternalAccountId?: Uuid
    relatedPaymentId?: Uuid
    relatedPaymentType?: PaymentType
    relatedFileId?: Uuid
    relatedPaymentFileId?: Uuid
}

/**
 * @description Display a list of related object link
 *
 * @todo Once the API provide the related objects, workaround API calls done in the component should be removed
 * and relatedId should come directly from the props, removing as well the need for 'show..' props
 */
export const EntityDetailCellRelatedObjectsLinks: React.FC<EntityDetailCellRelatedObjectsLinksProps> = ({
    showRelatedPaymentLink = false,
    showRelatedReturnRequestLink = false,
    showRelatedReturnLink = false,
    relatedAccountHolderId,
    relatedInternalAccountId,
    relatedExternalAccountId,
    relatedPaymentId,
    relatedPaymentType,
    relatedFileId,
    relatedPaymentFileId
}) => {
    const { paths } = useNavigationRoutes()

    const returnRequestQuery = useQueryFindAllReturnRequests(
        { related_payment_id: relatedPaymentId },
        {
            enabled: globalThis.Boolean(showRelatedReturnRequestLink && relatedPaymentId)
        }
    )
    const returnRequestData = useMemo(
        () => queryDataAggregation<ReturnRequest>(returnRequestQuery.data),
        [returnRequestQuery]
    )

    const returnQuery = useQueryFindAllReturns(
        { related_payment_id: relatedPaymentId },
        {
            enabled: globalThis.Boolean(showRelatedReturnLink && relatedPaymentId)
        }
    )
    const returnData = useMemo(() => queryDataAggregation<Return>(returnQuery.data), [returnQuery])

    const relatedLinks = useMemo<ReactNode>(() => {
        const willShowRelatedAccountHolderLink = !!relatedAccountHolderId
        const willShowRelatedInternalAccountLink = !!relatedInternalAccountId
        const willShowRelatedExternalAccountLink = !!relatedExternalAccountId
        const willShowRelatedFileLink = !!relatedFileId
        const willShowRelatedPaymentFileLink = !!relatedPaymentFileId
        const willShowRelatedPaymentLink = showRelatedPaymentLink && !!relatedPaymentId && !!relatedPaymentType
        const willShowRelatedReturnRequestLink = showRelatedReturnRequestLink && !!returnRequestData.length
        const willShowRelatedReturnsLink = showRelatedReturnLink && !!returnData.length

        if (
            !willShowRelatedAccountHolderLink &&
            !willShowRelatedInternalAccountLink &&
            !willShowRelatedExternalAccountLink &&
            !willShowRelatedPaymentLink &&
            !willShowRelatedReturnRequestLink &&
            !willShowRelatedReturnsLink &&
            !willShowRelatedFileLink &&
            !willShowRelatedPaymentFileLink
        ) {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        let relatedAccountHolderLink: ReactNode | undefined
        let relatedInternalAccountLink: ReactNode | undefined
        let relatedExternalAccountLink: ReactNode | undefined
        let relatedPaymentLink: ReactNode | undefined
        let relatedReturnRequestsLink: ReactNode | undefined
        let relatedReturnsLink: ReactNode | undefined
        let relatedFileLink: ReactNode | undefined
        let relatedPaymentFileLink: ReactNode | undefined

        if (willShowRelatedAccountHolderLink) {
            relatedAccountHolderLink = (
                <DetailPageLink
                    objectId={relatedAccountHolderId}
                    objectType={ApiObjectTypeSchema.enum.account_holder}
                />
            )
        }

        if (willShowRelatedInternalAccountLink) {
            relatedInternalAccountLink = (
                <DetailPageLink
                    objectId={relatedInternalAccountId}
                    objectType={ApiObjectTypeSchema.enum.internal_account}
                />
            )
        }

        if (willShowRelatedExternalAccountLink) {
            relatedInternalAccountLink = (
                <DetailPageLink
                    objectId={relatedExternalAccountId}
                    objectType={ApiObjectTypeSchema.enum.external_account}
                />
            )
        }

        if (willShowRelatedPaymentLink) {
            relatedPaymentLink = <DetailPageLink objectId={relatedPaymentId} objectType={relatedPaymentType} />
        }

        if (willShowRelatedReturnRequestLink) {
            relatedReturnRequestsLink = (
                <LinkWithFilterBy
                    labelKey="app.common.link.related_return_requests.label"
                    path={paths.PAYMENTS.RETURN_REQUESTS}
                    filterBy={{
                        related_payment_id: relatedPaymentId
                    }}
                />
            )
        }

        if (willShowRelatedReturnsLink) {
            relatedReturnsLink = (
                <LinkWithFilterBy
                    labelKey="app.common.link.related_returns.label"
                    path={paths.PAYMENTS.RETURNS}
                    filterBy={{
                        related_payment_id: relatedPaymentId
                    }}
                />
            )
        }

        if (willShowRelatedFileLink) {
            relatedFileLink = <DetailPageLink objectId={relatedFileId} objectType={ApiObjectTypeSchema.enum.file} />
        }

        if (willShowRelatedPaymentFileLink) {
            relatedPaymentFileLink = (
                <DetailPageLink
                    customLabelMessageKey="app.common.link.payment_file.label"
                    objectId={relatedPaymentFileId}
                    objectType={ApiObjectTypeSchema.enum.payment_file}
                />
            )
        }

        return (
            <Flex direction="column" gap="8px">
                {relatedAccountHolderLink}
                {relatedInternalAccountLink}
                {relatedExternalAccountLink}
                {relatedPaymentLink}
                {relatedReturnRequestsLink}
                {relatedReturnsLink}
                {relatedFileLink}
                {relatedPaymentFileLink}
            </Flex>
        )
    }, [
        relatedAccountHolderId,
        relatedInternalAccountId,
        relatedExternalAccountId,
        showRelatedPaymentLink,
        relatedPaymentId,
        relatedPaymentType,
        showRelatedReturnRequestLink,
        returnRequestData.length,
        showRelatedReturnLink,
        returnData.length,
        relatedFileId,
        relatedPaymentFileId,
        paths
    ])

    return <>{relatedLinks}</>
}
