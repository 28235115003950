import './SimpleGridItem.scss'
import { PropsWithChildren } from 'react'
import { Box } from '@chakra-ui/react'
import { HOME_PAGE_GRID_CONFIGURATION } from '../../Home.page.const'

export const SimpleGridItem: React.FC<PropsWithChildren> = ({ children }) => (
    <Box
        className="SimpleGridItem"
        border="1px solid"
        borderColor="gray.100"
        backgroundColor="white"
        padding={HOME_PAGE_GRID_CONFIGURATION.SPACING}
        overflow="hidden"
        borderRadius="var(--numeral-ui-primary-border-radius)"
        cursor="default"
        tabIndex={1}
        _focusVisible={{
            backgroundColor: 'gray.50',
            outlineColor: 'gray.100'
        }}>
        {children}
    </Box>
)
