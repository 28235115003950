import { TableColumnCustomizers } from '@/components'
import { DetailPageLink, FormattedDateTime } from '@/components/@misc'
import { MIN_COLUMN_WIDTH } from '@/constants'
import { AuditTrail } from '@/services'
import { TableCellAuthorName, TableCellAuthorType } from './@components'

export const AUDIT_TRAIL_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<AuditTrail> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.LONG_DATE,
        cell(info) {
            return <FormattedDateTime value={info.getValue()} />
        }
    },
    author_type: {
        cell(info) {
            return <TableCellAuthorType value={info.getValue()} />
        }
    },
    author_name: {
        cell(info) {
            const { author_id, author_type, object_data } = info.row.original

            return (
                <TableCellAuthorName
                    authorName={info.getValue()}
                    authorType={author_type}
                    authorId={author_id}
                    objectData={object_data}
                />
            )
        }
    },
    object_id: {
        cell(info) {
            const { object_type } = info.row.original

            return <DetailPageLink objectId={info.getValue()} objectType={object_type} />
        }
    }
}
