import { QueryFallback, RestrictedSection } from '@/components'
import { useMutationUpdateByIdTransaction, useNavigationRoutes, useQueryFindByIdTransaction } from '@/hooks'
import { PageLayout } from '@/layouts'
import { TransactionHeader } from '@/pages'
import { updateUUIDRelativeActionPathname } from '@/providers'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Box } from '@chakra-ui/react'
import { ApiObjectTypeSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TransactionEditForm } from './@components'

export const TransactionEditPage: React.FC = () => {
    const { uuid } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()

    const query = useQueryFindByIdTransaction(uuid)
    const isDataEmpty = useMemo(() => {
        return isEmpty(query?.data)
    }, [query.data])

    const mutation = useMutationUpdateByIdTransaction(uuid, {
        onSuccess() {
            const { ACCOUNTS } = relativePaths
            const path = updateUUIDRelativeActionPathname(location.pathname, ACCOUNTS.DETAILS)

            navigate(path)
        }
    })

    return (
        <PageLayout className="TransactionEditPage">
            <TransactionHeader />
            <RestrictedSection feature={ENTITY_FEATURE.TRANSACTION} permission={PERMISSION.ACCOUNTS_EDIT_TRANSACTIONS}>
                <Box marginTop="8px">
                    <QueryFallback
                        objectType={ApiObjectTypeSchema.enum.transaction}
                        isLoading={query.isLoading}
                        isSuccess={query.isSuccess}
                        isError={query.isError || isDataEmpty}
                        isDataEmpty={isDataEmpty}>
                        <TransactionEditForm
                            onSubmit={mutation.mutate}
                            disabled={mutation.isPending}
                            loading={mutation.isPending}
                            data={query.data}
                        />
                    </QueryFallback>
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
