import { useActivatedFeatures, useAuth, useConfiguration, usePermissions, useServices } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessAPIQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataInfiniteOptions, useInfiniteQuery } from '@tanstack/react-query'
import { IncomingPayment, IncomingPaymentsServiceFindAllQueryOptions, List } from '@webapps/numeral-ui-core'
import { paginationQueryOptions } from '../paginationQueryOptions.const'

export function useQueryFindAllIncomingPayments(
    params?: Partial<IncomingPaymentsServiceFindAllQueryOptions>,
    options?: Partial<UndefinedInitialDataInfiniteOptions<List<IncomingPayment>, Error>>
) {
    const { incomingPaymentsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { pagination } = useConfiguration()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isUserLoggedIn &&
        isFeatureActivated(ENTITY_FEATURE.INCOMING_PAYMENT) &&
        hasPermission(PERMISSION.PAYMENTS_VIEW_INCOMING_PAYMENTS) &&
        options?.enabled
    const paramsWithUserAccess: typeof params = {
        limit: pagination.limit,
        ...params,
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useInfiniteQuery<List<IncomingPayment>, Error>({
        ...options,
        enabled: isQueryEnabled,
        initialPageParam: undefined,
        queryKey: [incomingPaymentsService.url, paramsWithUserAccess],
        queryFn({ signal, pageParam }) {
            return incomingPaymentsService.findAll(
                { ...paramsWithUserAccess, ...(pageParam as typeof params) },
                { signal }
            )
        },
        getNextPageParam(lastPage) {
            return paginationQueryOptions.getNextPageParam(lastPage, pagination.limit)
        }
    })
}
