import { EntityDetail } from '@/components'
import { useQueryFindByIdExternalAccount } from '@/hooks'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Fade,
    Flex,
    Text
} from '@chakra-ui/react'
import { ExternalAccount, Uuid, UuidSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    EXTERNAL_ACCOUNT_DETAILS_SUMMARY_ROW_CUSTOMIZERS,
    EXTERNAL_ACCOUNT_DETAILS_SUMMARY_ROWS
} from './ExternalAccountDetailsSummary.const'

export const ExternalAccountDetailsSummary: React.FC<{
    externalAccountId?: Uuid
}> = ({ externalAccountId, ...propsBox }) => {
    const hasValidUUID = useMemo<boolean>(() => UuidSchema.safeParse(externalAccountId).success, [externalAccountId])
    const [activeIndex, setActiveIndex] = useState<number>(-1)
    const onChange = useCallback((expandedIndex: number) => {
        setActiveIndex(expandedIndex)
    }, [])
    const query = useQueryFindByIdExternalAccount(externalAccountId, {
        enabled: hasValidUUID
    })

    useEffect(() => {
        if (hasValidUUID) {
            onChange(0)
        }
    }, [hasValidUUID, onChange])

    if (isEmpty(query.data)) {
        return null
    }

    return (
        <Box
            {...propsBox}
            borderWidth="1px"
            borderRadius="var(--numeral-ui-primary-border-radius)"
            marginY="8px"
            overflow="hidden"
            width="inherit"
            className="PaymentOrderCounterpartyAccountDetails">
            <Accordion
                allowMultiple={false}
                allowToggle={!query?.isLoading}
                index={activeIndex}
                backgroundColor="gray.50"
                onChange={onChange}>
                <AccordionItem border="unset">
                    <AccordionButton display="flex" padding="16px" justifyContent="space-between">
                        <Text fontWeight="600" fontSize="16px">
                            <FormattedMessage id="app.common.account_details.title" />
                        </Text>
                        <Flex>
                            <AccordionIcon color="gray.500" />
                        </Flex>
                    </AccordionButton>
                    <AccordionPanel>
                        <Fade in={query?.isSuccess}>
                            <EntityDetail<ExternalAccount>
                                data={query?.data}
                                rows={EXTERNAL_ACCOUNT_DETAILS_SUMMARY_ROWS}
                                rowCustomizers={EXTERNAL_ACCOUNT_DETAILS_SUMMARY_ROW_CUSTOMIZERS}
                                style={{ border: 'unset', margin: 'unset', padding: 'unset' }}
                            />
                        </Fade>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    )
}
