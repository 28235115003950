import React, { useMemo, useRef } from 'react'
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { ChartLegend } from '../ChartLegend'
import { chain } from 'lodash'
import { useIntl } from 'react-intl'
import { CHART_BAR_HORIZONTAL_STACK_BASE_CONFIGURATION } from './ChartBarHorizontalStack.const'
import { If } from '@/components/@misc'
import { ChartBarHorizontalStackProps } from './ChartBarHorizontalStack.types'
import { Box, Stack, useToken } from '@chakra-ui/react'
import { CHART_BASE_COLORS, getChartBarScalesColors } from '@/components/@charts'
import { useChartJSCleanupChartInstance } from '../@hooks'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)

export function ChartBarHorizontalStack<T = void>({
    data,
    options,
    showLegend,
    isDisabled,
    isLoading,
    ...props
}: ChartBarHorizontalStackProps<T>) {
    const intl = useIntl()
    const fallbackColors = [
        CHART_BASE_COLORS.WHITE,
        CHART_BASE_COLORS.NUMERAL_LIGHT_GRAY,
        CHART_BASE_COLORS.NUMERAL_GRAY
    ]
    const [white, gray100, gray500] = useToken('colors', ['white', 'gray.100', 'gray.500'], fallbackColors)
    const chartRef = useRef<ChartJSOrUndefined<'bar', T>>()
    const chartId = useChartJSCleanupChartInstance(chartRef)
    const optionsWithDefaults = useMemo(() => {
        const scales = getChartBarScalesColors(white, gray100, gray500)

        return chain(options)
            .merge(CHART_BAR_HORIZONTAL_STACK_BASE_CONFIGURATION, {
                scales,
                locale: intl.locale
            })
            .value()
    }, [white, gray100, gray500, options, intl])

    return (
        <Stack className="ChartBarHorizontalStack" justifyContent="space-between" height="inherit">
            <Box className="ChartBarHorizontalStack-Chart" height="100%" width="100%" {...props}>
                <Bar id={chartId} options={optionsWithDefaults} data={data} ref={chartRef} />
            </Box>
            <If condition={showLegend}>
                <ChartLegend<T> chartRef={chartRef} dataset={data?.datasets} isDisabled={true} isLoading={isLoading} />
            </If>
        </Stack>
    )
}
