import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { FormikInput } from '@/types'
import { prettyPrintCasedWords } from '@/utils'
import { anythingValidator } from '@/utils/@validators'
import { Checkbox, CheckboxGroup, FormControl, FormErrorMessage, FormLabel, Stack } from '@chakra-ui/react'
import { ApiObjectType, CustomField } from '@webapps/numeral-ui-core'
import { useField, useFormikContext } from 'formik'
import { chain } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface CustomFieldObjectTypesInputProps extends Omit<FormikInput, 'value'> {
    value?: ApiObjectType[]
}

export const CustomFieldObjectTypesInput: React.FC<CustomFieldObjectTypesInputProps> = ({
    name,
    value,
    searchOnMount = false,
    isRequired,
    isDisabled = false,
    customLabelKey,
    customLabelValues,
    customPlaceholderKey,
    isPlaceholderDisplayed = false,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField<ApiObjectType[]>({
        name,
        value,
        validate: validator?.(intl, isRequired)
    })
    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.${name}.label`
        return intl.formatMessage({ id: labelKey }, customLabelValues)
    }, [name, customLabelKey, intl, customLabelValues])
    const { values } = useFormikContext<CustomField>()
    const isEditMode = useMemo(() => globalThis.Boolean(values?.id), [values])
    const isInvalid = meta.touched && !!meta.error
    const options = useMemo(() => {
        const availableOptions = [
            ApiObjectTypeExtendedSchema.enum.payment_order,
            ApiObjectTypeExtendedSchema.enum.transaction,
            ApiObjectTypeExtendedSchema.enum.internal_account,
            ApiObjectTypeExtendedSchema.enum.external_account,
            /**
             * @description
             * Future compatible
             */
            field.value
        ]

        return chain(ApiObjectTypeExtendedSchema.Enum)
            .pick(...availableOptions)
            .values()
            .value()
    }, [field.value])

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={label} />
            </FormLabel>
            <CheckboxGroup isDisabled={isEditMode || isDisabled} value={field.value} onChange={helpers.setValue}>
                <Stack gap="16px" marginBottom="16px">
                    {options.map((option, index) => (
                        <Checkbox name={`${name}.${index}`} value={option} key={option}>
                            {`${prettyPrintCasedWords(option)}s`}
                        </Checkbox>
                    ))}
                </Stack>
            </CheckboxGroup>
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
