import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Text, TextProps } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

interface FormattedTextIntlProps extends TextProps {
    messageId?: string
    defaultMessage?: string
}

export const FormattedTextIntl: React.FC<FormattedTextIntlProps> = ({ messageId, defaultMessage, ...textProps }) => {
    const intl = useIntl()
    const formattedText = useMemo(() => {
        if (!messageId) {
            return EMPTY_VALUE_PLACEHOLDER
        }

        return intl.formatMessage({ id: messageId, defaultMessage: defaultMessage || EMPTY_VALUE_PLACEHOLDER })
    }, [intl, messageId, defaultMessage])

    return (
        <Text as="span" title={formattedText} {...textProps}>
            {formattedText}
        </Text>
    )
}
