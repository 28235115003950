import { Select } from '@/components/@misc'
import { useCustomFormikField } from '@/hooks'
import { getIncomingPaymentReturnReasonOptions } from '@/services'
import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { IncomingPayment, IncomingPaymentReturnReason, SepaReturnReason } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface IncomingPaymentReturnReasonInputProps extends FormikInput {
    incomingPayment?: IncomingPayment
    isPlaceholderDisplayed?: boolean
}

export const IncomingPaymentReturnReasonInput: React.FC<IncomingPaymentReturnReasonInputProps> = ({
    incomingPayment,
    isPlaceholderDisplayed,
    name,
    value,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<IncomingPaymentReturnReason>({ name, value })
    const placeholder = useMemo<string | undefined>(() => {
        if (!isPlaceholderDisplayed) {
            return
        }
        return intl.formatMessage({ id: `app.common.form.input.${name}.placeholder` })
    }, [intl, isPlaceholderDisplayed, name])

    const options = useMemo<IncomingPaymentReturnReason[]>(() => {
        return getIncomingPaymentReturnReasonOptions(incomingPayment)
    }, [incomingPayment])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Select<SepaReturnReason>
                {...inputProps}
                {...field}
                id={name}
                placeholder={placeholder}
                onChange={helpers.setValue}
                options={options}
                isInvalid={isInvalid}
                getOptionLabel={(option) =>
                    `${option} - ${intl.formatMessage({ id: `api.sepa_return_reason.${option}` })}`
                }
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
