import { QueryFallback, RestrictedSection, Table, TableHeader, useTableRowNavigation } from '@/components'
import { useNavigationRoutes, usePermissions } from '@/hooks'
import { useQueryFindAllUsers } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION, User } from '@/services'
import { queryDataAggregation } from '@/utils'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { USERS_TABLE_COLUMN_CUSTOMIZERS, USERS_TABLE_STATIC_STATE } from './Users.page.const'
import { UsersCustomColumns } from './Users.page.types'

export const UsersPage: React.FC = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()

    const query = useQueryFindAllUsers()
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const onTableRowClick = useTableRowNavigation<User>('id', relativePaths.SETTINGS.DETAILS)
    const onNavigateToInvite = useCallback(() => {
        navigate(relativePaths.INVITE)
    }, [navigate, relativePaths])
    const actionsProps = useMemo(() => {
        if (hasPermission(PERMISSION.SETTINGS_EDIT_USERS)) {
            return [
                {
                    onClick: onNavigateToInvite,
                    children: intl.formatMessage({
                        id: 'app.settings.users.add.title'
                    })
                }
            ]
        }
    }, [hasPermission])

    const hasRowOptionsEnabled = useMemo(() => {
        return hasPermission(PERMISSION.SETTINGS_EDIT_USERS) || hasPermission(PERMISSION.SETTINGS_INVITE_USERS)
    }, [hasPermission])

    return (
        <PageLayout className="Users">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_USERS}>
                <TableHeader<User> objectType={ApiObjectTypeExtendedSchema.enum.user} actions={actionsProps} />
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.user}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <Table<User, UsersCustomColumns>
                        data={data}
                        onRowClick={onTableRowClick}
                        onScrollToBottom={query.fetchNextPage}
                        isLoading={query.hasNextPage}
                        columnCustomizers={USERS_TABLE_COLUMN_CUSTOMIZERS}
                        state={USERS_TABLE_STATIC_STATE}
                        hasRowOptionsEnabled={hasRowOptionsEnabled}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
