import { Button, Center, Heading, Image, Link, Text } from '@chakra-ui/react'
import { If } from '../@misc'
import { EMPTY_STATE_IMAGES, EMPTY_STATE_IMAGE_DIMENSION } from './EmptyState.const'
import { EmptyStateProps } from './EmptyState.types'

export const EmptyState: React.FC<EmptyStateProps> = ({
    title,
    description,
    imageSrc = EMPTY_STATE_IMAGES.DEFAULT,
    imageDimension = EMPTY_STATE_IMAGE_DIMENSION,
    propsButton,
    propsLink
}) => {
    return (
        <Center display="flex" flexGrow={2} flexDirection="column" gap="1rem" textAlign="center" whiteSpace="pre-wrap">
            <Image src={imageSrc} height={imageDimension} width={imageDimension} pointerEvents="none" />
            <If condition={!!title}>
                <Heading>{title}</Heading>
            </If>
            <If condition={!!description}>
                <Text maxWidth={`${imageDimension + 200}px`} color="gray.500">
                    {description}
                </Text>
            </If>
            <If condition={!!propsButton}>
                <Button {...propsButton} />
            </If>
            <If condition={!!propsLink}>
                <Link textDecoration="underline" target="_blank" rel="noopener noreferrer" {...propsLink} />
            </If>
        </Center>
    )
}
