import type { ChartOptions } from 'chart.js'
import { CHART_OPTIONS } from '@/components/@charts'

export const CHART_BAR_VERTICAL_STACK_BASE_CONFIGURATION: ChartOptions<'bar'> = {
    maintainAspectRatio: false,
    scales: {
        x: {
            display: true,
            stacked: true,
            grid: {
                drawTicks: false,
                display: false
            },
            ticks: CHART_OPTIONS.TICKS
        },
        y: {
            display: true,
            grid: {
                drawTicks: false
            },
            ticks: {
                ...CHART_OPTIONS.TICKS,
                maxTicksLimit: 5,
                minTicksLimit: 3
            },
            stacked: true
        }
    },
    plugins: {
        legend: CHART_OPTIONS.LEGEND,
        tooltip: CHART_OPTIONS.TOOLTIP,
        datalabels: {
            display: false
        }
    },
    elements: {
        bar: {
            borderWidth: 0
        }
    },
    responsive: true,
    animation: CHART_OPTIONS.ANIMATION
}
