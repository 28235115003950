import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { NumeralEvent, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdEvent(id?: Uuid, options?: Partial<UndefinedInitialDataOptions<NumeralEvent, Error>>) {
    const { eventsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.DEVELOPERS_VIEW_EVENTS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<NumeralEvent, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [eventsService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return eventsService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
