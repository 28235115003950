import {
    QueryFallback,
    RestrictedSection,
    Table,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import { SupportEmailMailtoLink } from '@/components/@misc'
import {
    useAPIQueryParams,
    useCustomFieldFilters,
    useMutationExportExternalAccounts,
    useNavigationRoutes,
    usePermissions,
    useQueryFindAllExternalAccounts,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { QuickFilterProvider, TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import {
    ApiObjectTypeSchema,
    ExternalAccount,
    ExternalAccountsServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import {
    EXTERNAL_ACCOUNT_DOCUMENTATION_LINK,
    EXTERNAL_ACCOUNT_FILTERS,
    EXTERNAL_ACCOUNT_PRESELECTED_FILTERS,
    EXTERNAL_ACCOUNT_QUICK_FILTERS,
    EXTERNAL_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS,
    EXTERNAL_ACCOUNT_TABLE_STATIC_STATE
} from './ExternalAccounts.page.const'

export const ExternalAccountsPage: React.FC = () => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const navigate = useNavigate()
    const { onAdd } = useToasts()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.counterparties.external_accounts.title' }),
        [intl]
    )
    const filters = useCustomFieldFilters(ApiObjectTypeExtendedSchema.enum.external_account, EXTERNAL_ACCOUNT_FILTERS)
    const { search, filterBy } = useAPIQueryParams<ExternalAccountsServiceFindAllQueryOptions>('search', {
        filterBy: {
            configuration: {
                filters,
                preselected: EXTERNAL_ACCOUNT_PRESELECTED_FILTERS,
                quickFilters: EXTERNAL_ACCOUNT_QUICK_FILTERS
            }
        }
    })

    const query = useQueryFindAllExternalAccounts({ ...search.parsedState, ...filterBy.parsedState })
    const mutationExport = useMutationExportExternalAccounts({
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.INFO,
                status: 'info',
                title: <FormattedMessage id="app.common.export.success.toast.title" />,
                description: <FormattedMessage id="app.common.export.success.toast.description" />
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.export.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.common.export.error.toast.description"
                        values={{ supportEmailMailtoLink: <SupportEmailMailtoLink /> }}
                    />
                )
            })
        }
    })

    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const emptyStateButtonProps = useMemo(() => {
        if (!hasPermission(PERMISSION.COUNTERPARTIES_CREATE_EXTERNAL_ACCOUNTS)) {
            return
        }

        return {
            children: intl.formatMessage({
                id: 'app.counterparties.external_accounts.create.form.actions.submit.label'
            }),
            onClick() {
                navigate(relativePaths.NEW)
            }
        }
    }, [hasPermission, intl, navigate, relativePaths.NEW])

    const actions = useMemo<TableHeaderActionProps[]>(() => {
        const actions: TableHeaderActionProps[] = []

        actions.push({
            onClick() {
                mutationExport.mutate({ search_parameters: { ...search.parsedState, ...filterBy.parsedState } })
            },
            children: intl.formatMessage({ id: 'app.common.actions.export' }),
            isDisabled: isDataEmpty || mutationExport.isPending
        })

        if (hasPermission(PERMISSION.COUNTERPARTIES_CREATE_EXTERNAL_ACCOUNTS)) {
            actions.push({
                onClick() {
                    navigate(relativePaths.NEW)
                },
                children: intl.formatMessage({
                    id: 'app.counterparties.external_accounts.create.form.actions.submit.label'
                })
            })
        }

        return actions
    }, [
        intl,
        isDataEmpty,
        mutationExport,
        hasPermission,
        search.parsedState,
        filterBy.parsedState,
        navigate,
        relativePaths.NEW
    ])

    const onTableRowClick = useTableRowNavigation<ExternalAccount>('id', relativePaths.COUNTERPARTIES.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection permission={PERMISSION.COUNTERPARTIES_VIEW_EXTERNAL_ACCOUNTS}>
                <QuickFilterProvider<ExternalAccountsServiceFindAllQueryOptions> filterBy={filterBy}>
                    <TableHeader<ExternalAccountsServiceFindAllQueryOptions>
                        objectType={ApiObjectTypeExtendedSchema.enum.external_account}
                        isLoading={query.isLoading}
                        actions={actions}
                        search={search}
                        filterBy={filterBy}
                    />
                    <QueryFallback
                        objectType={ApiObjectTypeSchema.enum.external_account}
                        isLoading={query.isLoading}
                        isSuccess={query.isSuccess}
                        isError={query.isError}
                        isDataEmpty={isDataEmpty}
                        isFilteringOrSearching={search.isActive || filterBy.isActive}
                        learnMoreLinkUrl={EXTERNAL_ACCOUNT_DOCUMENTATION_LINK}
                        emptyStateButtonProps={emptyStateButtonProps}>
                        <Table<ExternalAccount>
                            data={data}
                            onRowClick={onTableRowClick}
                            onScrollToBottom={query.fetchNextPage}
                            isLoading={query.hasNextPage}
                            state={EXTERNAL_ACCOUNT_TABLE_STATIC_STATE}
                            columnCustomizers={EXTERNAL_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS}
                        />
                    </QueryFallback>
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
