import { useActivatedFeatures, useAuth, usePermissions, useServices } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessAPIQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { PaymentCapture, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdPaymentCapture(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<PaymentCapture, Error>>
) {
    const { paymentCapturesService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isUserLoggedIn &&
        isFeatureActivated(ENTITY_FEATURE.PAYMENT_CAPTURE) &&
        hasPermission(PERMISSION.PAYMENTS_VIEW_PAYMENT_CAPTURES) &&
        options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<PaymentCapture, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [paymentCapturesService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return paymentCapturesService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
