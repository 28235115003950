import { EntityDetail, QueryFallback } from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdFile } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { NumeralFile } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React from 'react'
import { useParams } from 'react-router-dom'
import { FileApproval } from './@components'
import { FILE_DETAILS_ROW_CUSTOMIZERS, FILE_DETAILS_ROWS } from './FileDetails.page.const'
import { FileDetailCustomRows } from './FileDetails.page.types'

export const FileDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const hasApprovalPermission = hasPermission(PERMISSION.FILES_APPROVE_FILES)
    const query = useQueryFindByIdFile(uuid)

    return (
        <PageLayout>
            <QueryFallback
                objectType={ApiObjectTypeExtendedSchema.enum.file}
                isLoading={query.isLoading}
                isSuccess={query.isSuccess}
                isError={query.isError || isEmpty(query?.data)}
                isDataEmpty={isEmpty(query?.data)}>
                <EntityDetail<NumeralFile, FileDetailCustomRows>
                    data={query.data}
                    rows={FILE_DETAILS_ROWS}
                    rowCustomizers={FILE_DETAILS_ROW_CUSTOMIZERS}
                />
                <If condition={hasApprovalPermission}>
                    <FileApproval data={query.data} />
                </If>
            </QueryFallback>
        </PageLayout>
    )
}
