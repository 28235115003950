import { COMMA_SYMBOL } from '@/constants'
import { prettyPrintCasedWords } from '@/utils'
import { Text } from '@chakra-ui/react'
import { ServiceName } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

interface EntityDetailCellServicesActivatedProps {
    value: ServiceName[] | null
}

export const EntityDetailCellServicesActivated: React.FC<EntityDetailCellServicesActivatedProps> = ({ value }) => {
    const intl = useIntl()
    const formattedValue = useMemo<string | undefined>(() => {
        const intlServiceNameMapper = (item: ServiceName) => {
            return intl.formatMessage({
                id: `api.connected_accounts.services_activated.${item}`,
                defaultMessage: prettyPrintCasedWords(item)
            })
        }

        return value?.map(intlServiceNameMapper).join(`${COMMA_SYMBOL} `)
    }, [value, intl])
    return <Text>{formattedValue}</Text>
}
