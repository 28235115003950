import { FormContainer } from '@/components'
import { If, ReadonlyField } from '@/components/@misc'
import { usePermissions } from '@/hooks'
import { hasNonAdminPermissionFilter, PERMISSION, PERMISSIONS_GROUPS, Role } from '@/services'
import { RoleAssignedUsers } from '@/pages/Settings/Roles/@components'
import { useIntl } from 'react-intl'
import { GroupAccordionReadonlyField } from '@/components/@inputs'
import { useMemo } from 'react'

interface RoleDetailsProps {
    data?: Role
}

export const RoleDetails: React.FC<RoleDetailsProps> = ({ data }) => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const showUsersAssignToRole = hasPermission(PERMISSION.SETTINGS_VIEW_USERS)
    const availablePermissions = useMemo(() => Object.values(PERMISSION), [])
    const availablePermissionsGroups = useMemo(() => Object.values(PERMISSIONS_GROUPS), [])
    const getGroupLabel = (group: string) => {
        return intl.formatMessage({
            id: `app.settings.roles.form.permissions.groups.${group}`,
            defaultMessage: group
        })
    }
    const getGroupOptionLabel = (group: string, value: string, selected: number, available: number) => {
        return intl.formatMessage({
            id: `app.settings.roles.form.permissions.${group}`,
            defaultMessage: value
        })
    }
    const getGroupCounterLabel = (group: string, selected: number, available: number) => {
        return intl.formatMessage(
            {
                id: 'app.settings.roles.form.permissions.group_number_of_permissions.label',
                defaultMessage: group
            },
            {
                selected,
                available
            }
        )
    }

    return (
        <FormContainer>
            <ReadonlyField name="name" value={data?.name} />
            <ReadonlyField name="description" value={data?.description} />
            <GroupAccordionReadonlyField<PERMISSION>
                name="permissions"
                value={data?.permissions}
                options={availablePermissions}
                groupOrder={availablePermissionsGroups}
                getGroupLabel={getGroupLabel}
                getGroupOptionLabel={getGroupOptionLabel}
                getGroupCounterLabel={getGroupCounterLabel}
                optionsFilter={hasNonAdminPermissionFilter}
            />
            <If condition={showUsersAssignToRole}>
                <RoleAssignedUsers roleId={data?.id} />
            </If>
        </FormContainer>
    )
}
