import { useAuth, useServices } from '@/hooks'
import { PaymentOrderResolverAllQueryOptions, PaymentOrdersResolver, filterUserAccessAPIQueryParams } from '@/services'
import { useQuery } from '@tanstack/react-query'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'

export function useQueryPaymentOrdersResolver(
    params: PaymentOrderResolverAllQueryOptions,
    options?: Partial<UndefinedInitialDataOptions<PaymentOrdersResolver, Error>>
) {
    const { paymentOrdersResolverService } = useServices()
    const { selectedUserAccess } = useAuth()
    const paramsWithUserAccess: typeof params = {
        ...params,
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<PaymentOrdersResolver, Error>({
        ...options,
        queryKey: [paymentOrdersResolverService.url, paramsWithUserAccess],
        queryFn({ signal }) {
            return paymentOrdersResolverService.resolve(paramsWithUserAccess, { signal })
        }
    })
}
