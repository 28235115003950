import { useAuth, usePrivateTopLevelRoutes } from '@/hooks'
import { NavigationRoute } from '@/providers'
import { AccordionProps } from '@chakra-ui/accordion'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Text } from '@chakra-ui/react'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    MainNavigationAccount,
    MainNavigationDivider,
    MainNavigationFooter,
    MainNavigationSectionListItem
} from './@components'
import './MainNavigation.scss'
import {
    getAccordionGroupActiveIndexByCurrentPathname,
    getLegalEntityFeatureRoutesFilter,
    getLegalEntityTypeRoutesFilter,
    getPartitionedNavigationRoutes,
    isAccordionGroupActive
} from './MainNavigation.utils'
import { combineFilters } from '@/utils'
import { defaultMainNavigationRoutesFilter } from './MainNavigation.const'
import { noop } from 'lodash'

interface MainNavigationProps {
    isCollapsed: boolean

    onToggleNavigation?(): void
}

export const MainNavigation: React.FC<MainNavigationProps> = ({ isCollapsed, onToggleNavigation = noop }) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const location = useLocation()
    const { selectedUserAccess } = useAuth()
    const { routes } = usePrivateTopLevelRoutes()
    const [accordionGroupIndex, setAccordionGroupIndex] = useState(-1)
    const mainNavigationRoutesFilter = useMemo(() => {
        const entityType = selectedUserAccess?.configuration?.entity_type
        const legalEntityFeatureRoutesFilter = getLegalEntityFeatureRoutesFilter(selectedUserAccess?.features)
        const legalEntityTypeRoutesFilter = getLegalEntityTypeRoutesFilter(entityType)

        return combineFilters<NavigationRoute>(
            defaultMainNavigationRoutesFilter,
            legalEntityFeatureRoutesFilter,
            legalEntityTypeRoutesFilter
        )
    }, [selectedUserAccess])
    const [mainRoutes, footerRoutes] = useMemo(() => {
        return getPartitionedNavigationRoutes(routes, mainNavigationRoutesFilter)
    }, [routes, mainNavigationRoutesFilter])
    const onNavigationItemClick = useCallback(
        (path: string) => {
            navigate(path)
        },
        [navigate]
    )

    const propsAccordion = useMemo<AccordionProps>(
        () => ({
            allowMultiple: false,
            index: accordionGroupIndex,
            onChange(index) {
                setAccordionGroupIndex(index as number)
            }
        }),
        [accordionGroupIndex]
    )
    const className = useMemo<string>(() => {
        return classNames('MainNavigation', {
            Collapsed: isCollapsed
        })
    }, [isCollapsed])

    useEffect(() => {
        const accordionIndex = getAccordionGroupActiveIndexByCurrentPathname(mainRoutes, location.pathname)

        setAccordionGroupIndex(accordionIndex)
    }, [mainRoutes, location])

    return (
        <Box className={className} backgroundColor="gray.50" data-testid="main-navigation">
            <MainNavigationAccount isCollapsed={isCollapsed} />
            <MainNavigationDivider isCollapsed={isCollapsed} />
            <Flex className="MainNavigation-Section" flex="1" justifyContent="space-between" direction="column">
                <Box className="MainNavigation-Section-List" paddingTop="16px">
                    <Accordion className="MainNavigation-Section-List-Accordion" {...propsAccordion}>
                        {mainRoutes.map((item: NavigationRoute, index, array) => {
                            const rootLevelItemRoute = item?.path
                            const formattedMessageTitle = intl.formatMessage({
                                id: item?.title
                            })

                            if (!isCollapsed && item.routes?.length && item?.configuration?.isGroup) {
                                const navigationItems = item.routes
                                    .filter(mainNavigationRoutesFilter)
                                    .map((item, index) => {
                                        const completePath = `${rootLevelItemRoute}/${item?.path}`

                                        return (
                                            <MainNavigationSectionListItem
                                                isCollapsed={isCollapsed}
                                                route={item}
                                                onClick={onNavigationItemClick}
                                                completePath={completePath}
                                                key={index}
                                            />
                                        )
                                    })

                                const propsAccordionButton = {
                                    title: formattedMessageTitle,
                                    'aria-label': formattedMessageTitle,
                                    className: 'MainNavigation-Section-List-Accordion-Button',
                                    padding: '6px 14px',
                                    color: 'gray.500',
                                    outline: 'none',
                                    _expanded: {
                                        color: 'numeralBlue.500'
                                    },
                                    _hover: {
                                        backgroundColor: 'transparent'
                                    }
                                }
                                const accordionArrowIcon = isAccordionGroupActive(accordionGroupIndex, index, array)
                                    ? faChevronUp
                                    : faChevronDown

                                return (
                                    <AccordionItem key={index} _focusVisible={{ boxShadow: 'none', outline: 'none' }}>
                                        <AccordionButton {...propsAccordionButton}>
                                            <Flex flex="1" alignItems="center">
                                                {item?.icon}
                                                <Text>{formattedMessageTitle}</Text>
                                            </Flex>
                                            <FontAwesomeIcon icon={accordionArrowIcon} fixedWidth={true} size="xs" />
                                        </AccordionButton>
                                        <AccordionPanel padding={0}>{navigationItems}</AccordionPanel>
                                    </AccordionItem>
                                )
                            }

                            return (
                                <MainNavigationSectionListItem
                                    isCollapsed={isCollapsed}
                                    route={item}
                                    onClick={onNavigationItemClick}
                                    key={index}
                                />
                            )
                        })}
                    </Accordion>
                </Box>
                <MainNavigationFooter
                    className="MainNavigation-Section-List"
                    isCollapsed={isCollapsed}
                    onClick={onNavigationItemClick}
                    onToggleNavigation={onToggleNavigation}
                    routes={footerRoutes}
                />
            </Flex>
        </Box>
    )
}
