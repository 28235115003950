import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdExternalAccount } from '@/hooks'
import { PageLayout } from '@/layouts'
import { getCustomFieldsRowCustomizers } from '@/pages'
import { PERMISSION } from '@/services'
import { ApiObjectTypeSchema, ExternalAccount } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ExternalAccountApproval } from './@components'
import {
    EXTERNAL_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
    EXTERNAL_ACCOUNT_DETAILS_ROWS
} from './ExternalAccountDetails.page.const'

export const ExternalAccountDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdExternalAccount(uuid)
    const rowCustomizers = useMemo(() => {
        return {
            ...EXTERNAL_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
            custom_fields: getCustomFieldsRowCustomizers(query.data, PERMISSION.COUNTERPARTIES_EDIT_EXTERNAL_ACCOUNTS)
        }
    }, [query])

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.COUNTERPARTIES_VIEW_EXTERNAL_ACCOUNTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.external_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<ExternalAccount>
                        data={query.data}
                        rows={EXTERNAL_ACCOUNT_DETAILS_ROWS}
                        rowCustomizers={rowCustomizers}
                    />
                    <If condition={hasPermission(PERMISSION.COUNTERPARTIES_APPROVE_EXTERNAL_ACCOUNTS)}>
                        <ExternalAccountApproval data={query.data} />
                    </If>
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
