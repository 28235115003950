import { chain } from 'lodash'
import { PERCENTAGE_SYMBOL } from '@/constants'

const percentageTempleExecutor = chain('${value}' + PERCENTAGE_SYMBOL)
    .template()
    .value()

const PERCENTAGE_ROUNDED_PRECISION = 0
const PERCENTAGE_DECIMALS_NOT_ROUNDED = 2

export function percentageFormatter(
    value?: number | string,
    isRounded: boolean = false,
    precision = PERCENTAGE_ROUNDED_PRECISION
): string {
    const valueWrapperWithDefault = chain(value).toNumber().defaultTo(0)
    const formattedValue = isRounded
        ? valueWrapperWithDefault.round(precision).value()
        : valueWrapperWithDefault.value().toFixed(PERCENTAGE_DECIMALS_NOT_ROUNDED)

    return percentageTempleExecutor({
        value: formattedValue
    })
}
