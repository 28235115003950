import {
    useConfiguration,
    useNavigationRoutes,
    usePrevious,
    useQueryFindAllUserAccesses,
    useQueryParams,
    useServices
} from '@/hooks'
import { ENTITY_FEATURE, PERMISSION, STORAGE_KEYS, StorageService, UserAccess } from '@/services'
import { timeoutCallbackRunner } from '@/utils'
import { useQueryClient } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'
import { head, isEmpty, isEqualWith, isNil, noop } from 'lodash'
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SupportedQueryParam } from '../QueryParamsProvider'
import { INACTIVITY_LOGOUT_TIME } from './AuthProvider.const'
import { AuthContext } from './AuthProvider.context'
import { getUserAccessByLegalEntityID, isFindAllUserAccessesQueryEnabled } from './AuthProvider.utils'

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { apiEnvironment } = useConfiguration()
    const { authenticationService } = useServices()
    const { getQueryParam, setQueryParam } = useQueryParams()
    const { paths } = useNavigationRoutes()
    const queryClient = useQueryClient()
    const location = useLocation()
    const navigate = useNavigate()
    const [selectedUserAccess, setSelectedUserAccess] = useState<UserAccess | undefined>(undefined)
    const [isUserLoggingOut, setIsUserLoggingOut] = useState<boolean>(false)
    const prevUserAccess = usePrevious<UserAccess>(selectedUserAccess)
    const [activatedFeatures, setActivatedFeatures] = useState<Set<ENTITY_FEATURE> | undefined>(undefined)
    const [userPermissions, setUserPermissions] = useState<Set<PERMISSION> | undefined>(undefined)

    const isUserLoggedIn = useMemo<boolean>(() => {
        return !!selectedUserAccess && !isUserLoggingOut
    }, [selectedUserAccess, isUserLoggingOut])

    const isQueryEnabled = useCallback(
        () => isFindAllUserAccessesQueryEnabled(selectedUserAccess, isUserLoggingOut, location.pathname),
        [selectedUserAccess, location.pathname]
    )

    const queryUserAccesses = useQueryFindAllUserAccesses({
        enabled: isQueryEnabled()
    })

    const getInitialUserAccess = useCallback(
        (userAccesses?: UserAccess[]) => {
            if (!userAccesses) {
                return
            }

            const queryParamLegalEntityID = getQueryParam(SupportedQueryParam.LEGAL_ENTITY_ID) as Uuid
            const localStoragePersistedLegalEntityID = StorageService.getItem(
                STORAGE_KEYS.SELECTED_LEGAL_ENTITY_ID
            ) as Uuid
            const defaultUserAccess = head(userAccesses)

            return (
                getUserAccessByLegalEntityID(userAccesses, queryParamLegalEntityID) ??
                getUserAccessByLegalEntityID(userAccesses, localStoragePersistedLegalEntityID) ??
                defaultUserAccess
            )
        },
        [getQueryParam, StorageService]
    )

    const onSetSelectedUserAccess = (userAccess?: UserAccess) => {
        if (isNil(userAccess) || isEqualWith(userAccess, prevUserAccess)) {
            return
        }

        setSelectedUserAccess(userAccess)
        setActivatedFeatures(new Set(userAccess.features))
        setUserPermissions(new Set(userAccess.permissions))
        StorageService.setItem(STORAGE_KEYS.SELECTED_LEGAL_ENTITY_ID, userAccess.legal_entity_id)

        if (userAccess.environment !== prevUserAccess?.environment) {
            apiEnvironment.setActiveEnvironmentByName(userAccess.environment)
        }

        // Invalidating all queries, this will as well rebuild all services with the new base URL
        queryClient.cancelQueries()
        queryClient.invalidateQueries()

        if (!isNil(prevUserAccess)) {
            // Temporary solution to avoid being on a non-existing detail page when switching accesses
            navigate(paths.ROOT)
        }
    }

    const onLogin = useCallback(() => {
        authenticationService.login(apiEnvironment.primary?.url)
    }, [authenticationService, apiEnvironment])

    const onLogout = useCallback(() => {
        // Update the state and letting the use effect triggering the logout process
        setIsUserLoggingOut(true)
    }, [setIsUserLoggingOut])

    useEffect(() => {
        if (isUserLoggingOut) {
            queryClient.cancelQueries().catch(noop)
            // Logout from the default environment first and let BFF redirect us to <ExtraLogout/> if needed
            authenticationService.logout(apiEnvironment.primary?.url)
        }
    }, [isUserLoggingOut])

    /**
     * Inactive users automatically get logged out after INACTIVITY_LOGOUT_TIME ms
     */
    useEffect(() => {
        const cancelTimeoutCallbackRunner = timeoutCallbackRunner(onLogout, INACTIVITY_LOGOUT_TIME)

        return () => {
            cancelTimeoutCallbackRunner?.()
        }
    }, [onLogout])

    /**
     * If not already set, get the default entry for a user or equivalent call.
     */
    useEffect(() => {
        if (selectedUserAccess) {
            return
        }

        if (queryUserAccesses.isError) {
            navigate(`${paths.ACCOUNT.ERROR}`)
            return
        }

        if (queryUserAccesses.isSuccess && isEmpty(queryUserAccesses.data)) {
            navigate(`${paths.ACCOUNT.ERROR}/?error_type=no_accesses`)
            return
        }

        const initialUserAccess = getInitialUserAccess(queryUserAccesses.data)
        onSetSelectedUserAccess(initialUserAccess)
    }, [
        queryUserAccesses.data,
        queryUserAccesses.isError,
        queryUserAccesses.isSuccess,
        paths,
        selectedUserAccess,
        navigate,
        getInitialUserAccess
    ])

    return (
        <AuthContext.Provider
            value={{
                isUserLoggedIn,
                onLogin,
                isUserLoggingOut,
                onLogout,
                userAccesses: queryUserAccesses.data,
                selectedUserAccess,
                selectUserAccess: onSetSelectedUserAccess,
                activatedFeatures,
                userPermissions
            }}>
            {children}
        </AuthContext.Provider>
    )
}
