import { Link } from '@chakra-ui/react'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'

interface ShowMoreProps {
    isExpanded: boolean
    onClick: () => void
}

export const ShowMore: React.FC<ShowMoreProps> = memo(({ isExpanded, onClick }) => {
    return (
        <Link onClick={onClick}>
            <FormattedMessage id={`app.common.actions.${isExpanded ? 'show_less' : 'show_more'}`} />
        </Link>
    )
})
