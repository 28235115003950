import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { markedFieldsTransform } from '@/services/@transformRequests'
import { addTransformRequestsToHttpClientInstance } from '@/services/@transformRequests/transformRequests.utils'
import { APIError } from '@/types'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { DirectDebitMandate } from '@webapps/numeral-ui-core'
import { AxiosError } from 'axios'
import { noop } from 'lodash'

export function useMutationCreateDirectDebitMandate(
    options?: MutationOptions<
        Partial<DirectDebitMandate> | void,
        AxiosError<APIError<DirectDebitMandate>>,
        DirectDebitMandate
    >
) {
    const { selectedUserAccess } = useAuth()
    const { directDebitMandatesService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<DirectDebitMandate | void, AxiosError<APIError<DirectDebitMandate>>, DirectDebitMandate>({
        ...options,
        mutationKey: [directDebitMandatesService.url],
        mutationFn(data) {
            return directDebitMandatesService.createDirectDebitMandate(data, {
                params: paramsWithUserAccess,
                transformRequest: addTransformRequestsToHttpClientInstance(
                    directDebitMandatesService,
                    markedFieldsTransform
                )
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [directDebitMandatesService.url]
                })
                .catch(noop)
        }
    })
}
