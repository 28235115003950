import { DEFAULT_CURRENCY_CODE } from '@/constants'
import { computePaymentPropertiesByServiceName } from '@/services'
import { ConnectedAccount, CurrencyCode, ExpectedPaymentCreateData, ServiceName } from '@webapps/numeral-ui-core'
import { chain } from 'lodash'

export function computeExpectedPaymentFormDerivedFields(
    account?: ConnectedAccount
): Partial<ExpectedPaymentCreateData> {
    const item: Partial<ExpectedPaymentCreateData> = Object.create(null)
    const serviceName = chain(account).get('services_activated').head().value()
    const { direction } = computePaymentPropertiesByServiceName(serviceName as ServiceName)

    item.direction = direction
    item.connected_account_id = account?.id

    return item
}

export function getExpectedPaymentFormInitialValues(data?: ConnectedAccount[]): ExpectedPaymentCreateData {
    const item: ExpectedPaymentCreateData = Object.create(null)

    item.connected_account_id = ''
    item.direction = '' as any
    item.currency = DEFAULT_CURRENCY_CODE as CurrencyCode

    item.amount_from = '' as any
    item.amount_to = '' as any

    item.start_date = ''
    item.end_date = ''

    item.internal_account_id = ''
    item.internal_account = {
        account_number: ''
    }

    item.external_account_id = ''
    item.external_account = {
        holder_name: '',
        account_number: ''
    }
    item.descriptions = ['']

    return item
}
