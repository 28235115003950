import { Widget, WidgetQueryFallback } from '@/components'
import { ChartBarHorizontalStack } from '@/components/@charts'
import { If } from '@/components/@misc'
import { useAPIQueryParams, usePermissions, useQueryPaymentOrderReturns } from '@/hooks'
import { HOME_PAGE_COMMON_WIDGET_PRESELECTED_FILTERS } from '@/pages/Home/Home.page.const'
import {
    PERMISSION,
    WIDGET_TYPE,
    WidgetsServiceBalancesQueryOptions,
    WidgetsServiceFindAllQueryOptions
} from '@/services'
import { percentageFormatter } from '@/utils/@formatters'
import { Stack, Text } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PAYMENT_ORDER_RETURNS_WIDGET_CHART_OPTIONS } from './PaymentOrderReturnsWidget.const'
import { paymentOrderReturnsDatasetMapperWithIntl } from './PaymentOrderReturnsWidget.utils'

export const PaymentOrderReturnsWidget: React.FC<WidgetsServiceFindAllQueryOptions> = (props) => {
    const intl = useIntl()
    const { filterBy } = useAPIQueryParams<WidgetsServiceBalancesQueryOptions>({
        filterBy: {
            configuration: {
                preselected: {
                    ...props,
                    ...HOME_PAGE_COMMON_WIDGET_PRESELECTED_FILTERS
                },
                uniqueId: WIDGET_TYPE.PaymentOrderReturns
            }
        }
    })
    const query = useQueryPaymentOrderReturns(filterBy.parsedState)
    const chartDatasetMapper = useMemo(() => {
        return paymentOrderReturnsDatasetMapperWithIntl(intl)
    }, [intl])
    const chartData = useMemo(() => {
        if (!query.data) {
            return {
                labels: [],
                datasets: []
            }
        }

        return {
            labels: [intl.formatMessage({ id: 'app.widgets.payment_order_returns.dataset.label' })],
            datasets: query.data?.records?.map(chartDatasetMapper)
        }
    }, [query, intl])
    const title = useMemo(
        () =>
            intl.formatMessage({
                id: 'app.widgets.payment_order_returns.title'
            }),
        [intl]
    )
    const isDataEmpty = useMemo(() => {
        return isEmpty(query.data?.records)
    }, [query])
    const { hasPermission } = usePermissions()
    const hasViewReturnsPermission = useMemo(() => {
        return hasPermission(PERMISSION.HOME_VIEW_RETURNS)
    }, [hasPermission])
    const formattedReturnRate = useMemo(() => {
        return percentageFormatter(query.data?.return_rate, true, 1)
    }, [query.data])

    return (
        <If condition={hasViewReturnsPermission}>
            <Widget title={title}>
                <WidgetQueryFallback isLoading={query.isLoading} isDataEmpty={isDataEmpty}>
                    <Stack gap="8px">
                        <Stack marginBottom="32px" gap="8px">
                            <Text fontSize="12px" lineHeight="16px" color="gray.500">
                                <FormattedMessage id="app.widgets.payment_order_returns.return_rate" />
                            </Text>
                            <Text fontSize="24px" lineHeight="32px" color="gray.800">
                                {formattedReturnRate}
                            </Text>
                        </Stack>
                        <ChartBarHorizontalStack
                            data={chartData}
                            options={PAYMENT_ORDER_RETURNS_WIDGET_CHART_OPTIONS}
                            marginY="60px"
                            height="120px"
                            showLegend={true}
                        />
                    </Stack>
                </WidgetQueryFallback>
            </Widget>
        </If>
    )
}
