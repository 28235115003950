import { EntityDetailCellTextWithCopyButton, EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { EnabledStatus, FormattedDateTime } from '@/components/@misc'
import { AccountHolder } from '@webapps/numeral-ui-core'
import { AccountHolderDetailsRelatedObjects } from './@components'
import { AccountHolderCustomRows } from './AccountHolderDetails.page.types'

export const ACCOUNT_HOLDER_DETAILS_ROWS: EntityDetailRow<AccountHolder, AccountHolderCustomRows>[] = [
    'id',
    'created_at',
    'name',
    'status',
    'disabled_at',
    'related_objects',
    'metadata'
]

export const ACCOUNT_HOLDER_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    AccountHolder,
    AccountHolderCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    status: {
        cell(item) {
            const isDisabled = !!item?.disabled_at
            return <EnabledStatus isDisabled={isDisabled} />
        }
    },
    disabled_at: {
        cell(item) {
            return <FormattedDateTime value={item.disabled_at} />
        }
    },
    related_objects: {
        cell(item) {
            return <AccountHolderDetailsRelatedObjects accountHolderId={item.id} />
        }
    },
    metadata: {
        nested: true
    }
}
