import { Form, FormPageFooter, FormSection } from '@/components'
import { AccountDetailsForm, OptionalAccountHolderAddressForm } from '@/components/@forms'
import { AccountHolderInput, TextInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { CustomFieldsSection } from '@/pages'
import { enabledAccountHolderFilter } from '@/services'
import { ApiObjectTypeSchema, ExternalAccount, ExternalAccountTypeSchema } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { useMemo } from 'react'
import { ExternalAccountCompanyRegistrationNumberTypeInput, ExternalAccountTypeInput } from './@components'
import { ExternalAccountFormProps } from './ExternalAccount.form.types'
import { getExternalAccountFormInitialValues } from './ExternalAccount.form.utils'

export const ExternalAccountForm: React.FC<ExternalAccountFormProps> = ({ disabled, loading, onSubmit = noop }) => {
    const initialValues = useMemo<ExternalAccount>(getExternalAccountFormInitialValues, [])

    return (
        <Form<ExternalAccount> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, dirty }) => {
                const isLegalEntityAccount = values.type === ExternalAccountTypeSchema.enum.legal_entity
                const hasCompanyRegistrationNumberType = globalThis.Boolean(values.company_registration_number_type)

                return (
                    <>
                        <FormSection titleId="Account holder">
                            <ExternalAccountTypeInput
                                name="type"
                                customLabelKey="app.counterparties.external_accounts.create.form.inputs.type.label"
                                customPlaceholderKey="app.counterparties.external_accounts.create.form.inputs.type.placeholder"
                                isDisabled={disabled}
                            />
                            <AccountHolderInput
                                name="account_holder_id"
                                queryDataFilter={enabledAccountHolderFilter}
                                customPlaceholderKey="app.counterparties.external_accounts.create.form.inputs.account_holder_id.placeholder"
                                isDisabled={disabled}
                            />
                            <TextInput
                                name="name"
                                customPlaceholderKey="app.counterparties.external_accounts.create.form.inputs.holder_name.placeholder"
                                isDisabled={disabled}
                                data-testid="name"
                            />
                            <If condition={isLegalEntityAccount}>
                                <ExternalAccountCompanyRegistrationNumberTypeInput
                                    name="company_registration_number_type"
                                    customLabelKey="app.counterparties.external_accounts.create.form.inputs.company_registration_number_type.label"
                                    customPlaceholderKey="app.counterparties.external_accounts.create.form.inputs.company_registration_number_type.placeholder"
                                    isDisabled={disabled}
                                    isRequired={true}
                                />
                                <TextInput
                                    name="company_registration_number"
                                    customLabelKey="app.counterparties.external_accounts.create.form.inputs.company_registration_number.label"
                                    customPlaceholderKey="app.counterparties.external_accounts.create.form.inputs.company_registration_number.placeholder"
                                    isDisabled={!hasCompanyRegistrationNumberType || disabled}
                                    isRequired={true}
                                />
                            </If>
                        </FormSection>
                        <FormSection titleId="Account details">
                            <AccountDetailsForm isLookupAvailable={true} isDisabled={disabled} isRequired={true} />
                        </FormSection>

                        <OptionalAccountHolderAddressForm isDisabled={disabled} />
                        <CustomFieldsSection<ExternalAccount>
                            objectType={ApiObjectTypeSchema.enum.external_account}
                            isDisabled={disabled}
                            isExpanded={false}
                        />

                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.counterparties.external_accounts.create.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
