import { usePermissions, useQueryProcessedPaymentsTotalNumber } from '@/hooks'
import { PERMISSION, WidgetsServiceFindAllQueryOptions } from '@/services'
import { FormattedNumber, useIntl } from 'react-intl'
import { memo, useMemo } from 'react'
import { chain } from 'lodash'
import { Widget, WidgetQueryFallback, WidgetVariants } from '@/components'
import { Text } from '@chakra-ui/react'
import { If } from '@/components/@misc'

export const TotalNumberPaymentsProcessedWidget: React.FC<WidgetsServiceFindAllQueryOptions> = memo((props) => {
    const { hasPermission } = usePermissions()
    const intl = useIntl()
    const query = useQueryProcessedPaymentsTotalNumber(props)
    const title = useMemo(
        () =>
            intl.formatMessage({
                id: 'app.widgets.total_number_payments_processed.title'
            }),
        [intl]
    )
    const isDataEmpty = useMemo(() => !query.data, [query.data])
    const hasViewProcessedPaymentsTotalNumberPermission = useMemo(() => {
        return hasPermission(PERMISSION.HOME_VIEW_PROCESSED_PAYMENTS_TOTAL_NUMBER)
    }, [hasPermission])

    const value = chain(query.data?.total_number).defaultTo(0).value()
    const label = value.toString()

    return (
        <If condition={hasViewProcessedPaymentsTotalNumberPermission}>
            <Widget title={title} variant={WidgetVariants.Lite}>
                <WidgetQueryFallback
                    isLoading={query.isLoading}
                    isDataEmpty={isDataEmpty}
                    variant={WidgetVariants.Lite}>
                    <Text fontSize="24px" aria-label={label}>
                        <FormattedNumber value={value} />
                    </Text>
                </WidgetQueryFallback>
            </Widget>
        </If>
    )
})
