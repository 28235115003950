import { TableCellShortDate, TableCellText, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { StatusLabel } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, MIN_COLUMN_WIDTH } from '@/constants'
import { accountNumberFormatter } from '@/utils/@formatters'
import { InternalAccount } from '@webapps/numeral-ui-core'

export const CONNECTED_ACCOUNT_INTERNAL_ACCOUNTS_TABLE_STATIC_STATE: TableStateWithEntity<InternalAccount> = {
    columnOrder: Object.freeze(['created_at', 'name', 'account_number', 'status'])
}

export const CONNECTED_ACCOUNT_INTERNAL_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<InternalAccount> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    name: {
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    account_number: {
        minSize: MIN_COLUMN_WIDTH.ACCOUNT_NUMBER,
        cell(info) {
            const formattedValue = accountNumberFormatter(info.getValue())

            return <TableCellText value={formattedValue} />
        }
    },
    status: {
        cell(info) {
            return <StatusLabel value={info.getValue()} />
        }
    }
}
