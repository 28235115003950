import { Form, FormPageFooter, InputWithCopyToClipboard } from '@/components'
import { Webhook, WEBHOOK_RULES_MODES } from '@/services'
import { Input } from '@chakra-ui/react'
import { noop } from 'lodash'
import { useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { getWebhookFormInitialValues, webhookNameValidator } from './WebhookCreateOrEdit.form.utils'
import { If } from '@/components/@misc'
import { TextInput } from '@/components/@inputs'
import { areIndividualWebhookRulesEnabled, getWebhookRulesModeInitialValue } from '../../Webhooks.page.utils'
import { urlValidator } from '@/utils/@validators'

interface WebhookCreateOrEditFormProps {
    data?: Webhook
    loading?: boolean
    disabled?: boolean
    readonly?: boolean
    onSubmit(data: Partial<Webhook>): void
}

export const WebhookCreateOrEditForm: React.FC<WebhookCreateOrEditFormProps> = ({
    data,
    disabled,
    readonly,
    loading,
    onSubmit = noop
}) => {
    const intl = useIntl()
    const [rulesMode, setRulesMode] = useState<WEBHOOK_RULES_MODES>(getWebhookRulesModeInitialValue(data))
    const isEditMode = useRef(globalThis.Boolean(data?.id)).current
    const initialValues = useMemo(() => getWebhookFormInitialValues(data), [data])
    const hasIndividualWebhookRulesEnabled = useMemo(() => areIndividualWebhookRulesEnabled(rulesMode), [rulesMode])
    const submitLabelMessageId = useMemo<string | undefined>(() => {
        return isEditMode ? undefined : 'app.developers.webhooks.form.actions.submit.label'
    }, [isEditMode])

    /*
     * @description
     * Enable when priorities are ready - (now compatible with API implementation)
        const getGroupLabel = (group: string) => {
            return prettyPrintCasedWords(group)
        }
        const getGroupCounterLabel = (group: string, selected: number, available: number) => {
            return intl.formatMessage(
                {
                    id: 'app.developers.webhooks.form.rules.group_number_of_rules.label',
                    defaultMessage: group
                },
                {
                    selected,
                    available
                }
            )
        }
    */

    return (
        <Form<Webhook> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, dirty, setFieldValue }) => {
                const isInputDisabled = isEditMode || loading || disabled
                /*
                 * @description
                 * Enable when priorities are ready - (now compatible with API implementation)
                    const onToggleRulesMode = (value: WEBHOOK_RULES_MODES) => {
                        const rulesValue = areIndividualWebhookRulesEnabled(value) ? values?.rules : Object.create(null)

                        setFieldValue('rules', rulesValue)
                        setRulesMode(value)
                    }
                */

                return (
                    <>
                        <If condition={isEditMode}>
                            <TextInput name="id" isDisabled={isInputDisabled} as={InputWithCopyToClipboard} />
                        </If>
                        <TextInput
                            name="name"
                            customPlaceholderKey="app.developers.webhooks.form.name.placeholder"
                            validator={webhookNameValidator}
                            isRequired={true}
                            isDisabled={loading}
                        />
                        <TextInput
                            name="url"
                            customPlaceholderKey="app.developers.webhooks.form.url.placeholder"
                            isDisabled={isInputDisabled}
                            validator={urlValidator}
                            isRequired={true}
                            as={isEditMode ? InputWithCopyToClipboard : Input}
                        />
                        {/*
                          * @description
                          * Enable when priorities are ready - (now compatible with API implementation)
                        <FormControl>
                            <FormLabel htmlFor="topicsFilterMode">
                                <FormattedMessage id="app.developers.webhooks.form.rules_mode.label" />
                            </FormLabel>
                            <RadioGroup
                                id="rulesMode"
                                name="rulesMode"
                                value={rulesMode}
                                onChange={onToggleRulesMode}
                                isDisabled={isEditMode}>
                                <Flex gap="16px">
                                    <Radio value={WEBHOOK_RULES_MODES.ALL}>
                                        <FormattedMessage id="app.developers.webhooks.form.rules_mode.all.label" />
                                    </Radio>
                                    <Radio value={WEBHOOK_RULES_MODES.INDIVIDUAL}>
                                        <FormattedMessage id="app.developers.webhooks.form.rules_mode.individual.label" />
                                    </Radio>
                                </Flex>
                            </RadioGroup>
                        </FormControl>
                        <If condition={hasIndividualWebhookRulesEnabled}>
                            <GroupAccordionInput<WebhookRules>
                                name="rules"
                                options={WEBHOOK_RULE_OPTIONS}
                                getGroupLabel={getGroupLabel}
                                getGroupCounterLabel={getGroupCounterLabel}
                                isReadOnly={readonly}
                                isDisabled={isEditMode}
                            />
                        </If>*/}
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={loading || !dirty}
                            isLoading={loading}
                            submitLabelMessageId={submitLabelMessageId}
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
