import { Table, TableStateWithEntity } from '@/components'
import { If } from '@/components/@misc'
import { API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES } from '@/hooks'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Text } from '@chakra-ui/react'
import { chain, isFunction, isUndefined } from 'lodash'
import { useCallback, useMemo, useRef } from 'react'
import { GroupByTable } from '../GroupByTable'
import { GroupByTableEntryTypesProps } from './GroupByTableEntry.types'
import { generateGroupByUniqueKey, getGroupByAccordionItemStyle } from './GroupByTableEntry.utils'

const GROUP_BY_TABLE_ENTRY_PADDING = '16px'

export function GroupByTableEntry<T, G>({
    parentGroupBy,
    groupHeader,
    state,
    groups,
    group,
    parentGroup,
    depth,
    index,
    isExpandedEntries = Object.create(null),
    ...props
}: GroupByTableEntryTypesProps<T, G>) {
    const currentDepth = useMemo(() => {
        return chain(depth).add(1).value()
    }, [depth])
    const tableState = useMemo<TableStateWithEntity<T>>(() => {
        if (props.isGroupByRecordColumnsVisible) {
            return {
                ...state,
                columnOrder: state.columnOrder?.filter((column) => {
                    return column !== group.group_by
                })
            }
        }

        return state
    }, [props.isGroupByRecordColumnsVisible, state, group])
    const propsAccordionItem = useMemo(() => {
        return getGroupByAccordionItemStyle(groups, depth, index)
    }, [groups, depth, index])
    const groupEntryUniqueKey = useMemo(() => {
        return generateGroupByUniqueKey(group.group, depth as number, index as number)
    }, [group.group, depth, index])
    const currentIndex = useMemo<number>(() => {
        return isUndefined(props.isExpanded)
            ? groupEntryUniqueKey in isExpandedEntries
                ? isExpandedEntries[groupEntryUniqueKey]
                : API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES.EXPANDED // Default expanded
            : props.isExpanded
              ? API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES.EXPANDED
              : API_QUERY_PARAMS_GROUP_BY_TABLE_ENTRY_STATES.COLLAPSED
    }, [props.isExpanded, isExpandedEntries, groupEntryUniqueKey])

    const onToggleGroupByTableEntry = useRef(props.getToggleGroupByExpandedEntries?.(groupEntryUniqueKey))
    const onChange = useCallback(
        (index: number) => {
            return onToggleGroupByTableEntry.current?.(index)
        },
        [onToggleGroupByTableEntry]
    )

    const hasRecords = globalThis.Boolean(group?.records?.length)
    const hasGroups = globalThis.Boolean(group?.groups?.length)

    const marginAndPaddingBottom = useMemo(() => {
        const depthAsNumber = globalThis.Number(depth)
        const marginBottom = depthAsNumber === 0 ? GROUP_BY_TABLE_ENTRY_PADDING : 0
        const paddingBottom = depthAsNumber >= 0 ? GROUP_BY_TABLE_ENTRY_PADDING : 0

        return {
            marginBottom,
            paddingBottom
        }
    }, [depth])

    const GroupHeader = useMemo(() => {
        if (groupHeader && isFunction(groupHeader)) {
            const Component = groupHeader
            return <Component group={group} parentGroupBy={parentGroupBy} />
        }

        return <Text>{group.group}</Text>
    }, [groupHeader, group, parentGroupBy])

    return (
        <Accordion
            allowToggle={true}
            marginBottom={marginAndPaddingBottom.marginBottom}
            border="unset"
            index={currentIndex}
            onChange={onChange}
            key={index}>
            <AccordionItem
                isDisabled={!(hasRecords || hasGroups)}
                border="1px solid"
                borderColor="gray.200"
                overflow="hidden"
                {...propsAccordionItem}>
                <AccordionButton padding="16px">{GroupHeader}</AccordionButton>
                <If condition={hasRecords || hasGroups}>
                    <AccordionPanel paddingBottom={marginAndPaddingBottom.paddingBottom} overflow="hidden">
                        <GroupByTable<T, G>
                            groupHeader={groupHeader}
                            data={group as any}
                            parentGroupBy={group.group_by}
                            depth={currentDepth}
                            index={index}
                            {...props}
                            isExpandedEntries={isExpandedEntries}
                            state={tableState}
                        />
                        <If condition={hasRecords}>
                            <Table<T>
                                {...props}
                                state={tableState}
                                data={group?.records}
                                hasStickyHeader={false}
                                hasSmallHeader={true}
                            />
                        </If>
                    </AccordionPanel>
                </If>
            </AccordionItem>
        </Accordion>
    )
}
