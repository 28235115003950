import { addMinutes, isBefore } from 'date-fns'
import { chain, template } from 'lodash'
import { RECENTLY_DISABLED_DURATION_IN_MINUTES } from './APIKeys.service.const'
import { APIKey } from './APIKeys.service.types'

export function isAPIKeyDisabled(data?: APIKey): boolean {
    return !chain(data).get('disabled_at').isNull().value()
}

export function getAPIKeyStatusMapper(data?: APIKey): 'disabled' | 'enabled' {
    return isAPIKeyDisabled(data) ? 'disabled' : 'enabled'
}

export function isAPIKeyRecentlyDisabled(disabledDate?: string | null): boolean {
    if (!disabledDate) {
        return false
    }

    const currentDate = new Date()
    const isRecentlyDisableDateThreshold = addMinutes(new Date(disabledDate), RECENTLY_DISABLED_DURATION_IN_MINUTES)

    return isBefore(currentDate, isRecentlyDisableDateThreshold)
}

export function getAPIKeyLast4CharsPlaceholderTemplate(data?: Partial<APIKey>) {
    const compiledTemplate = template('****<%= last_4_chars %>')

    return compiledTemplate({ last_4_chars: data?.last_4_chars })
}
