import { enabledConnectedAccountsFilter, hasConnectedAccountsActivatedServices } from '@/services'
import { combineFilters } from '@/utils'
import {
    ConnectedAccount,
    DirectDebitMandate,
    DirectDebitMandateDirectionSchema,
    DirectDebitMandateTypeSchema,
    ServiceName,
    ServiceNameSchema
} from '@webapps/numeral-ui-core'

export function isDirectDebitMandateBacsDebitType(directDebitMandate: Partial<DirectDebitMandate>) {
    return directDebitMandate?.type === DirectDebitMandateTypeSchema.enum.bacs_debit
}

export function isDirectDebitMandateIncoming(directDebitMandate?: DirectDebitMandate) {
    return directDebitMandate?.direction === DirectDebitMandateDirectionSchema.enum.incoming
}

export function createDirectDebitMandateConnectedAccountServicesFilter(account: ConnectedAccount): boolean {
    if (!hasConnectedAccountsActivatedServices(account)) {
        return false
    }

    return account?.services_activated.filter(globalThis.Boolean).some((serviceName: ServiceName) => {
        switch (serviceName) {
            case ServiceNameSchema.enum.sdd:
            case ServiceNameSchema.enum.bacs_direct_debit: {
                return true
            }

            default: {
                return false
            }
        }
    })
}

export const createDirectDebitMandateConnectedAccountsCombinedFilter = combineFilters<ConnectedAccount>(
    enabledConnectedAccountsFilter,
    createDirectDebitMandateConnectedAccountServicesFilter
)
