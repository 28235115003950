import { NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS } from '@/providers'
import {
    FileCategorySchema,
    FileDirectionSchema,
    NumeralFile,
    NumeralFileCategory,
    NumeralFileDirection
} from '@webapps/numeral-ui-core'
import { chain } from 'lodash'

export function isUploadedPaymentFile(file: NumeralFile) {
    return file.category === FileCategorySchema.enum.uploaded_payment_file
}

export function relatedObjectsPathFromFileCategoryReducer(
    fileCategory: NumeralFileCategory,
    fileDirection: NumeralFileDirection
): string[] {
    const { PAYMENTS, ACCOUNTS } = NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS

    switch (fileCategory) {
        case FileCategorySchema.enum.payment_file: {
            return fileDirection === FileDirectionSchema.enum.incoming
                ? [PAYMENTS.INCOMING_PAYMENTS]
                : [PAYMENTS.PAYMENT_ORDERS]
        }

        case FileCategorySchema.enum.account_statement: {
            return [ACCOUNTS.TRANSACTIONS, ACCOUNTS.BALANCES]
        }

        case FileCategorySchema.enum.return_request_file: {
            return [PAYMENTS.RETURN_REQUESTS]
        }

        case FileCategorySchema.enum.return_file: {
            return [PAYMENTS.RETURNS]
        }

        case FileCategorySchema.enum.inquiry_category: {
            return [PAYMENTS.INQUIRIES]
        }

        default: {
            return []
        }
    }
}

export function relatedObjectsLabelFromPathReducer(path: string) {
    const intlRootPath = 'app.page.details.cells.related_objects'
    const transformedPartialPath = chain<string>(path).split('/').last().snakeCase().value()

    return `${intlRootPath}.${transformedPartialPath}.link`
}
