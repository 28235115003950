import { Widget, WidgetQueryFallback } from '@/components'
import { If } from '@/components/@misc'
import { useAPIQueryParams, usePermissions, useQueryFindAllEvents } from '@/hooks'
import { PERMISSION, WIDGET_TYPE } from '@/services'
import { queryDataAggregation } from '@/utils'
import { Box } from '@chakra-ui/react'
import { EventsServiceFindAllQueryOptions, NumeralEvent } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { WidgetLiteTable } from '../WidgetLiteTable'
import {
    LATEST_EVENTS_WIDGET_COLUMN_CUSTOMIZERS,
    LATEST_EVENTS_WIDGET_PRESELECTED_FILTERS,
    LATEST_EVENTS_WIDGET_TABLE_STATIC_STATE
} from './LatestEventsWidget.const'
import './LatestEventsWidget.scss'

export const LatestEventsWidget: React.FC = () => {
    const intl = useIntl()
    const { filterBy } = useAPIQueryParams<EventsServiceFindAllQueryOptions>({
        filterBy: {
            configuration: {
                preselected: LATEST_EVENTS_WIDGET_PRESELECTED_FILTERS,
                uniqueId: WIDGET_TYPE.LatestEvents
            }
        }
    })
    const query = useQueryFindAllEvents(filterBy.parsedState)
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo(() => isEmpty(data), [data])
    const title = useMemo(
        () =>
            intl.formatMessage({
                id: 'app.widgets.latest_events.title'
            }),
        [intl]
    )
    const { hasPermission } = usePermissions()
    const hasViewEventsPermission = useMemo(() => {
        return hasPermission(PERMISSION.DEVELOPERS_VIEW_EVENTS)
    }, [hasPermission])

    return (
        <If condition={hasViewEventsPermission}>
            <Widget title={title}>
                <WidgetQueryFallback isLoading={query.isLoading} isError={query.isError} isDataEmpty={isDataEmpty}>
                    <Box className="LatestEventsWidget-GradientOverlay">
                        <Box className="LatestEventsWidget-GradientOverlay-Table" />
                        <WidgetLiteTable<NumeralEvent>
                            className="LatestEventsWidget-Table"
                            state={LATEST_EVENTS_WIDGET_TABLE_STATIC_STATE}
                            columnCustomizers={LATEST_EVENTS_WIDGET_COLUMN_CUSTOMIZERS}
                            data={data}
                        />
                    </Box>
                </WidgetQueryFallback>
            </Widget>
        </If>
    )
}
