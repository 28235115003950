import { Link, LinkProps } from '@chakra-ui/react'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'

interface ExternalLinkProps extends LinkProps {
    labelKey: string
    url: string
}

export const ExternalLink: React.FC<ExternalLinkProps> = memo(({ labelKey, url, ...linkProps }) => {
    return (
        <Link {...linkProps} href={url} target="_blank">
            <FormattedMessage id={labelKey} />
        </Link>
    )
})
