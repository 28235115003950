import type { ChartOptions } from 'chart.js'

export const CHART_DATASET_COLORS = {
    NUMERAL_RED: 'rgba(217, 131, 104, 1)',
    NUMERAL_GREEN: 'rgba(73, 184, 131, 1)',
    NUMERAL_YELLOW: 'rgba(243, 204, 85, 1)',
    NUMERAL_LIGHT_BLUE: 'rgba(209, 209, 255, 1)',
    NUMERAL_BLUE: 'rgba(121, 146, 242, 1)',
    NUMERAL_DARK_BLUE: 'rgba(65, 81, 140, 1)'
}

export const CHART_BASE_COLORS = {
    NUMERAL_GRAY: 'rgb(113, 128, 150)',
    NUMERAL_LIGHT_GRAY: 'rgb(200, 200, 200)',
    WHITE: 'rgba(255, 255, 255, 1)'
}

export const CHART_HUE_SETTINGS = {
    START: 230,
    END: 270,
    SATURATION: 0.5,
    LIGHTNESS: 0.6,
    ALPHA: 0.9,
    ARC: 360
}

export const CHART_MAX_BAR_WIDTH = 100

export const CHART_OPTIONS: ChartOptions<any> = {
    LEGEND: {
        display: false
    },
    SCALES: {
        alignToPixels: true,
        y: {
            grid: {
                display: false
            }
        }
    },
    TOOLTIP: {
        cornerRadius: 2
    },
    TICKS: {
        padding: 16,
        font: {
            size: 12,
            family: 'Inter'
        },
        color: CHART_BASE_COLORS.NUMERAL_GRAY
    },
    ANIMATION: {
        duration: 300,
        easing: 'easeOutQuart'
    }
}
