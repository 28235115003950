import { APIQueryParamTypes, UseAPIQueryParamsResult, usePage, usePrevious } from '@/hooks'
import { RECONCILE_ENTITY_PAGE_CONTEXT } from '@/pages'
import { isEmptyRecord } from '@/utils'
import { Uuid } from '@webapps/numeral-ui-core'
import { chain, isNull, noop } from 'lodash'
import { useEffect, useMemo } from 'react'

interface useBulkActionsResetAllSelectionsProps<T> {
    search: UseAPIQueryParamsResult<APIQueryParamTypes.Search>
    filterBy: UseAPIQueryParamsResult<APIQueryParamTypes.FilterBy, T>
    isActive: boolean
    onChangeSelection(value?: Set<Uuid>): void
    onChangeSelectAllQueryEnabled(value?: boolean): void
}

/**
 * @description
 * Documented special cases for implicit auto reset (including persisted selections):
 * 1. Page query search parameters changes (`filterBy`, `search`, etc.);
 * 2. Page `context` value changes from `undefined` to any value;
 * 3. Page `context` value changes from any value to `undefined`;
 * 4. Page `context` RECONCILE_ENTITY_PAGE_CONTEXT is set to `null`;
 */
export function useBulkActionsAutoReset<T>({
    search,
    filterBy,
    isActive,
    onChangeSelection = noop,
    onChangeSelectAllQueryEnabled = noop
}: useBulkActionsResetAllSelectionsProps<T>) {
    const currentAPIQueryParams = {
        ...search?.parsedState,
        ...filterBy?.parsedState
    }
    const { context, getNonPersistedState, resetNonPersistedState } = usePage()
    const previousContext = usePrevious(context)

    const previousAPIQueryParams = usePrevious(currentAPIQueryParams)
    const hasDifferentAPIQueryParams = useMemo(() => {
        return !chain(previousAPIQueryParams).isEqual(currentAPIQueryParams).value()
    }, [previousAPIQueryParams, currentAPIQueryParams])

    const hasPreviousAPIQueryParams = useMemo(() => {
        return !chain(previousAPIQueryParams).isEmpty().value()
    }, [previousAPIQueryParams, search, filterBy])

    useEffect(() => {
        const currentReconcileContext = getNonPersistedState(RECONCILE_ENTITY_PAGE_CONTEXT)
        const hasNullCurrentReconcileContext = isNull(currentReconcileContext)

        const hasEmptyPreviousPageContext = isEmptyRecord(previousContext)
        const hasEmptyCurrentPageContext = isEmptyRecord(context)

        const hasEmptyCurrentAPIQueryParams = isEmptyRecord(currentAPIQueryParams)
        const hasEmptyPreviousAPIQueryParams = isEmptyRecord(previousAPIQueryParams)

        switch (true) {
            // 0. Page state with new location.state (current query params) and previously isActive=false;
            // 1. Page query search parameters changes (`filterBy`, `search`, etc.);
            // 2. Page `context` value changes from `undefined` to any value;
            // 3. Page `context` value changes from any value to `undefined`;
            case !hasEmptyCurrentAPIQueryParams && hasEmptyPreviousAPIQueryParams && !isActive:
            case hasPreviousAPIQueryParams && hasDifferentAPIQueryParams:
            case hasEmptyPreviousPageContext && !hasEmptyCurrentPageContext:
            case !hasEmptyPreviousPageContext && hasEmptyCurrentPageContext: {
                onChangeSelection?.(undefined)
                onChangeSelectAllQueryEnabled?.(false)
                break
            }

            // 4. Page `context` RECONCILE_ENTITY_PAGE_CONTEXT is set to `null`;
            case hasNullCurrentReconcileContext: {
                onChangeSelection?.(undefined)
                resetNonPersistedState()
                break
            }
        }
    }, [
        isActive,
        previousAPIQueryParams,
        currentAPIQueryParams,
        hasPreviousAPIQueryParams,
        hasDifferentAPIQueryParams,
        previousContext,
        context
    ])
}
