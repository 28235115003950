import { EntityAction } from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdAccountHolder } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { enabledAccountHolderFilter, PERMISSION } from '@/services'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { AccountHolderDisableConfirmationModal } from './@components'

export const AccountHolderHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdAccountHolder(uuid)

    const [isDisableModalOpen, setIsDisableModalOpen] = useState(false)
    const onCloseDisableModal = useCallback(() => {
        setIsDisableModalOpen(false)
    }, [])

    const actions = useMemo<EntityAction[]>(() => {
        const actions = []

        const isDisableAvailable =
            hasPermission(PERMISSION.COUNTERPARTIES_EDIT_ACCOUNT_HOLDERS) &&
            !!query?.data &&
            enabledAccountHolderFilter(query.data)

        if (isDisableAvailable) {
            actions.push({
                onClick() {
                    setIsDisableModalOpen(true)
                },
                color: 'red.500',
                isDisabled: query.isLoading,
                children: intl.formatMessage({
                    id: 'app.common.actions.disable.label'
                })
            })
        }

        return actions
    }, [intl, query])

    const title = useMemo<ReactNode>(() => {
        const notFoundTitle = intl.formatMessage({
            id: 'app.common.not_found.title'
        })
        return (query.isError && notFoundTitle) || query.data?.name || uuid
    }, [intl, query, uuid])

    if (!hasPermission(PERMISSION.COUNTERPARTIES_VIEW_ACCOUNT_HOLDERS)) {
        return null
    }

    return (
        <>
            <PageHeaderLayout title={title} actions={actions} isLoading={query.isLoading} disabled={query.isError} />
            <If condition={hasPermission(PERMISSION.COUNTERPARTIES_EDIT_ACCOUNT_HOLDERS)}>
                <AccountHolderDisableConfirmationModal
                    uuid={uuid}
                    isOpen={isDisableModalOpen}
                    onClose={onCloseDisableModal}
                />
            </If>
        </>
    )
}
