import { useAuth, usePermissions, useServices } from '@/hooks'
import {
    filterUserAccessAPIQueryParams,
    PaymentCounters,
    PERMISSION,
    WIDGET_TYPE,
    WidgetsServicePaymentCountersQueryOptions
} from '@/services'
import { APIError } from '@/types'
import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { List } from '@webapps/numeral-ui-core'

export function useQueryPaymentCounters(
    params: WidgetsServicePaymentCountersQueryOptions,
    options?: Partial<UndefinedInitialDataOptions<List<PaymentCounters>, APIError>>
) {
    const { widgetsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()
    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.HOME_VIEW_PAYMENT_COUNTERS) && options?.enabled

    const paramsWithUserAccess: typeof params = {
        ...params,
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<List<PaymentCounters>, APIError>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [widgetsService.url, paramsWithUserAccess, WIDGET_TYPE.PaymentCounters],
        queryFn({ signal }) {
            return widgetsService.paymentCounters(paramsWithUserAccess, { signal })
        }
    })
}
