import { WidgetProps, WidgetVariants } from '@/components'
import { useMemo } from 'react'
import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { If } from '@/components/@misc'
import { InfoIcon } from '@/components/@icons'
import { FormattedMessage } from 'react-intl'

export const WidgetNoDataAvailable: React.FC<WidgetProps> = ({ variant }) => {
    const hasLiteWidgetVariant = variant === WidgetVariants.Lite
    const propsFlex = useMemo<FlexProps>(() => {
        return {
            marginY: hasLiteWidgetVariant ? '0' : '25%',
            flexDirection: hasLiteWidgetVariant ? 'row' : 'column',
            alignItems: hasLiteWidgetVariant ? 'flex-start' : 'center',
            justifyContent: hasLiteWidgetVariant ? 'flex-start' : 'center'
        }
    }, [variant])

    return (
        <Flex {...propsFlex} gap="8px">
            <If condition={!hasLiteWidgetVariant}>
                <InfoIcon color="gray.400" width="32px" height="32px" />
            </If>
            <Text color="gray.400">
                <FormattedMessage id="app.common.no_data_available.title" />
            </Text>
        </Flex>
    )
}
