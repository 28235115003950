import { FormContainer, InputWithCopyToClipboard } from '@/components'
import { FormattedDateTime, ReadonlyField, StatusLabel } from '@/components/@misc'
import { Webhook } from '@/services'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { useMemo, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { areIndividualWebhookRulesEnabled, getWebhookRulesModeInitialValue } from '../../Webhooks.page.utils'

interface WebhookDetailsFormProps {
    data?: Webhook
}

export const WebhookDetailsForm: React.FC<WebhookDetailsFormProps> = ({ data }) => {
    const rulesMode = useMemo(() => getWebhookRulesModeInitialValue(data), [data])
    const hasIndividualWebhookRulesEnabled = useMemo(() => areIndividualWebhookRulesEnabled(rulesMode), [rulesMode])
    const propsInputCommon = useRef({
        readOnly: true,
        variant: 'flushed',
        focusBorderColor: 'none'
    }).current

    return (
        <FormContainer>
            <FormControl>
                <FormLabel htmlFor="id">
                    <FormattedMessage id="app.common.form.input.id.label" />
                </FormLabel>
                <InputWithCopyToClipboard id="id" name="id" defaultValue={data?.id} {...propsInputCommon} />
            </FormControl>
            <ReadonlyField name="created_at" value={<FormattedDateTime value={data?.created_at} />} />
            <ReadonlyField name="name" value={data?.name} />
            <ReadonlyField name="url" value={data?.url} />
            <ReadonlyField name="status" value={<StatusLabel value={data?.enabled ? 'enabled' : 'disabled'} />} />

            {/*<If condition={hasIndividualWebhookRulesEnabled}>
                <GroupAccordionReadonlyField<WebhookRules>
                    name="rules"
                    options={WEBHOOK_RULE_OPTIONS}
                    getGroupLabel={(value) => prettyPrintCasedWords(value)}
                    getGroupCounterLabel={(value: string, valuesSelected: number, valuesAvailable: number) =>
                        intl.formatMessage(
                            {
                                id: 'app.developers.webhooks.form.rules.group_number_of_rules.label',
                                defaultMessage: value
                            },
                            {
                                valuesSelected,
                                valuesAvailable
                            }
                        )
                    }
                    isReadOnly={true}
                    isDisabled={true}
                />
            </If>*/}
        </FormContainer>
    )
}
