import { If } from '@/components/@misc'
import { usePersistedLocationState, useQueryResolveIds, useQuickFilter, useToasts } from '@/hooks'
import {
    BulkActionsContext,
    getQueriesMatchingQuickFiltersPredicate,
    getResolvedIds,
    isBulkActionsActive,
    TOAST_VARIANTS,
    useBulkActionsAutoReset,
    wait
} from '@/providers'
import { useQueryClient } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { BulkActionsFooter } from './@components'
import {
    BULK_ACTIONS_ARTIFICIAL_WAIT_TIME,
    PERSISTED_STORE_BULK_ACTIONS_SELECTIONS_STATE
} from './BulkActionsProvider.const'
import { BulkActionsContextValue, BulkActionsProviderProps } from './BulkActionsProvider.types'

export function BulkActionsProvider<T, Q>({
    targetObject,
    filterBy,
    search,
    actions,
    customResolver,
    data,
    children
}: BulkActionsProviderProps<T, Q>) {
    const intl = useIntl()
    const queryClient = useQueryClient()
    const { onAdd } = useToasts()
    const { state } = useQuickFilter<Q>()
    const [isSelectAllQueryEnabled, setIsSelectAllQueryEnabled] = useState(false)

    const queryDefaultResolver = useQueryResolveIds<Q>(
        {
            target_object: targetObject,
            ...state,
            ...search?.parsedState,
            ...filterBy?.parsedState
        },
        {
            enabled: !customResolver && isSelectAllQueryEnabled
        }
    )

    const queryCustomResolver = customResolver?.(
        {
            ...state,
            ...search?.parsedState,
            ...filterBy?.parsedState
        },
        {
            enabled: isSelectAllQueryEnabled
        }
    )

    const resolvedIds = useMemo(() => {
        if (queryCustomResolver?.data) {
            return getResolvedIds(queryCustomResolver.data.items)
        }
        return getResolvedIds(queryDefaultResolver?.data?.items)
    }, [queryDefaultResolver, queryCustomResolver])

    const resolvedData = useMemo<Record<Uuid, Partial<T>>>(() => {
        const resolvedData: Record<Uuid, Partial<T>> = Object.create(null)
        queryCustomResolver?.data?.items?.forEach((item) => {
            if (item.id) {
                resolvedData[item.id] = item
            }
        })
        return resolvedData
    }, [queryDefaultResolver])

    const [persistence, setPersistence] = usePersistedLocationState<Set<Uuid>>(
        PERSISTED_STORE_BULK_ACTIONS_SELECTIONS_STATE,
        targetObject
    )

    const [selection, setSelection] = useState<Set<Uuid> | undefined>(persistence)
    const [isWaiting, setIsWaiting] = useState(false)
    const isLoading = globalThis.Boolean(isWaiting || queryDefaultResolver?.isLoading || queryCustomResolver?.isLoading)

    const onChangeSelection = useCallback((data?: Set<Uuid>) => {
        const newState = new Set<Uuid>(data)
        setSelection(newState)
        setPersistence(newState)
    }, [])
    const onResetSelection = useCallback(() => {
        setIsWaiting(true)

        wait(BULK_ACTIONS_ARTIFICIAL_WAIT_TIME).then(() => {
            const queryClientPredicate = getQueriesMatchingQuickFiltersPredicate(state)

            queryClient
                .invalidateQueries({ predicate: queryClientPredicate })
                .then(() => onChangeSelection(undefined))
                .finally(() => setIsWaiting(false))
        })
    }, [queryClient])

    const onSelect = useCallback((value: Uuid) => {
        setIsSelectAllQueryEnabled(false)
        setSelection((prevState) => {
            const newState = new Set<Uuid>(prevState)

            if (newState.has(value)) {
                newState.delete(value)
            } else {
                newState.add(value)
            }

            setPersistence(newState)

            return newState
        })
    }, [])
    const onSelectAll = useCallback(() => {
        setIsSelectAllQueryEnabled((prevState) => {
            if (prevState) {
                onChangeSelection(undefined)
            }
            return !prevState
        })
    }, [])
    const onChangeSelectAllQueryEnabled = useCallback((value: typeof isSelectAllQueryEnabled) => {
        setIsSelectAllQueryEnabled(value)
    }, [])
    const isActive = useMemo(() => {
        return isBulkActionsActive(actions, state, filterBy.parsedState)
    }, [actions, state, filterBy.parsedState])
    const value: BulkActionsContextValue<T> = {
        isQueryEnabled: isSelectAllQueryEnabled,
        isActive,
        isLoading,
        actions,
        data,
        resolvedIds,
        resolvedData,
        selection,
        onSelect,
        onSelectAll,
        onChangeSelection,
        onResetSelection
    }
    const showBulkActionsFooter = useMemo(() => {
        return data?.length > 0 && isActive
    }, [data, isActive])

    useBulkActionsAutoReset<Q>({
        search,
        filterBy,
        isActive,
        onChangeSelection,
        onChangeSelectAllQueryEnabled
    })

    useEffect(() => {
        if (!isSelectAllQueryEnabled) {
            return
        }

        const isQueryDefaultSuccessful = queryDefaultResolver?.isSuccess
        const isQueryCustomSuccessful = queryCustomResolver?.isSuccess
        const isSelectionSizeDifferentFromResolvedIdsSize = selection?.size !== resolvedIds?.size
        const shouldUpdateSelection =
            (isQueryDefaultSuccessful || isQueryCustomSuccessful) && isSelectionSizeDifferentFromResolvedIdsSize

        if (shouldUpdateSelection) {
            onChangeSelection(resolvedIds)
        }

        if (queryDefaultResolver?.isError) {
            setIsSelectAllQueryEnabled(false)

            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                isClosable: true,
                title: intl.formatMessage({ id: 'app.common.error.title' }),
                description: queryDefaultResolver.error?.message
            })
        }
    }, [isSelectAllQueryEnabled, queryDefaultResolver, queryCustomResolver, resolvedIds, intl])

    return (
        <BulkActionsContext.Provider value={value}>
            {children}
            <If condition={showBulkActionsFooter}>
                <BulkActionsFooter targetObject={targetObject} selection={selection} actions={actions} />
            </If>
        </BulkActionsContext.Provider>
    )
}
