import { Icon, IconProps } from '@chakra-ui/react'

export const HomeIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0251 9.04998V3.22498H20.7751V9.04998H13.0251ZM3.2251 12.9V3.22498H10.9751V12.9H3.2251ZM13.0251 20.775V11.1H20.7751V20.775H13.0251ZM3.2251 20.775V14.95H10.9751V20.775H3.2251ZM5.1001 11.025H9.1001V5.09998H5.1001V11.025ZM14.9001 18.9H18.9001V12.975H14.9001V18.9ZM14.9001 7.17498H18.9001V5.09998H14.9001V7.17498ZM5.1001 18.9H9.1001V16.825H5.1001V18.9Z"
        />
    </Icon>
)
