import {
    EntityDetailCellHolderAddress,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers
} from '@/components'
import { AccountHolderName, BankCode, FormattedDateTime, StatusLabel } from '@/components/@misc'
import { accountNumberFormatter, whitespaceFormatter } from '@/utils/@formatters'
import { ExternalAccount } from '@webapps/numeral-ui-core'
import {
    ExternalAccountCompanyRegistrationNumberType,
    ExternalAccountDetailsRelatedObjects,
    ExternalAccountType
} from './@components'
import { ExternalAccountCustomRows } from './ExternalAccountDetails.page.types'

export const EXTERNAL_ACCOUNT_DETAILS_ROWS: EntityDetailRow<ExternalAccount, ExternalAccountCustomRows>[] = [
    'id',
    'created_at',
    'name',
    'type',
    'account_number',
    'bank_code',
    'account_holder_id',
    'holder_name',
    'holder_address',
    'company_registration_number',
    'company_registration_number_type',
    'status',
    'disabled_at',
    'related_objects',
    'custom_fields',
    'metadata'
]

export const EXTERNAL_ACCOUNT_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    ExternalAccount,
    ExternalAccountCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    type: {
        cell(item) {
            return <ExternalAccountType accountType={item?.type} />
        }
    },
    account_number: {
        cell(item) {
            const formattedValue = accountNumberFormatter(item.account_number)
            return <EntityDetailCellTextWithCopyButton value={formattedValue} formatter={whitespaceFormatter} />
        }
    },
    bank_code: {
        cell(item) {
            return <BankCode value={item.bank_code} />
        }
    },
    account_holder_id: {
        cell(item) {
            return <AccountHolderName accountHolderId={item.account_holder_id} />
        }
    },
    holder_address: {
        cell(item) {
            return <EntityDetailCellHolderAddress value={item.holder_address} />
        }
    },
    company_registration_number_type: {
        cell(item) {
            return (
                <ExternalAccountCompanyRegistrationNumberType
                    companyRegistrationNumberType={item.company_registration_number_type}
                />
            )
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    disabled_at: {
        cell(item) {
            return <FormattedDateTime value={item.disabled_at} />
        }
    },
    related_objects: {
        cell(item) {
            return <ExternalAccountDetailsRelatedObjects externalAccount={item} />
        }
    },
    metadata: {
        nested: true
    }
}
