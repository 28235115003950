import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { UserInfo } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationUserInfo(options?: MutationOptions<UserInfo | void, Error, UserInfo>) {
    const { selectedUserAccess } = useAuth()
    const { userService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }
    return useMutation<UserInfo | void, Error, UserInfo>({
        ...options,
        mutationKey: [userService.url],
        mutationFn(data) {
            return userService.updateCurrentUserInfo(data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [userService.url]
                })
                .catch(noop)
        }
    })
}
