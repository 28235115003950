import { useAuth, usePermissions, useServices } from '@/hooks'
import {
    AggregateAllBalancesQueryOptions,
    BalanceExpanded,
    filterUserAccessAPIQueryParams,
    PERMISSION
} from '@/services'
import { BalanceGroup } from '@/services/Balances'
import { Group } from '@/types'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'

export function useQueryAggregateAllBalancesExpanded(
    params: AggregateAllBalancesQueryOptions,
    options?: Partial<UndefinedInitialDataOptions<Group<BalanceExpanded, BalanceGroup>, Error>>
) {
    const { balancesService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()
    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.ACCOUNTS_VIEW_BALANCES) && options?.enabled
    const paramsWithUserAccess: typeof params = {
        ...params,
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<Group<BalanceExpanded, BalanceGroup>, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [balancesService.url, paramsWithUserAccess],
        queryFn({ signal }) {
            return balancesService.aggregateAll({ ...paramsWithUserAccess }, { signal })
        }
    })
}
