import { PropsWithChildren, ReactNode } from 'react'

export enum WidgetVariants {
    Lite,
    Dense
}

export interface WidgetProps extends PropsWithChildren {
    title?: string | ReactNode
    variant?: WidgetVariants
    options?: ReactNode
    parent?: ReactNode
}
