import { QueryFallback, RestrictedSection, Table, useTableRowNavigation } from '@/components'
import { useQueryFindAllPaymentRetryRules } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE } from '@/services'
import { ApiObjectTypeSchema, PaymentRetryRule } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import {
    PAYMENT_RETRY_RULES_TABLE_COLUMN_CUSTOMIZERS,
    PAYMENT_RETRY_RULES_TABLE_STATIC_STATE
} from './PaymentRetryRules.page.const'

export const PaymentRetryRulesPage: React.FC = () => {
    const query = useQueryFindAllPaymentRetryRules()
    const data = useMemo(() => query.data?.records, [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const onTableRowClick = useTableRowNavigation<PaymentRetryRule>('id')

    return (
        <PageLayout className="PaymentRetryRules">
            <RestrictedSection feature={ENTITY_FEATURE.PAYMENT_RETRY_RULE}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.payment_retry_rule}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isDataEmpty}>
                    <Table<PaymentRetryRule>
                        data={data}
                        onRowClick={onTableRowClick}
                        columnCustomizers={PAYMENT_RETRY_RULES_TABLE_COLUMN_CUSTOMIZERS}
                        state={PAYMENT_RETRY_RULES_TABLE_STATIC_STATE}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
