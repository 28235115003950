import React, { useMemo } from 'react'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Text
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { GROUPED_ACCORDION_FIELD_STYLE_BOX } from './GroupAccordionInput.const'
import { If } from '@/components/@misc'
import {
    GROUP_ACCORDION_DEFAULT_WILDCARDS,
    GroupAccordionInputProps,
    GroupAccordionSupportedInputType
} from './GroupAccordionInput.types'
import {
    computeGroupInputStats,
    extractGroupOptions,
    isItemChecked,
    normalizeGroupOptions
} from './GroupAccordionInput.utils'
import { useGroupAccordionInputArray, useGroupAccordionInputRecord, useGroupAccordionLabelFormatters } from './@hooks'

export const GroupAccordionInput = <T extends GroupAccordionSupportedInputType>(props: GroupAccordionInputProps<T>) => {
    const {
        name,
        options,
        isDisabled,
        groupOrder,
        wildcardSymbol,
        getGroupLabel,
        getGroupOptionLabel,
        getGroupCounterLabel,
        optionsFilter
    } = props
    const normalizedGroupOptions = useMemo(
        () => normalizeGroupOptions(options, optionsFilter),
        [options, optionsFilter]
    )
    const hasArrayShape = useMemo(() => globalThis.Array.isArray(options), [options])
    const computedWildcardSymbol = useMemo(() => {
        return wildcardSymbol || hasArrayShape
            ? GROUP_ACCORDION_DEFAULT_WILDCARDS.ARRAY_WILDCARD
            : GROUP_ACCORDION_DEFAULT_WILDCARDS.RECORD_WILDCARD
    }, [wildcardSymbol, hasArrayShape])

    const inputsProps = { normalizedGroupOptions, ...props }
    const { field, meta, handleCheckboxChange, handleGroupCheckboxChange } = hasArrayShape
        ? useGroupAccordionInputArray<T>(inputsProps)
        : useGroupAccordionInputRecord<T>(inputsProps)
    const { getFormattedGroupLabel, getFormattedGroupCounterLabel, getFormattedGroupOptionLabel } =
        useGroupAccordionLabelFormatters({ hasArrayShape, getGroupLabel, getGroupCounterLabel, getGroupOptionLabel })

    const availableGroups = useMemo(() => extractGroupOptions(options, groupOrder), [options, groupOrder])
    const groupStats = useMemo(() => {
        return computeGroupInputStats(
            field.value,
            availableGroups,
            normalizedGroupOptions,
            computedWildcardSymbol,
            isItemChecked
        )
    }, [availableGroups, normalizedGroupOptions, field, computedWildcardSymbol])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl isInvalid={isInvalid}>
            <FormLabel>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Box {...GROUPED_ACCORDION_FIELD_STYLE_BOX}>
                <Accordion allowMultiple={true}>
                    {availableGroups.map((group, index) => {
                        const groupItems = normalizedGroupOptions[group] || []
                        const totalGroupCount = groupStats[group]?.total
                        const selectedGroupCount = groupStats[group]?.selected
                        const isGroupIndeterminate = selectedGroupCount > 0 && selectedGroupCount < totalGroupCount
                        const isGroupChecked = selectedGroupCount === totalGroupCount
                        const isFirstItem = index === 0
                        const propsAccordionItem = {
                            [isFirstItem ? 'borderTop' : 'borderBottom']: 'unset'
                        }
                        const groupLabel = getFormattedGroupLabel(group, selectedGroupCount, totalGroupCount)

                        return (
                            <AccordionItem {...propsAccordionItem} key={group}>
                                <AccordionButton display="flex" padding="16px" justifyContent="space-between">
                                    <Checkbox
                                        isChecked={isGroupChecked}
                                        isDisabled={isDisabled}
                                        isIndeterminate={isGroupIndeterminate}
                                        onChange={() => handleGroupCheckboxChange(group)}
                                        isInvalid={isInvalid}
                                        size="lg"
                                        onClick={(event) => event.stopPropagation()}>
                                        <Text
                                            marginLeft="8px"
                                            color="gray.800"
                                            fontWeight="semibold"
                                            pointerEvents="none"
                                            aria-label={groupLabel}
                                            title={groupLabel}>
                                            {groupLabel}
                                        </Text>
                                    </Checkbox>
                                    <Flex>
                                        <Text color="gray.500" marginX="8px" fontSize="14px">
                                            {getFormattedGroupCounterLabel(group, selectedGroupCount, totalGroupCount)}
                                        </Text>
                                        <AccordionIcon color="gray.500" />
                                    </Flex>
                                </AccordionButton>
                                <AccordionPanel
                                    display="flex"
                                    gap="16px"
                                    flexDirection="column"
                                    marginLeft="16px"
                                    color="gray.800">
                                    {groupItems.map((item, index) => {
                                        const isChecked = isItemChecked(
                                            group,
                                            item,
                                            field.value,
                                            computedWildcardSymbol
                                        )
                                        const groupOptionLabel = getFormattedGroupOptionLabel(
                                            group,
                                            item,
                                            selectedGroupCount,
                                            totalGroupCount
                                        )

                                        return (
                                            <Checkbox
                                                key={index}
                                                isChecked={isChecked}
                                                isDisabled={isDisabled}
                                                onChange={() => handleCheckboxChange(group, item)}
                                                isInvalid={isInvalid}
                                                aria-label={groupOptionLabel}
                                                title={groupOptionLabel}>
                                                {groupOptionLabel}
                                            </Checkbox>
                                        )
                                    })}
                                </AccordionPanel>
                            </AccordionItem>
                        )
                    })}
                </Accordion>
            </Box>
            <If condition={!!meta.error}>
                <Text color="red.500">{meta.error}</Text>
            </If>
        </FormControl>
    )
}
