import { EntityDetail, EntityDetailRowCustomizers, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdDirectDebitMandate } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ApiObjectTypeSchema, DirectDebitMandate } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { DIRECt_DEBIT_MANDATE_COUNTERPARTIES_ROWS } from './DirectDebitMandateCounterparties.page.const'
import { getDirectDebitMandateCounterpartiesRowCustomizers } from './DirectDebitMandateCounterparties.page.utils'

export const DirectDebitMandateCounterpartiesPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdDirectDebitMandate(uuid)

    const rowCustomizers = useMemo<EntityDetailRowCustomizers<DirectDebitMandate>>(() => {
        return getDirectDebitMandateCounterpartiesRowCustomizers(query.data)
    }, [query])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.DIRECT_DEBIT_MANDATE}
                permission={PERMISSION.COUNTERPARTIES_VIEW_DIRECT_DEBIT_MANDATES}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.direct_debit_mandate}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<DirectDebitMandate>
                        data={query.data}
                        rows={DIRECt_DEBIT_MANDATE_COUNTERPARTIES_ROWS}
                        rowCustomizers={rowCustomizers}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
