import { debounce } from 'lodash'
import { FocusEvent, useCallback, useRef, useState } from 'react'
import { InputActionMeta } from 'react-select'
import { SELECT_DEFAULT_PAGE_SIZE } from '../Select.const'
import { defaultRemoteQuerySelectFilterOption } from '@/components/@misc'

const SEARCH_QUERY_DEBOUNCE_TIME = 250
const SEARCH_QUERY_PAGE_SIZE = SELECT_DEFAULT_PAGE_SIZE || 20
const SEARCH_QUERY_DEBOUNCE_OPTIONS = { trailing: true }

/**
 * @description
 * 1. Expose `react-query` specific flags (`enabled`);
 * 2. Expose query specific pagination parameters, `search`, `limit`;
 * 3. Expose `react-select` props:
 * - `inputText`
 * - `onInputChange`
 * - `onFocus`
 */
export function useSearchQuerySelect(isEnabled = false) {
    const [search, setSearch] = useState('')
    const [inputText, setInputText] = useState('')
    const [enabled, setEnabled] = useState(isEnabled)

    const setSearchTextDebounced = useRef(
        debounce((search) => setSearch(search), SEARCH_QUERY_DEBOUNCE_TIME, SEARCH_QUERY_DEBOUNCE_OPTIONS)
    ).current
    const onInputChange = useCallback((newValue: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action !== 'input-blur' && actionMeta.action !== 'menu-close') {
            setInputText(newValue)
            setSearchTextDebounced(newValue)
        }
    }, [])
    const onFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
        const hasSearchElementFocused = event.currentTarget === globalThis.document.activeElement
        setEnabled(hasSearchElementFocused)
    }, [])

    return {
        // ReactQuery interface
        enabled,
        // `SelectProps` interface with {inputText} and {onInputChange}
        inputText,
        onInputChange,
        onFocus,
        filterOption: defaultRemoteQuerySelectFilterOption,
        // `Pagination` interface with {search} and {limit}
        search,

        limit: SEARCH_QUERY_PAGE_SIZE
    }
}
