import { isSDDPayment } from '@/services'
import {
    IncomingPayment,
    IncomingPaymentSCTReturnReasonSchema,
    IncomingPaymentSDDReturnReasonSchema
} from '@webapps/numeral-ui-core'

export function getIncomingPaymentReturnReasonOptions(imcomingPayment?: IncomingPayment) {
    if (isSDDPayment(imcomingPayment?.type, imcomingPayment?.direction)) {
        return IncomingPaymentSDDReturnReasonSchema.options
    } else {
        return IncomingPaymentSCTReturnReasonSchema.options
    }
}
