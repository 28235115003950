import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { addTransformRequestsToHttpClientInstance, markedFieldsTransform } from '@/services/@transformRequests'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { Return, ReturnCreateData } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationCreateReturn(options?: MutationOptions<Partial<Return> | void, Error, ReturnCreateData>) {
    const { selectedUserAccess } = useAuth()
    const { returnsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<Return | void, Error, ReturnCreateData>({
        ...options,
        mutationKey: [returnsService.url],
        mutationFn(data) {
            return returnsService.createReturn(data, {
                params: paramsWithUserAccess,
                transformRequest: addTransformRequestsToHttpClientInstance(returnsService, markedFieldsTransform)
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [returnsService.url]
                })
                .catch(noop)
        }
    })
}
