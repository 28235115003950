import {
    API_QUERY_PARAMS_RESERVED_NAMES,
    APIQueryParamStateFilterBy,
    APIQueryParamStateMap,
    APIQueryParamTypes,
    ExtractQueryType,
    UseAPIQueryParamsResult
} from '@/hooks'
import { Nullable } from '@/types'
import { chain, every, isEqual, omit } from 'lodash'
import { TableHeaderForm } from './TableHeader.types'

export function pickFilterByValues<T>(values?: TableHeaderForm<T>) {
    const reservedNamespaces = chain(API_QUERY_PARAMS_RESERVED_NAMES).values().value()
    return omit(values, ...reservedNamespaces) as APIQueryParamStateFilterBy<T>
}

export function pickAPIQueryParamValues<Q extends APIQueryParamTypes, T = void>(
    values?: TableHeaderForm<T>,
    APIQueryParamsResult?: UseAPIQueryParamsResult<Q, T>
): ExtractQueryType<Q, APIQueryParamStateMap<T>> {
    const paramKey = APIQueryParamsResult?.configuration?.APIQueryParamKey
    const result = Object.create(null)

    if (paramKey) {
        result[paramKey] = chain<Record<string, any>>(values).get(paramKey).value()
        return result
    }

    return result
}

/**
 * @todo
 * Move to global utils if reused more than once (don't forget to move its test suite also).
 */
export function isRecordIncluded(
    smallerRecord?: Nullable<Record<any, any>>,
    largerRecord?: Nullable<Record<any, any>>
): boolean {
    if (!smallerRecord || !largerRecord) {
        return false
    }

    return every(smallerRecord, (value, key) => isEqual(largerRecord[key], value))
}
