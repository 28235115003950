import { SelectOption } from '@/components/@misc/Select/@components'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Box, Flex, Text } from '@chakra-ui/react'
import { get } from 'lodash'
import { memo } from 'react'
import { OptionProps } from 'react-select'

export const InternalAccountInputSelectOption: React.FC<OptionProps> = memo(({ ...optionProps }) => {
    const accountNumber = get(optionProps, 'data.original.account_number', EMPTY_VALUE_PLACEHOLDER)

    return (
        <SelectOption {...optionProps}>
            <Flex alignItems="center" justifyContent="space-between" paddingX="8px">
                <Box>
                    <Text color="gray.700" noOfLines={1}>
                        {optionProps.children}
                    </Text>
                    <Text color="gray.400" fontSize="small" noOfLines={1}>
                        {accountNumber}
                    </Text>
                </Box>
            </Flex>
        </SelectOption>
    )
})
