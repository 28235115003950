import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { NumeralFile, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdFile(id?: Uuid, options?: Partial<UndefinedInitialDataOptions<NumeralFile, Error>>) {
    const { filesService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.FILES_VIEW_FILES) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<NumeralFile, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [filesService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return filesService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
