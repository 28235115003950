import { PaymentOrderReturnItem, WidgetSeriesReservedLabels } from '@/services'
import { CHART_BASE_COLORS, generateChartDatasetColorByIndex } from '@/components/@charts'
import { IntlShape } from 'react-intl'

export function paymentOrderReturnsDatasetMapperWithIntl(intl: IntlShape) {
    return function paymentOrderReturnsDatasetMapper(record: PaymentOrderReturnItem, index: number) {
        const backgroundColor = (() => {
            switch (record.label) {
                case WidgetSeriesReservedLabels.Other: {
                    return CHART_BASE_COLORS.NUMERAL_GRAY
                }

                case WidgetSeriesReservedLabels.Empty: {
                    return CHART_BASE_COLORS.NUMERAL_LIGHT_GRAY
                }
                default: {
                    return generateChartDatasetColorByIndex(index)
                }
            }
        })()
        const label = (() => {
            switch (record.label) {
                case WidgetSeriesReservedLabels.Other: {
                    return intl.formatMessage({
                        id: 'app.common.other'
                    })
                }

                case WidgetSeriesReservedLabels.Empty: {
                    return intl.formatMessage({
                        id: 'app.widgets.payment_order_returns.dataset.no_reason'
                    })
                }

                default: {
                    return intl.formatMessage({
                        id: `api.sepa_return_reason.${record.label}`,
                        defaultMessage: record.label
                    })
                }
            }
        })()

        return {
            label,
            backgroundColor,
            data: [record.rate]
        }
    }
}
