import { LinkWithFilterBy, LinkWithFiltersProps } from '@/components/@misc'
import { useNavigationRoutes } from '@/hooks'
import { Flex } from '@chakra-ui/react'
import {
    BalancesServiceFindAllQueryOptions,
    TransactionsServiceFindAllQueryOptions,
    Uuid
} from '@webapps/numeral-ui-core'
import { useMemo } from 'react'

interface ConnectedAccountDetailsRelatedObjectsProps {
    connectedAccountId: Uuid
}

export const ConnectedAccountDetailsRelatedObjects: React.FC<ConnectedAccountDetailsRelatedObjectsProps> = ({
    connectedAccountId
}) => {
    const { paths } = useNavigationRoutes()
    const relatedTransactionLinkProps = useMemo<LinkWithFiltersProps<TransactionsServiceFindAllQueryOptions>>(() => {
        return {
            labelKey: 'app.settings.connected_accounts.details.related_transactions.label',
            path: paths.ACCOUNTS.TRANSACTIONS,
            filterBy: {
                connected_account_id: connectedAccountId
            }
        }
    }, [connectedAccountId])

    const relatedBalanceLinkProps = useMemo<LinkWithFiltersProps<BalancesServiceFindAllQueryOptions>>(() => {
        return {
            labelKey: 'app.settings.connected_accounts.details.related_balances.label',
            path: paths.ACCOUNTS.BALANCES,
            filterBy: {
                connected_account_id: connectedAccountId
            }
        }
    }, [connectedAccountId])

    return (
        <Flex direction="column" gap="8px">
            <LinkWithFilterBy<TransactionsServiceFindAllQueryOptions> {...relatedTransactionLinkProps} />
            <LinkWithFilterBy<BalancesServiceFindAllQueryOptions> {...relatedBalanceLinkProps} />
        </Flex>
    )
}
