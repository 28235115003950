import { QueryFallback, RestrictedSection } from '@/components'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, isCreateReconciliationAvailable, PERMISSION } from '@/services'
import { ApiObjectTypeSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { ReconcileEntityHeader, ReconcileTransactionForm, ReconcileTransactionFormData } from './@components'
import { useReconciliation } from './@hooks'
import { ReconcileEntityPageProps } from './ReconcileEntity.page.types'

export const ReconcileEntityPage: React.FC<ReconcileEntityPageProps> = ({ queryItem }) => {
    const { onStartReconciliation } = useReconciliation()
    const isDisabled = useMemo<boolean>(() => {
        const canCreateReconciliation = isCreateReconciliationAvailable(queryItem.data?.reconciliation_status)
        return !canCreateReconciliation
    }, [queryItem])
    const onSubmit = useCallback(
        (data: ReconcileTransactionFormData) => {
            if (!data.payment_type) {
                return
            }

            onStartReconciliation(data.payment_type, queryItem?.data)
        },
        [queryItem]
    )

    return (
        <PageLayout className="ReconcileEntity">
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS}>
                <ReconcileEntityHeader queryItem={queryItem} />
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.reconciliation}
                    isLoading={queryItem.isLoading}
                    isSuccess={queryItem.isSuccess}
                    isError={queryItem.isError || isEmpty(queryItem?.data)}
                    isDataEmpty={isEmpty(queryItem?.data)}>
                    <ReconcileTransactionForm
                        data={queryItem?.data}
                        onSubmit={onSubmit}
                        loading={queryItem.isLoading}
                        disabled={isDisabled}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
