import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { usePermissions } from '@/hooks'
import { PERMISSION } from '@/services'
import { Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { ConnectedAccountName } from '../ConnectedAccountName'
import { If } from '../If'
import { RestrictedData } from '../RestrictedData'
import { ShowMore } from '../ShowMore'
import { NUMBER_OF_VISIBLE_ACCOUNT_NAMES_BEFORE_EXPAND } from './ConnectedAccountsName.const'

interface ConnectedAccountsNameProps {
    connectedAccountIds?: string[]
    asPlainText?: boolean
}

export const ConnectedAccountsName: React.FC<ConnectedAccountsNameProps> = React.memo(
    ({ connectedAccountIds, asPlainText }) => {
        const { hasPermission } = usePermissions()
        const [isExpanded, setIsExpanded] = useState(false)

        const onClickShowMoreHandler = () => {
            setIsExpanded((prevState) => !prevState)
        }

        switch (true) {
            case !connectedAccountIds?.length: {
                return <>{EMPTY_VALUE_PLACEHOLDER}</>
            }

            case !hasPermission(PERMISSION.SETTINGS_VIEW_CONNECTED_ACCOUNTS): {
                return <RestrictedData />
            }

            default: {
                const hasExpandableSection = connectedAccountIds?.length > NUMBER_OF_VISIBLE_ACCOUNT_NAMES_BEFORE_EXPAND

                return (
                    <Flex direction="column" gap="8px">
                        {connectedAccountIds.slice(0, NUMBER_OF_VISIBLE_ACCOUNT_NAMES_BEFORE_EXPAND).map((id) => {
                            return <ConnectedAccountName key={id} connectedAccountId={id} asPlainText={asPlainText} />
                        })}
                        <If condition={hasExpandableSection}>
                            <If condition={isExpanded}>
                                {connectedAccountIds
                                    .slice(NUMBER_OF_VISIBLE_ACCOUNT_NAMES_BEFORE_EXPAND, connectedAccountIds.length)
                                    .map((id) => {
                                        return (
                                            <ConnectedAccountName
                                                key={id}
                                                connectedAccountId={id}
                                                asPlainText={asPlainText}
                                            />
                                        )
                                    })}
                            </If>
                            <ShowMore isExpanded={isExpanded} onClick={onClickShowMoreHandler} />
                        </If>
                    </Flex>
                )
            }
        }
    }
)
