import { chain } from 'lodash'
import { combineFilters } from '@/utils'
import {
    navigationProviderDisabledRoutesFilter,
    navigationProviderHiddenRoutesFilter,
    navigationProviderIndexRoutesFilter,
    NavigationRoute
} from '@/providers'

export const defaultMainNavigationRoutesFilter = combineFilters<NavigationRoute>(
    navigationProviderIndexRoutesFilter,
    navigationProviderDisabledRoutesFilter,
    navigationProviderHiddenRoutesFilter
)
export const MAIN_NAVIGATION_ACCOUNT_DROPDOWN_WIDTH = 256
export const MAIN_NAVIGATION_ACCOUNT_DROPDOWN_MENU_DIMENSION = {
    WIDTH: chain<number>(MAIN_NAVIGATION_ACCOUNT_DROPDOWN_WIDTH).subtract(16).value().toString().concat('px'),
    MAX_HEIGHT: `${MAIN_NAVIGATION_ACCOUNT_DROPDOWN_WIDTH}px`
}
