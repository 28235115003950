import { getChartTooltipLabelFormatterForAxis } from '@/components/@charts'
import { type ChartOptions, ChartTypeRegistry, TooltipItem } from 'chart.js'
import { percentageFormatter } from '@/utils/@formatters'

export const PAYMENT_ORDER_RETURNS_WIDGET_CHART_OPTIONS: ChartOptions<'bar'> = {
    maintainAspectRatio: false,
    scales: {
        x: {
            ticks: {
                callback(value) {
                    return percentageFormatter(value)
                }
            }
        }
    },
    plugins: {
        tooltip: {
            callbacks: {
                label(context: TooltipItem<keyof ChartTypeRegistry>) {
                    const axisLabelFormatter = getChartTooltipLabelFormatterForAxis('x', percentageFormatter)

                    return axisLabelFormatter(context)
                }
            }
        },
        datalabels: {
            formatter(value) {
                return percentageFormatter(value, true)
            }
        }
    }
}
