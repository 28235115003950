import { useMemo, useRef } from 'react'
import { getPrivateTopLevelRoutes, NavigationRoute } from '../providers'
import { useNavigationRoutes } from './context/useNavigationRoutes'
import { useLocation } from 'react-router-dom'
import { chain } from 'lodash'

interface UsePrivateTopLevelRoutesHook {
    routes: NavigationRoute[]
    currentRoute?: NavigationRoute
}

const getCurrentRootRoute = (currentPathName: string) => {
    return chain(currentPathName).split('/', 2).last().defaultTo(currentPathName).value()
}
const flattenRoutes = (route: NavigationRoute[]): NavigationRoute[] => {
    return route.flatMap((item) => (item.routes ? [item, ...flattenRoutes(item.routes)] : item))
}

export const usePrivateTopLevelRoutes = (): UsePrivateTopLevelRoutesHook => {
    const location = useLocation()
    const { routes } = useNavigationRoutes()
    const privateTopLevelRoutes = useRef<NavigationRoute[]>(getPrivateTopLevelRoutes(routes)).current
    const currentPrivateTopLevel = useMemo<NavigationRoute | undefined>(() => {
        const currentRoot = getCurrentRootRoute(location.pathname)
        return privateTopLevelRoutes.find((route) => route?.path?.includes(currentRoot))
    }, [location, privateTopLevelRoutes])

    return {
        routes: privateTopLevelRoutes,
        currentRoute: currentPrivateTopLevel
    }
}
