import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams, Webhook } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationUpdateByIdWebhook(
    id?: Uuid,
    options?: MutationOptions<Partial<Webhook> | void, Error, Partial<Webhook>>
) {
    const { selectedUserAccess } = useAuth()
    const { webhooksService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = { ...filterUserAccessAPIQueryParams(selectedUserAccess) }

    return useMutation<Partial<Webhook> | void, Error, Partial<Webhook>>({
        ...options,
        mutationKey: [webhooksService.url, { id, ...paramsWithUserAccess }],
        mutationFn(data) {
            return webhooksService.updateById(id as string, data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            queryClient.setQueryData([webhooksService.url, { id }], args[0])

            await queryClient
                .invalidateQueries({
                    queryKey: [webhooksService.url]
                })
                .catch(noop)
        }
    })
}
