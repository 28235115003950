import { useAuth } from '@/hooks'
import { UserAccess } from '@/services'
import { withCalleeName } from '@/utils'
import { Box } from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { find, isNil } from 'lodash'
import { JSXElementConstructor, PropsWithoutRef } from 'react'
import { NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS } from '../NavigationRoutesProvider'

export function isFindAllUserAccessesQueryEnabled(
    userAccess: UserAccess | undefined,
    isUserLoggingOut: boolean,
    currentPath: string
) {
    return (
        isNil(userAccess) &&
        !isUserLoggingOut &&
        !currentPath.includes(NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.CALLBACK) &&
        !currentPath.includes(NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.INITIAL_CALLBACK) &&
        !currentPath.includes(NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.EXTRA_LOGOUT) &&
        !currentPath.includes(NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.ACCOUNT.LOGIN) &&
        !currentPath.includes(NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.ACCOUNT.ERROR)
    )
}

export function getUserAccessByLegalEntityID(userAcesses: UserAccess[], legalEntityID?: Uuid): UserAccess | undefined {
    if (!userAcesses || !legalEntityID) {
        return
    }

    return find(userAcesses, ({ legal_entity_id }) => legal_entity_id === legalEntityID)
}

export function withUserAccess(Component: JSXElementConstructor<any>) {
    const name = `${withUserAccess?.name}(${Component?.name})`

    return withCalleeName(name, (props?: PropsWithoutRef<any>) => {
        const { selectedUserAccess } = useAuth()

        return (
            <Box hidden={!selectedUserAccess?.id}>
                <Component {...props} />
            </Box>
        )
    })
}

export function hasUserMatchingRole(role: string, roles: string[]): boolean {
    return roles?.includes(role)
}
