import { useBulkActions, useQuickFilter } from '@/hooks'
import { Box, Flex, Skeleton } from '@chakra-ui/react'
import { HeaderContext } from '@tanstack/react-table'
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import {
    BULK_ACTIONS_TABLE_CELL_CONTAINER_STYLE,
    BULK_ACTIONS_TABLE_CHECKBOX_DIMENSION
} from './BulkActionsTable.const'
import { isBulkActionsTableHeaderDisabled } from './BulkActionsTable.utils'

function BulkActionsTableSelectHeader<T>({ header }: HeaderContext<T, any>) {
    const { actions, onSelectAll, isLoading, selection, resolvedIds, isQueryEnabled } = useBulkActions()
    const { state } = useQuickFilter()
    const elementRef = useRef<HTMLInputElement>(null)
    const isChecked = useMemo(() => {
        return isQueryEnabled || (resolvedIds && resolvedIds?.size > 0 && selection?.size === resolvedIds?.size)
    }, [isQueryEnabled, resolvedIds, selection?.size])
    const isIndeterminate = useMemo(() => {
        if (!selection) {
            return false
        }

        return selection?.size > 0 && selection?.size !== resolvedIds?.size
    }, [resolvedIds, selection])
    const hasBulkActionsTableHeaderDisabled = useMemo(() => {
        return isBulkActionsTableHeaderDisabled(actions, state)
    }, [actions, state])
    const onClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation()
            onSelectAll()
        },
        [onSelectAll]
    )

    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.indeterminate = isIndeterminate
        }
    }, [elementRef, isIndeterminate])

    if (hasBulkActionsTableHeaderDisabled) {
        return <Box marginLeft="13px" />
    }

    if (isLoading) {
        return <Skeleton width={BULK_ACTIONS_TABLE_CHECKBOX_DIMENSION} height={BULK_ACTIONS_TABLE_CHECKBOX_DIMENSION} />
    }

    return (
        <Flex {...BULK_ACTIONS_TABLE_CELL_CONTAINER_STYLE} onClick={onClick}>
            <input type="checkbox" checked={isChecked} ref={elementRef} readOnly={true} style={{ cursor: 'inherit' }} />
        </Flex>
    )
}

export const BulkActionsTableSelectHeaderMemoized = memo(BulkActionsTableSelectHeader)
