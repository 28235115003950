import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { NumeralFile, Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationCancelByIdFile(id?: Uuid, options?: MutationOptions<Partial<NumeralFile> | void, Error>) {
    const { selectedUserAccess } = useAuth()
    const { filesService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<NumeralFile | void, Error>({
        mutationKey: [filesService.url, { id }],
        mutationFn(data) {
            return filesService.cancelFile(id as string, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            queryClient.setQueryData([filesService.url, { id }], args[0])

            await queryClient
                .invalidateQueries({
                    queryKey: [filesService.url]
                })
                .catch(noop)
        }
    })
}
