import { DASH_SEPARATOR } from '@/constants'
import { APIError } from '@/types'
import { Text } from '@chakra-ui/react'
import { ExternalAccount } from '@webapps/numeral-ui-core'
import { AxiosError } from 'axios'
import { get } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

export const ExternalAccountCreateErrorNotification: React.FC<{
    error: AxiosError<APIError<ExternalAccount>>
}> = ({ error }) => {
    const errorDetails = useMemo(() => get(error, 'response.data.details', []), [error])

    return (
        <>
            <Text>
                <FormattedMessage id="app.counterparties.external_accounts.create.form.validation.create.error.description" />
            </Text>
            {errorDetails.map((detail, index: number) => {
                return (
                    <Text whiteSpace="normal" key={index}>
                        {`${DASH_SEPARATOR} ${detail?.field}: ${detail?.reason}`}
                    </Text>
                )
            })}
        </>
    )
}
