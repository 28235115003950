import { EntityAction } from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdInternalAccount } from '@/hooks'
import { PageHeaderLayout, PageHeaderLayoutProps } from '@/layouts'
import { enabledInternalAccountFilter, PERMISSION } from '@/services'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { InternalAccountDisableConfirmationModal } from './@components'

export const InternalAccountDetailsHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdInternalAccount(uuid)
    const [isDisableModalOpen, setIsDisableModalOpen] = useState(false)

    const onCloseDisableModal = useCallback(() => {
        setIsDisableModalOpen(false)
    }, [])

    const actions = useMemo<EntityAction[]>(() => {
        const actions = []

        const isDisableAvailable =
            hasPermission(PERMISSION.COUNTERPARTIES_EDIT_EXTERNAL_ACCOUNTS) && enabledInternalAccountFilter(query.data)

        if (isDisableAvailable) {
            actions.push({
                onClick() {
                    setIsDisableModalOpen(true)
                },
                color: 'red.500',
                isDisabled: query.isLoading,
                children: intl.formatMessage({
                    id: 'app.common.actions.disable.label'
                })
            })
        }

        return actions
    }, [intl, query])

    const title = useMemo<ReactNode>(() => {
        const notFoundTitle = intl.formatMessage({
            id: 'app.common.not_found.title'
        })
        return (query.isError && notFoundTitle) || query.data?.name || uuid
    }, [intl, query, uuid])
    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        return {
            title,
            actions,
            isLoading: query.isLoading,
            disabled: query.isError
        }
    }, [title, query, actions])

    if (!hasPermission(PERMISSION.COUNTERPARTIES_VIEW_INTERNAL_ACCOUNTS)) {
        return null
    }

    return (
        <>
            <PageHeaderLayout {...propsPageHeaderLayout} />
            <If condition={hasPermission(PERMISSION.COUNTERPARTIES_EDIT_EXTERNAL_ACCOUNTS)}>
                <InternalAccountDisableConfirmationModal
                    uuid={uuid}
                    isOpen={isDisableModalOpen}
                    onClose={onCloseDisableModal}
                />
            </If>
        </>
    )
}
