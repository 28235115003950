import { BoxProps, Collapse, CollapseProps } from '@chakra-ui/react'

export const TABLE_HEADER_AUTO_SUBMIT_DEBOUNCE_TIME = 200
export const TABLE_HEADER_FILTER_INPUT_WIDTH = 250

export const TABLE_HEADER_COLLAPSABLE_PANEL_PROPS: BoxProps & CollapseProps = {
    as: Collapse,
    animateOpacity: false,
    unmountOnExit: false,
    width: '100%'
}
