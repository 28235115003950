import { Icon, IconProps } from '@chakra-ui/react'

export const FilesIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.1126 17.8H15.9001V15.925H8.1126V17.8ZM8.1126 13.85H15.9001V11.975H8.1126V13.85ZM6.1001 21.75C5.58443 21.75 5.14301 21.5664 4.77585 21.1992C4.40868 20.8321 4.2251 20.3907 4.2251 19.875V4.125C4.2251 3.60933 4.40868 3.16792 4.77585 2.80075C5.14301 2.43358 5.58443 2.25 6.1001 2.25H14.0001L19.7751 8.0125V19.875C19.7751 20.3907 19.5915 20.8321 19.2243 21.1992C18.8572 21.5664 18.4158 21.75 17.9001 21.75H6.1001ZM13.0751 8.95V4.125H6.1001V19.875H17.9001V8.95H13.0751Z"
        />
    </Icon>
)
