import { useFormikAutoSubmit } from '@/hooks'

/**
 * @description
 * - This component auto submits the form when an input changes, only if the form is valid;
 * - Simply render this component inside a formik context;
 */
export const FormAutoSubmit: React.FC<{ wait?: number }> = ({ wait }) => {
    useFormikAutoSubmit(wait)
    return null
}
