import {
    EntityDetailCellHolderAddress,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRowCustomizers
} from '@/components'
import { BankCode, DetailPageLink } from '@/components/@misc'
import { accountNumberFormatter, whitespaceFormatter } from '@/utils/@formatters'
import { Account, ApiObjectType, UltimateOriginator, Uuid } from '@webapps/numeral-ui-core'
import { CounterpartyOrganizationIdentification } from './@components'
import { CounterpartyAccountCommonCustomRows } from './Counterparties.page.types'

export function getCounterpartyAccountCommonRowCustomizers(
    accountType: Extract<ApiObjectType, 'connected_account' | 'internal_account' | 'external_account'>,
    relatedAccountId?: Uuid
): EntityDetailRowCustomizers<Account, CounterpartyAccountCommonCustomRows> {
    return {
        related_objects: {
            cell() {
                return <DetailPageLink objectType={accountType} objectId={relatedAccountId} />
            }
        },
        account_number: {
            cell(account) {
                const formattedValue = accountNumberFormatter(account?.account_number)
                return <EntityDetailCellTextWithCopyButton value={formattedValue} formatter={whitespaceFormatter} />
            }
        },
        bank_code: {
            cell(account) {
                return <BankCode value={account?.bank_code} />
            }
        },
        holder_address: {
            cell(account) {
                return <EntityDetailCellHolderAddress value={account?.holder_address} />
            }
        }
    }
}

export function getCounterpartyUltimateOriginatorCommonRowCustomizers(): EntityDetailRowCustomizers<UltimateOriginator> {
    return {
        organization_identification: {
            cell(externalAccount) {
                return (
                    <CounterpartyOrganizationIdentification
                        organizationIdentification={externalAccount.organization_identification}
                    />
                )
            }
        },
        holder_address: {
            cell(counterpartyAccount) {
                return <EntityDetailCellHolderAddress value={counterpartyAccount.holder_address} />
            }
        }
    }
}
