import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdTransaction } from '@/hooks'
import { PageLayout } from '@/layouts'
import { getCustomFieldsRowCustomizers } from '@/pages'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ApiObjectTypeSchema, Transaction } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { TransactionReconcileFooter } from './@components'
import { TRANSACTION_DETAILS_ROW_CUSTOMIZERS, TRANSACTION_DETAILS_ROWS } from './TransactionDetails.page.const'
import { TransactionDetailsCustomRows } from './TransactionDetails.types'

export const TransactionDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdTransaction(uuid)
    const isDataEmpty = useMemo(() => {
        return isEmpty(query?.data)
    }, [query.data])

    const rowCustomizers = useMemo(() => {
        return {
            ...TRANSACTION_DETAILS_ROW_CUSTOMIZERS,
            custom_fields: getCustomFieldsRowCustomizers(query.data, PERMISSION.ACCOUNTS_EDIT_TRANSACTIONS)
        }
    }, [query.data])

    return (
        <PageLayout className="Transaction">
            <RestrictedSection feature={ENTITY_FEATURE.TRANSACTION} permission={PERMISSION.ACCOUNTS_VIEW_TRANSACTIONS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.transaction}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <EntityDetail<Transaction, TransactionDetailsCustomRows>
                        data={query.data}
                        rows={TRANSACTION_DETAILS_ROWS}
                        rowCustomizers={rowCustomizers}
                    />
                    <TransactionReconcileFooter data={query.data} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
