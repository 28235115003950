import { FilterInputs } from '@/components'
import { WILDCARD_SYMBOL } from '@/constants'
import { ExtractType } from '@/types'

export enum API_QUERY_PARAMS_RESERVED_NAMES {
    SEARCH = 'search',
    GROUP_BY = 'group_by'
}

export enum APIQueryParamTypes {
    Search = 'search',
    GroupBy = 'groupBy',
    FilterBy = 'filterBy'
}

/**
 * @scope
 * Utility
 * @description
 * Type to enforce extraction of an item with multiple types by the (Query type=Q) from a given configuration table map (Map=M).
 */
export type ExtractQueryType<Q, M> = ExtractType<Q, M>
export type DynamicQueryType<T> = T extends string ? T : keyof T
export type ExtractEnumType<T, Key extends keyof T> = Key extends keyof T ? T[Key] : never

/**
 * @scope
 * Utility
 * @description
 * Allows for shorthands like "search" or "filterBy" instead of a configuration object:
 */
export type APIQueryParamType<T extends APIQueryParamTypes> = `${T}`
export type UseAPIQueryParamsArg<T = void> =
    | Partial<{
          [Q in APIQueryParamTypes]: {
              configuration: ExtractQueryType<Q, APIQueryParamConfigurationMap<T>>
          }
      }>
    | APIQueryParamType<APIQueryParamTypes>

/**
 * @description
 * Different configuration types used by either one of:
 * `Search`, `GroupBy` and `FilterBy` (and others).
 */
export interface APIQueryParamConfiguration<Q = void, T = void> {
    isDisabled?: boolean

    isPersisted?: boolean
    isExpanded?: boolean

    toggleIsVisible?(state: boolean): void
    toggleIsActive?(state: boolean): void
    toggleIsExpanded?(state: boolean): void

    stateParser?(state: ExtractQueryType<Q, APIQueryParamStateMap<T>>): typeof state | undefined
    APIQueryParamKey?: API_QUERY_PARAMS_RESERVED_NAMES
    uniqueId?: string
}

export type APIQueryParamQuickFilters<T> = {
    [K in keyof Partial<T> & { [WILDCARD_SYMBOL]?: [typeof WILDCARD_SYMBOL] }]: ExtractEnumType<T, K>[]
}

export interface APIQueryParamConfigurationFilterBy<T>
    extends APIQueryParamConfiguration<APIQueryParamTypes.FilterBy, T> {
    filters?: FilterInputs<T>
    preselected?: APIQueryParamStateFilterBy<T>

    quickFilters?: APIQueryParamQuickFilters<T>
}
export interface APIQueryParamConfigurationSearch extends APIQueryParamConfiguration<APIQueryParamTypes.Search> {
    isEnabled?: boolean
}
export interface APIQueryParamConfigurationGroupBy extends APIQueryParamConfiguration<APIQueryParamTypes.GroupBy> {
    groups: readonly string[]
    onClose?(): void
}
export type APIQueryParamConfigurationMap<T = void> = {
    [APIQueryParamTypes.Search]: APIQueryParamConfigurationSearch
    [APIQueryParamTypes.GroupBy]: APIQueryParamConfigurationGroupBy
    [APIQueryParamTypes.FilterBy]: APIQueryParamConfigurationFilterBy<T>
}

/**
 * @description
 * State types and state mappings for `Search`, `GroupBy` and `FilterBy`:
 */
export type APIQueryParamStateFilterBy<T> = { [key in keyof Partial<Omit<T, API_QUERY_PARAMS_RESERVED_NAMES>>]: T[key] }
export type APIQueryParamStateSearch = { [API_QUERY_PARAMS_RESERVED_NAMES.SEARCH]?: string }
export type APIQueryParamStateGroupBy = { [API_QUERY_PARAMS_RESERVED_NAMES.GROUP_BY]?: string[] }
export type APIQueryParamStateMap<T = void> = {
    [APIQueryParamTypes.Search]: APIQueryParamStateSearch
    [APIQueryParamTypes.GroupBy]: APIQueryParamStateGroupBy
    [APIQueryParamTypes.FilterBy]: APIQueryParamStateFilterBy<T>
}

/**
 * @description
 * General available API for any given hook `Search`, `GroupBy` and `FilterBy`, etc.
 */
export interface UseAPIQueryParamResult<Q, T> {
    readonly isActive?: boolean
    readonly isVisible?: boolean
    //Hook states:
    readonly initialState: ExtractQueryType<Q, APIQueryParamStateMap<T>> | undefined
    readonly parsedState: ExtractQueryType<Q, APIQueryParamStateMap<T>> | undefined
    readonly innerState: ExtractQueryType<Q, APIQueryParamStateMap<T>> | undefined
    //Hook callbacks:
    setState(state?: ExtractQueryType<Q, APIQueryParamStateMap<T>>): void
    toggleIsVisible(forcedValue?: boolean): void
    //Hook initial configuration:
    readonly configuration?: ExtractQueryType<Q, APIQueryParamConfigurationMap<T>>
}

export type UseAPIQueryParamResultGroupBy = {
    isExpanded?: boolean

    isGroupByRecordColumnsVisible: boolean
    isGroupByWithEmptyRecordsVisible: boolean
    isExpandedEntries: Record<string, number>

    toggleIsExpanded?(forcedValue?: boolean): void
    getToggleGroupByExpandedEntries?(uniqueKey: string): (expandedIndex: number) => void
}

export type UseAPIQueryParamsResult<Q, T = void> = UseAPIQueryParamResult<Q, T> & UseAPIQueryParamResultGroupBy
