import { EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { COUNTERPARTIES_COMMON_ACCOUNT_DETAIL_ROWS, getCounterpartyAccountCommonRowCustomizers } from '@/pages/Common'
import { ApiObjectTypeSchema, IncomingPayment } from '@webapps/numeral-ui-core'

export function getIncomingPaymentCounterpartiesRows(incomingPayment?: IncomingPayment) {
    const rows: EntityDetailRow<IncomingPayment>[] = []

    if (incomingPayment?.originating_account) {
        rows.push('originating_account')
    }

    if (incomingPayment?.receiving_account) {
        rows.push('receiving_account')
    }

    return rows
}

export function getIncomingOrderCounterpartiesRowCustomizers(
    incomingPayment?: IncomingPayment
): EntityDetailRowCustomizers<IncomingPayment> {
    return {
        originating_account: {
            nested: true,
            rows: COUNTERPARTIES_COMMON_ACCOUNT_DETAIL_ROWS.toSpliced(3, 0, 'creditor_identifier'),
            rowCustomizers: getCounterpartyAccountCommonRowCustomizers(
                ApiObjectTypeSchema.enum.external_account,
                incomingPayment?.originating_account_id
            )
        },
        receiving_account: {
            nested: true,
            rows: COUNTERPARTIES_COMMON_ACCOUNT_DETAIL_ROWS,
            rowCustomizers: getCounterpartyAccountCommonRowCustomizers(
                ApiObjectTypeSchema.enum.internal_account,
                incomingPayment?.receiving_account_id
            )
        }
    }
}
