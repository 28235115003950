import { Nullable } from '@/types'
import {
    AccountHolderAddress,
    ExternalAccount,
    ExternalAccountStatus,
    ExternalAccountStatusSchema,
    ExternalAccountVerification,
    ExternalAccountVerificationResult,
    ExternalAccountVerificationResultSchema,
    ExternalAccountVerificationServiceResponse
} from '@webapps/numeral-ui-core'
import { chain, isObjectLike } from 'lodash'

export function externalAccountFactory(): ExternalAccount {
    const item: ExternalAccount = Object.create(null)
    const holderAddress: AccountHolderAddress = {
        country: '',
        building_number: '',
        street_name: '',
        postal_code: '',
        region_state: '',
        city: ''
    }

    item.type = undefined
    item.name = ''
    item.holder_name = ''
    item.account_number = ''
    item.bank_code = ''
    item.holder_address = holderAddress

    return item
}

export function approvedExternalAccountsFilter(value?: ExternalAccount): boolean {
    return value?.status === ExternalAccountStatusSchema.enum.approved
}

export function canDisableExternalAccountByStatus(status?: ExternalAccountStatus) {
    return status === ExternalAccountStatusSchema.enum.approved
}

export function canApproveOrDenyExternalAccountByStatus(status?: ExternalAccountStatus) {
    return status === ExternalAccountStatusSchema.enum.pending_approval
}

/**
 * @description
 * Checks for specific verification result statuses like ["valid", "invalid"], etc.
 */
export function checkExternalAccountVerificationResult(
    accountVerification: Nullable<ExternalAccountVerification>,
    verificationResults: ExternalAccountVerificationResult[]
) {
    if (!accountVerification) {
        return false
    }
    const accountVerificationServiceResponses = chain(accountVerification)
        .values()
        .filter(isObjectLike)
        .value() as ExternalAccountVerificationServiceResponse[]

    for (const result of accountVerificationServiceResponses) {
        if (verificationResults.includes(result?.result)) {
            return true
        }
    }
    return false
}

export function isExternalAccountPendingVerificationResult(
    accountVerification?: Nullable<ExternalAccountVerification>
) {
    const { pending_verification } = ExternalAccountVerificationResultSchema.enum
    return accountVerification?.result === pending_verification
}
