import { TableCellText, TableCellUserFullName, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { FormattedDateTime, StatusLabel } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, MIN_COLUMN_WIDTH } from '@/constants'
import { getUserFirstRoleName, User } from '@/services'
import { FormattedMessage } from 'react-intl'
import { UsersCustomColumns } from './Users.page.types'
import { getTableRowOptionsColumnDef } from '@/components/Table/Table.utils'
import { UserTableRowOptions } from './@components'

export const USERS_TABLE_STATIC_STATE: TableStateWithEntity<User, UsersCustomColumns> = {
    columnOrder: Object.freeze(['fullName', 'email', 'roles', 'status', 'last_login_date'])
}

export const USERS_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<User, UsersCustomColumns> = {
    fullName: {
        minSize: MIN_COLUMN_WIDTH.FULL_NAME,
        header() {
            return <FormattedMessage id="app.table.header.user.full_name" />
        },
        cell(info) {
            return <TableCellUserFullName value={info.row.original} />
        }
    },
    email: {
        size: COLUMN_WIDTH_RATIO.EMAIL,
        minSize: MIN_COLUMN_WIDTH.EMAIL
    },
    roles: {
        minSize: MIN_COLUMN_WIDTH.ROLE,
        cell(info) {
            const roleName = getUserFirstRoleName(info.getValue())
            return <TableCellText value={roleName} />
        }
    },
    status: {
        minSize: MIN_COLUMN_WIDTH.SHORT_STATUS,
        cell(info) {
            return <StatusLabel value={info.getValue()} />
        }
    },
    last_login_date: {
        minSize: MIN_COLUMN_WIDTH.LONG_DATE,
        cell(info) {
            return <FormattedDateTime value={info.getValue()} />
        }
    },
    options: {
        ...getTableRowOptionsColumnDef<User>(UserTableRowOptions),
        size: 0.3
    }
}
