import { Nullable } from '@/types'
import {
    ExternalAccountVerification,
    ExternalAccountVerificationResult,
    ExternalAccountVerificationResultSchema
} from '@webapps/numeral-ui-core'
import { chain, isObjectLike } from 'lodash'
import { ExternalAccountVerificationStatusValue } from './ExternalAccountVerificationStatus.types'

export function getExternalAccountVerificationResults(
    accountVerification: Nullable<ExternalAccountVerification>
): ExternalAccountVerificationResult[] {
    return chain(accountVerification).values().filter(isObjectLike).map('result').value()
}

export function getTotalValidExternalAccountVerifications(
    results: ExternalAccountVerificationResult[]
): [number, number] {
    let valid = 0
    let total = 0

    for (const result of results) {
        if (result !== ExternalAccountVerificationResultSchema.enum.not_verified) {
            total++
        }

        if (result === ExternalAccountVerificationResultSchema.enum.valid) {
            valid++
        }
    }

    return [valid, total]
}

function getTextColorAndBackgroundColor(numberOfValidVerifications: number, numberOfTotalVerifications: number) {
    switch (true) {
        case numberOfValidVerifications === numberOfTotalVerifications: {
            return {
                color: 'green.600',
                backgroundColor: 'green.100'
            }
        }

        case numberOfValidVerifications === 0: {
            return {
                color: 'red.600',
                backgroundColor: 'red.100'
            }
        }

        default: {
            return { color: 'orange.600', backgroundColor: 'orange.100' }
        }
    }
}

export function getExternalAccountVerificationStatusTagProps(
    accountVerification?: Nullable<ExternalAccountVerification>
): {
    messageKey: string
    color: string
    backgroundColor: string
    numberOfValidVerifications?: number
    numberOfTotalVerifications?: number
} {
    const messageKeyPrefix = 'app.common.external_account.verification_status.'
    const messageKeySuffix = '.label'

    if (!accountVerification) {
        return {
            messageKey: `${messageKeyPrefix}${ExternalAccountVerificationStatusValue.NOT_VERIFIED}${messageKeySuffix}`,
            color: 'gray.600',
            backgroundColor: 'gray.100'
        }
    }

    const applicableVerifications = getExternalAccountVerificationResults(accountVerification)
    const [valid, total] = getTotalValidExternalAccountVerifications(applicableVerifications)

    return {
        messageKey: `${messageKeyPrefix}${ExternalAccountVerificationStatusValue.VERIFIED}${messageKeySuffix}`,
        ...getTextColorAndBackgroundColor(valid, total),
        numberOfValidVerifications: valid,
        numberOfTotalVerifications: total
    }
}
