import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdReturnRequest } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ReturnRequest } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { ReturnRequestApproval } from './@components'
import { RETURN_REQUEST_DETAILS_ROW_CUSTOMIZERS, RETURN_REQUEST_DETAILS_ROWS } from './ReturnRequestDetails.page.const'
import { ReturnRequestDetailsCustomRows } from './ReturnRequestDetails.types'

export const ReturnRequestDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdReturnRequest(uuid)

    const hasApprovalPermission = hasPermission(PERMISSION.PAYMENTS_APPROVE_RETURN_REQUESTS)

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.RETURN_REQUEST}
                permission={PERMISSION.PAYMENTS_VIEW_RETURN_REQUESTS}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.return_request}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<ReturnRequest, ReturnRequestDetailsCustomRows>
                        data={query.data}
                        rows={RETURN_REQUEST_DETAILS_ROWS}
                        rowCustomizers={RETURN_REQUEST_DETAILS_ROW_CUSTOMIZERS}
                    />
                    <If condition={hasApprovalPermission}>
                        <ReturnRequestApproval data={query.data} />
                    </If>
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
