import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { isString } from 'lodash'
import { isValidIBAN } from '../../isValidIBAN'
import { digitsFormatter } from '../digits'
import { ibanFormatter } from '../iban'
import { isUKAccountNumber } from './accountNumber.formatter.utils'

export function accountNumberFormatter(value?: string): string {
    if (!isString(value)) {
        return EMPTY_CHAR_SYMBOL
    }

    const digitsOnlyValue = digitsFormatter(value)

    switch (true) {
        case isUKAccountNumber(digitsOnlyValue): {
            return digitsOnlyValue
        }

        case isValidIBAN(value): {
            return ibanFormatter(value)
        }

        default: {
            return value
        }
    }
}
