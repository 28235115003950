import { AccountHolder } from '@webapps/numeral-ui-core'

export function accountHolderFactory(): AccountHolder {
    const item: AccountHolder = Object.create(null)
    return item
}

export function enabledAccountHolderFilter(accountHolder?: AccountHolder): boolean {
    return !!accountHolder && !accountHolder?.disabled_at
}
