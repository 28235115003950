import { RestrictedSection, TableHeader, TableHeaderActionProps } from '@/components'
import { If, SupportEmailMailtoLink } from '@/components/@misc'
import {
    useAPIQueryParams,
    useMutationExportBalances,
    useQueryAggregateAllBalancesExpanded,
    useQueryFindAllBalances,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { QuickFilterProvider, TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { BalancesServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { BalancesGroupByTable, BalancesTable } from './@components'
import {
    BALANCES_FILTERS,
    BALANCES_GROUP_BY,
    BALANCES_PRESELECTED_FILTERS,
    BALANCES_QUICK_FILTERS
} from './Balances.page.const'
import { isLatestBalanceFilterBy } from './Balances.page.utils'

export const BalancesPage: React.FC = () => {
    const intl = useIntl()
    const { onAdd } = useToasts()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.accounts.balances.title' }), [intl])
    const { search, filterBy, groupBy } = useAPIQueryParams<BalancesServiceFindAllQueryOptions>(
        'search',
        {
            filterBy: {
                configuration: {
                    preselected: BALANCES_PRESELECTED_FILTERS,
                    filters: BALANCES_FILTERS,
                    quickFilters: BALANCES_QUICK_FILTERS,
                    toggleIsVisible() {
                        if (groupBy.isVisible) {
                            groupBy?.toggleIsVisible(false)
                        }
                    }
                }
            }
        },
        {
            groupBy: {
                configuration: {
                    groups: BALANCES_GROUP_BY,
                    isExpanded: true,
                    toggleIsVisible() {
                        if (filterBy.isVisible) {
                            filterBy.toggleIsVisible(false)
                        }
                    },
                    onClose() {
                        groupBy.toggleIsVisible(false)
                    }
                }
            }
        }
    )

    const query = useQueryFindAllBalances({ ...search.parsedState, ...filterBy.parsedState })
    const mutationExport = useMutationExportBalances({
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.INFO,
                status: 'info',
                title: <FormattedMessage id="app.common.export.success.toast.title" />,
                description: <FormattedMessage id="app.common.export.success.toast.description" />
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.export.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.common.export.error.toast.description"
                        values={{ supportEmailMailtoLink: <SupportEmailMailtoLink /> }}
                    />
                )
            })
        }
    })

    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const actions = useMemo<TableHeaderActionProps[]>(() => {
        return [
            {
                onClick() {
                    mutationExport.mutate({ search_parameters: { ...filterBy.parsedState } })
                },
                children: intl.formatMessage({ id: 'app.common.actions.export' }),
                isDisabled: isDataEmpty || mutationExport.isPending
            }
        ]
    }, [intl, isDataEmpty, mutationExport, filterBy.parsedState])

    const computedGroupBy = useMemo(() => {
        const hasLatestBalanceFilterBy = isLatestBalanceFilterBy(filterBy.parsedState)
        return {
            ...groupBy,
            isVisible: groupBy.isVisible && hasLatestBalanceFilterBy,
            isActive: groupBy.isActive && hasLatestBalanceFilterBy,
            configuration: {
                ...groupBy.configuration,
                isDisabled: !hasLatestBalanceFilterBy
            }
        }
    }, [groupBy, filterBy])

    const queryFindAll = useQueryFindAllBalances(
        { ...search.parsedState, ...filterBy.parsedState },
        {
            enabled: !computedGroupBy.isActive
        }
    )
    const queryAggregateAll = useQueryAggregateAllBalancesExpanded(
        {
            ...search.parsedState,
            ...filterBy.parsedState,
            ...computedGroupBy.parsedState
        },
        {
            enabled: computedGroupBy.isActive
        }
    )

    const isLoading = useMemo(() => {
        return computedGroupBy.isActive ? queryAggregateAll.isLoading : queryFindAll.isLoading
    }, [computedGroupBy.isActive, queryAggregateAll, queryFindAll])

    return (
        <PageLayout className="Balances">
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection feature={ENTITY_FEATURE.BALANCE} permission={PERMISSION.ACCOUNTS_VIEW_BALANCES}>
                <QuickFilterProvider<BalancesServiceFindAllQueryOptions> filterBy={filterBy}>
                    <TableHeader<BalancesServiceFindAllQueryOptions>
                        objectType={ApiObjectTypeExtendedSchema.enum.balance}
                        isLoading={isLoading}
                        filterBy={filterBy}
                        groupBy={computedGroupBy}
                        search={search}
                        actions={actions}
                    />
                    <If condition={computedGroupBy?.isActive}>
                        <BalancesGroupByTable
                            queryItem={queryAggregateAll}
                            isFiltering={filterBy.isActive}
                            isSearching={search.isActive}
                            {...computedGroupBy}
                        />
                    </If>
                    <If condition={!computedGroupBy?.isActive}>
                        <BalancesTable
                            queryItem={queryFindAll}
                            isFiltering={filterBy.isActive}
                            isSearching={search.isActive}
                        />
                    </If>
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
