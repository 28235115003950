import type { ChartOptions } from 'chart.js'
import { CHART_BASE_COLORS, CHART_OPTIONS } from '@/components/@charts'

export const CHART_BAR_HORIZONTAL_STACK_BASE_CONFIGURATION: ChartOptions<'bar'> = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    scales: {
        x: {
            beginAtZero: true,
            display: false,
            stacked: true,
            grid: {
                display: false
            },
            ticks: {
                ...CHART_OPTIONS.TICKS
            }
        },
        y: {
            display: false,
            stacked: true
        }
    },
    plugins: {
        legend: CHART_OPTIONS.LEGEND,
        tooltip: CHART_OPTIONS.TOOLTIP,
        datalabels: {
            color: CHART_BASE_COLORS.WHITE
        }
    },
    elements: {
        bar: {
            borderWidth: 0
        }
    },
    responsive: true,
    animation: CHART_OPTIONS.ANIMATION
}
