import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdExternalAccount } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ApiObjectTypeSchema, ExternalAccount } from '@webapps/numeral-ui-core'
import { chain, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ExternalAccountVerificationFooter } from './@components'
import {
    EXTERNAL_ACCOUNT_VERIFICATIONS_ROW_CUSTOMIZERS,
    EXTERNAL_ACCOUNT_VERIFICATIONS_ROWS
} from './ExternalAccountVerifications.page.const'
import { ExternalAccountVerificationCustomRows } from './ExternalAccountVerifications.types'

export const ExternalAccountVerificationsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdExternalAccount(uuid)
    const canVerifyExternalAccount = useMemo<boolean>(() => {
        const hasVerifyExternalAccountsPermission = hasPermission(PERMISSION.COUNTERPARTIES_VERIFY_EXTERNAL_ACCOUNTS)
        const hasNoAccountVerification = chain(query.data?.account_verification).isNull().value()

        return hasVerifyExternalAccountsPermission && hasNoAccountVerification
    }, [query, hasPermission])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.EXTERNAL_ACCOUNT_VERIFICATION}
                permission={PERMISSION.COUNTERPARTIES_VIEW_EXTERNAL_ACCOUNTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.external_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isEmpty(query?.data)}
                    isDataEmpty={isEmpty(query?.data)}>
                    <EntityDetail<ExternalAccount, ExternalAccountVerificationCustomRows>
                        data={query.data}
                        rows={EXTERNAL_ACCOUNT_VERIFICATIONS_ROWS}
                        rowCustomizers={EXTERNAL_ACCOUNT_VERIFICATIONS_ROW_CUSTOMIZERS}
                    />
                    <If condition={canVerifyExternalAccount}>
                        <ExternalAccountVerificationFooter externalAccountId={query.data?.id} />
                    </If>
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
