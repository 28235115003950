import React, { useMemo } from 'react'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Text
} from '@chakra-ui/react'
import { identity } from 'lodash'
import {
    computeGroupInputStats,
    extractGroupOptions,
    isItemChecked,
    normalizeGroupOptions
} from '../GroupAccordionInput.utils'
import {
    GROUP_ACCORDION_DEFAULT_WILDCARDS,
    GroupAccordionInputProps,
    GroupAccordionSupportedInputType
} from '../GroupAccordionInput.types'
import { GROUPED_ACCORDION_FIELD_STYLE_BOX } from '../GroupAccordionInput.const'
import { FormattedMessage } from 'react-intl'
import { useGroupAccordionLabelFormatters } from '@/components/@inputs/GroupAccordionInput/@hooks'

export type GroupAccordionReadonlyFieldProps<T extends GroupAccordionSupportedInputType> = GroupAccordionInputProps<T>

export const GroupAccordionReadonlyField = <T extends GroupAccordionSupportedInputType>({
    name,
    value,
    options,
    groupOrder,
    wildcardSymbol,
    getGroupOptionLabel,
    getGroupCounterLabel,
    getGroupLabel,
    optionsFilter = identity
}: GroupAccordionReadonlyFieldProps<T>) => {
    const normalizedGroupOptions = useMemo(
        () => normalizeGroupOptions(options, optionsFilter),
        [options, optionsFilter]
    )
    const hasArrayShape = useMemo(() => Array.isArray(options), [options])
    const computedWildcardSymbol = useMemo(() => {
        return wildcardSymbol || hasArrayShape
            ? GROUP_ACCORDION_DEFAULT_WILDCARDS.ARRAY_WILDCARD
            : GROUP_ACCORDION_DEFAULT_WILDCARDS.RECORD_WILDCARD
    }, [wildcardSymbol, hasArrayShape])

    const availableGroups = useMemo(() => extractGroupOptions(options, groupOrder), [options, groupOrder])
    const groupStats = useMemo(() => {
        return computeGroupInputStats(
            value,
            availableGroups,
            normalizedGroupOptions,
            computedWildcardSymbol,
            isItemChecked
        )
    }, [availableGroups, normalizedGroupOptions, value, computedWildcardSymbol])
    const { getFormattedGroupLabel, getFormattedGroupCounterLabel, getFormattedGroupOptionLabel } =
        useGroupAccordionLabelFormatters({ hasArrayShape, getGroupLabel, getGroupCounterLabel, getGroupOptionLabel })

    return (
        <FormControl>
            <FormLabel>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Box {...GROUPED_ACCORDION_FIELD_STYLE_BOX}>
                <Accordion allowMultiple={true}>
                    {availableGroups.map((group, index) => {
                        const groupItems = normalizedGroupOptions[group] || []
                        const totalGroupCount = groupStats[group]?.total
                        const selectedGroupCount = groupStats[group]?.selected
                        const isFirstItem = index === 0
                        const propsAccordionItem = {
                            [isFirstItem ? 'borderTop' : 'borderBottom']: 'unset'
                        }

                        return (
                            <AccordionItem {...propsAccordionItem} key={group}>
                                <AccordionButton display="flex" padding="16px" justifyContent="space-between">
                                    <Text color="gray.800" fontWeight="semibold">
                                        {getFormattedGroupLabel(group, selectedGroupCount, totalGroupCount)}
                                    </Text>
                                    <Flex>
                                        <Text color="gray.500" marginX="8px" fontSize="14px">
                                            {getFormattedGroupCounterLabel(group, selectedGroupCount, totalGroupCount)}
                                        </Text>
                                        <AccordionIcon color="gray.500" />
                                    </Flex>
                                </AccordionButton>
                                <AccordionPanel
                                    display="flex"
                                    gap="16px"
                                    flexDirection="column"
                                    marginLeft="16px"
                                    color="gray.800">
                                    {groupItems.map((item, index) => {
                                        const isChecked = isItemChecked(group, item, value, computedWildcardSymbol)

                                        return (
                                            <Checkbox
                                                key={index}
                                                isChecked={isChecked}
                                                isDisabled={true}
                                                isReadOnly={true}
                                                sx={{
                                                    _checked: {
                                                        span: { opacity: 1, userSelect: 'text' }
                                                    }
                                                }}>
                                                {getFormattedGroupOptionLabel(
                                                    group,
                                                    item,
                                                    selectedGroupCount,
                                                    totalGroupCount
                                                )}
                                            </Checkbox>
                                        )
                                    })}
                                </AccordionPanel>
                            </AccordionItem>
                        )
                    })}
                </Accordion>
            </Box>
        </FormControl>
    )
}
