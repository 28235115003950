import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { APIQueryParamStateFilterBy, APIQueryParamStateSearch, APIQueryParamTypes, usePage } from '@/hooks'
import { getReconcilableTargetObjectAbsolutePath, RECONCILE_ENTITY_PAGE_CONTEXT } from '@/pages'
import { QuickFilterParamTypes } from '@/providers'
import { Payment } from '@/types'
import {
    ApiObjectType,
    ExpectedPaymentsServiceFindAllQueryOptions,
    IncomingPaymentsServiceFindAllQueryOptions,
    PaymentCaptureServiceFindAllQueryOptions,
    PaymentOrdersServiceFindAllQueryOptions,
    ReconciliationStatus,
    ReturnsServiceFindAllQueryOptions,
    Transaction,
    TransactionsServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'
import { get, set } from 'lodash'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RECONCILIATION_CONTEXT_TO_QUICK_FILTER_CONTEXT_BINDING } from './useReconciliation.const'
import { getReconcilableTargetObjectFilterByState } from './useReconciliation.utils'
import { isCreateReconciliationAvailable } from '@/services'

/**
 * @description
 * Starts or cancels a reconciliation flow based on the entry point (payment_* or transactions).
 * Steps:
 * 1. Starts a navigation with preset navigations states (for search, filters, quick-filters, etc.);
 * 2. Commits a sourceObject as a global context object accessible from any page (see `usePage` hook);
 */
export function useReconciliation() {
    const { setNonPersistedState, getNonPersistedState } = usePage<Payment | Transaction>()
    const navigate = useNavigate()
    const onStartReconciliation = useCallback(
        (targetObjectType: ApiObjectType, sourceObject?: Payment | Transaction) => {
            const targetPath = getReconcilableTargetObjectAbsolutePath(targetObjectType)
            const hasSameObjectType = targetObjectType === sourceObject?.object

            if (!targetPath || hasSameObjectType) {
                return
            }

            const searchBy: APIQueryParamStateSearch = {
                search: EMPTY_CHAR_SYMBOL
            }
            const targetFilterBy: APIQueryParamStateFilterBy<
                | PaymentOrdersServiceFindAllQueryOptions
                | IncomingPaymentsServiceFindAllQueryOptions
                | ExpectedPaymentsServiceFindAllQueryOptions
                | ReturnsServiceFindAllQueryOptions
                | PaymentCaptureServiceFindAllQueryOptions
                | TransactionsServiceFindAllQueryOptions
            > = {
                ...RECONCILIATION_CONTEXT_TO_QUICK_FILTER_CONTEXT_BINDING,
                ...getReconcilableTargetObjectFilterByState(targetObjectType, sourceObject)
            }

            const reconcileEntityNonPersistedState = set(
                Object.create(null),
                RECONCILE_ENTITY_PAGE_CONTEXT,
                sourceObject
            )
            setNonPersistedState(reconcileEntityNonPersistedState)

            navigate(targetPath, {
                state: {
                    [QuickFilterParamTypes.QuickFilter]: RECONCILIATION_CONTEXT_TO_QUICK_FILTER_CONTEXT_BINDING,
                    [APIQueryParamTypes.FilterBy]: targetFilterBy,
                    [APIQueryParamTypes.Search]: searchBy
                }
            })
        },
        [navigate, setNonPersistedState]
    )

    const onCancelReconciliation = useCallback(() => {
        const reconcileEntityNonPersistedState = set(Object.create(null), RECONCILE_ENTITY_PAGE_CONTEXT, null)
        setNonPersistedState(reconcileEntityNonPersistedState)
    }, [])

    const onForceCancelReconciliation = useCallback(
        <T>(quickFilterState?: APIQueryParamStateFilterBy<T>) => {
            const isCurrentlyActive = !!getNonPersistedState(RECONCILE_ENTITY_PAGE_CONTEXT)
            const activeReconciliationStatus = get(quickFilterState, 'reconciliation_status') as
                | ReconciliationStatus
                | undefined
            const hasCorrectReconciliationStatus = isCreateReconciliationAvailable(activeReconciliationStatus)
            const isEligibleForManualCancellation = isCurrentlyActive && !hasCorrectReconciliationStatus

            if (isEligibleForManualCancellation) {
                onCancelReconciliation()
            }
        },
        [onCancelReconciliation]
    )

    return {
        onStartReconciliation,
        onCancelReconciliation,
        // Used to bail auto forcefully out of the reconciliation flow:
        onForceCancelReconciliation
    }
}
