import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { DirectDebitMandate, Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationBlockByIdDirectDebitMandate(
    id?: Uuid,
    options?: MutationOptions<DirectDebitMandate, Error>
) {
    const { selectedUserAccess } = useAuth()
    const { directDebitMandatesService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<DirectDebitMandate, Error>({
        ...options,
        mutationKey: [directDebitMandatesService.url, { id }],
        mutationFn(data) {
            return directDebitMandatesService.blockDirectDebitMandate(id as string, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            queryClient.setQueryData([directDebitMandatesService.url, { id }], args[0])

            await queryClient
                .invalidateQueries({
                    queryKey: [directDebitMandatesService.url]
                })
                .catch(noop)
        }
    })
}
