import { APIQueryParamStateFilterBy } from '@/hooks'
import { Payment } from '@/types'
import {
    ApiObjectType,
    ApiObjectTypeSchema,
    Direction,
    DirectionSchema,
    ExpectedPaymentsServiceFindAllQueryOptions,
    IncomingPaymentsServiceFindAllQueryOptions,
    PaymentCaptureServiceFindAllQueryOptions,
    PaymentOrdersServiceFindAllQueryOptions,
    ReturnsServiceFindAllQueryOptions,
    Transaction,
    TransactionsServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'
import { get } from 'lodash'

export function switchObjectDirection(direction?: Direction): Direction | undefined {
    const { credit, debit } = DirectionSchema.enum
    if (direction === credit) {
        return debit
    } else if (direction === debit) {
        return credit
    } else {
        return undefined
    }
}

function maybeCopySourceObjectDirection(
    sourceObjectType?: ApiObjectType,
    direction?: Direction
): Direction | undefined {
    switch (sourceObjectType) {
        case ApiObjectTypeSchema.enum.transaction:
        case ApiObjectTypeSchema.enum.payment_order: {
            return switchObjectDirection(direction)
        }

        case ApiObjectTypeSchema.enum.expected_payment:
        case ApiObjectTypeSchema.enum.incoming_payment: {
            return direction
        }

        default: {
            return undefined
        }
    }
}

/**
 * @description
 * Creates mappings between object types and their search parameters (filterBy)
 */
export function getReconcilableTargetObjectFilterByState(
    targetObjectType?: ApiObjectType,
    sourceObject?: Payment | Transaction
) {
    const direction = get(sourceObject, 'direction')
    const currency = get(sourceObject, 'currency')
    const valueDate = get(sourceObject, 'value_date')

    switch (targetObjectType) {
        case ApiObjectTypeSchema.enum.transaction: {
            const filterBy: APIQueryParamStateFilterBy<TransactionsServiceFindAllQueryOptions> = {
                currency,
                direction: maybeCopySourceObjectDirection(sourceObject?.object, direction)
            }

            return filterBy
        }

        case ApiObjectTypeSchema.enum.payment_order: {
            const filterBy: APIQueryParamStateFilterBy<PaymentOrdersServiceFindAllQueryOptions> = {
                currency,
                end_value_date: valueDate,
                direction: maybeCopySourceObjectDirection(sourceObject?.object, direction)
            }

            return filterBy
        }

        case ApiObjectTypeSchema.enum.incoming_payment: {
            const filterBy: APIQueryParamStateFilterBy<IncomingPaymentsServiceFindAllQueryOptions> = {
                direction,
                currency,
                end_value_date: valueDate
            }

            return filterBy
        }

        case ApiObjectTypeSchema.enum.expected_payment: {
            const filterBy: APIQueryParamStateFilterBy<ExpectedPaymentsServiceFindAllQueryOptions> = {
                direction,
                currency
            }

            return filterBy
        }

        case ApiObjectTypeSchema.enum.return: {
            const filterBy: APIQueryParamStateFilterBy<ReturnsServiceFindAllQueryOptions> = {
                end_value_date: valueDate
            }

            return filterBy
        }

        case ApiObjectTypeSchema.enum.payment_capture: {
            const filterBy: APIQueryParamStateFilterBy<PaymentCaptureServiceFindAllQueryOptions> = {
                currency
            }

            return filterBy
        }
    }
}
