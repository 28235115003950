import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { noop } from 'lodash'

export function useMutationRetryFailedEvents(options?: MutationOptions<void, Error>) {
    const { selectedUserAccess } = useAuth()
    const { eventsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<void, Error>({
        mutationKey: [eventsService.url],
        mutationFn(data) {
            return eventsService.retryFailed({
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [eventsService.url]
                })
                .catch(noop)
        }
    })
}
