import { TableColumnCustomizers } from '@/components'
import { FormattedTextIntl, ReconciliationRuleName } from '@/components/@misc'
import { getTableRowOptionsColumnDef } from '@/components/Table/Table.utils'
import { Reconciliation } from '@webapps/numeral-ui-core'
import { FormattedDate } from 'react-intl'
import { ReconciliationTableRowOptions } from './@components'

export const RECONCILIATIONS_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<Reconciliation> = {
    created_at: {
        cell(info) {
            return <FormattedDate value={info.getValue()} />
        }
    },
    match_type: {
        cell(info) {
            return <FormattedTextIntl messageId={`app.table.cell.match_type.${info.getValue()}`} />
        }
    },
    reconciliation_rule_id: {
        cell(info) {
            return <ReconciliationRuleName reconciliationRuleId={info.getValue()} />
        }
    },
    options: getTableRowOptionsColumnDef<Reconciliation>(ReconciliationTableRowOptions)
}
