import { EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import {
    COUNTERPARTIES_COMMON_ACCOUNT_DETAIL_ROWS,
    COUNTERPARTIES_COMMON_ULTIMATE_ORIGINATOR_DETAILS_ROWS,
    getCounterpartyAccountCommonRowCustomizers,
    getCounterpartyUltimateOriginatorCommonRowCustomizers
} from '@/pages/Common'
import { hasUltimateOriginator, isCorporateConnectedAccount } from '@/services'
import { ApiObjectTypeSchema, ConnectedAccount, PaymentOrder } from '@webapps/numeral-ui-core'

export function getPaymentOrderCounterpartiesRows(paymentOrder?: PaymentOrder) {
    const rows: EntityDetailRow<PaymentOrder>[] = []

    if (paymentOrder?.originating_account) {
        // originating_account contains connected account data for corporate payments,
        // and internal account data for interbank payments
        rows.push('originating_account')
    }

    if (hasUltimateOriginator(paymentOrder)) {
        rows.push('ultimate_originator')
    }

    if (paymentOrder?.receiving_account) {
        rows.push('receiving_account')
    }

    return rows
}

export function getPaymentOrderCounterpartiesRowCustomizers(
    paymentOrder?: PaymentOrder,
    connectedAccount?: ConnectedAccount
): EntityDetailRowCustomizers<PaymentOrder> {
    const isCorporatePayment = isCorporateConnectedAccount(connectedAccount)

    return {
        originating_account: {
            nested: true,
            rows: COUNTERPARTIES_COMMON_ACCOUNT_DETAIL_ROWS.toSpliced(3, 0, 'creditor_identifier'),
            rowCustomizers: getCounterpartyAccountCommonRowCustomizers(
                isCorporatePayment
                    ? ApiObjectTypeSchema.enum.connected_account
                    : ApiObjectTypeSchema.enum.internal_account,
                isCorporatePayment ? paymentOrder?.connected_account_id : paymentOrder?.originating_account_id
            )
        },
        ultimate_originator: {
            nested: true,
            rows: COUNTERPARTIES_COMMON_ULTIMATE_ORIGINATOR_DETAILS_ROWS,
            rowCustomizers: getCounterpartyUltimateOriginatorCommonRowCustomizers()
        },
        receiving_account: {
            nested: true,
            rows: COUNTERPARTIES_COMMON_ACCOUNT_DETAIL_ROWS,
            rowCustomizers: getCounterpartyAccountCommonRowCustomizers(
                ApiObjectTypeSchema.enum.external_account,
                paymentOrder?.receiving_account_id
            )
        }
    }
}
