import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams, PERMISSION } from '@/services'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { ConnectedAccount, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdConnectedAccount(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<ConnectedAccount, Error>>
) {
    const { connectedAccountsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isUserLoggedIn && hasPermission(PERMISSION.SETTINGS_VIEW_CONNECTED_ACCOUNTS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<ConnectedAccount, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [connectedAccountsService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return connectedAccountsService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
