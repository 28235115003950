import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { DirectDebitMandateDirection as DirectDebitMandateDirectionType } from '@webapps/numeral-ui-core'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'

interface DirectDebitMandateDirectionProps {
    direction?: DirectDebitMandateDirectionType
}

export const DirectDebitMandateDirection: React.FC<DirectDebitMandateDirectionProps> = memo(({ direction }) => {
    if (!direction) {
        return EMPTY_VALUE_PLACEHOLDER
    }

    return <FormattedMessage id={`api.direct_debit_mandate.direction.${direction}`} defaultMessage={direction} />
})
