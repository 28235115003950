import {
    APIQueryParamConfigurationFilterBy,
    APIQueryParamStateFilterBy,
    APIQueryParamTypes,
    UseAPIQueryParamsResult
} from '@/hooks'
import { PropsWithChildren } from 'react'

export type QuickFilterContextValue<T = void> = {
    state?: APIQueryParamStateFilterBy<T>
    quickFilters: APIQueryParamConfigurationFilterBy<T>['quickFilters']
    onChange(value?: APIQueryParamStateFilterBy<T>): void
    onReset(): void
}

export enum QuickFilterParamTypes {
    QuickFilter = 'quickFilter'
}

export interface QuickFilterProviderProps<T> extends PropsWithChildren {
    filterBy: UseAPIQueryParamsResult<APIQueryParamTypes.FilterBy, T>
}
