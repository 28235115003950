import { BaseHttpClientService, extractResponseData, List, ServicesConfiguration } from '@webapps/numeral-ui-core'
import {
    Balances,
    PaymentCounters,
    PaymentOrderReturns,
    ProcessedPayments,
    ProcessedPaymentsTotalAmount,
    ProcessedPaymentsTotalNumber,
    Reconciliations,
    WIDGET_TYPE,
    WidgetsServiceBalancesQueryOptions,
    WidgetsServiceFindAllQueryOptions,
    WidgetsServicePaymentCountersQueryOptions,
    WidgetsServicePaymentOrderReturnsQueryOptions,
    WidgetsServiceReconciliationQueryOptions
} from './Widgets.service.types'

export class WidgetsService extends BaseHttpClientService {
    get url() {
        return '/v1/widgets'
    }

    async processedPaymentsTotalNumber(params?: WidgetsServiceFindAllQueryOptions, config?: ServicesConfiguration) {
        const url = `${this.url}/${WIDGET_TYPE.ProcessedPaymentsTotalNumber}`

        return this.httpClient
            .get<ProcessedPaymentsTotalNumber>(url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    async processedPaymentsTotalAmounts(params?: WidgetsServiceFindAllQueryOptions, config?: ServicesConfiguration) {
        const url = `${this.url}/${WIDGET_TYPE.ProcessedPaymentsTotalAmounts}`

        return this.httpClient
            .get<List<ProcessedPaymentsTotalAmount>>(url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    async processedPayments(params?: WidgetsServiceFindAllQueryOptions, config?: ServicesConfiguration) {
        const url = `${this.url}/${WIDGET_TYPE.ProcessedPayments}`

        return this.httpClient
            .get<ProcessedPayments>(url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    async paymentCounters(params?: WidgetsServicePaymentCountersQueryOptions, config?: ServicesConfiguration) {
        const url = `${this.url}/${WIDGET_TYPE.PaymentCounters}`

        return this.httpClient
            .get<List<PaymentCounters>>(url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    async balances(params?: WidgetsServiceBalancesQueryOptions, config?: ServicesConfiguration) {
        const url = `${this.url}/${WIDGET_TYPE.Balances}`

        return this.httpClient
            .get<Balances>(url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    async reconciliations(params?: WidgetsServiceReconciliationQueryOptions, config?: ServicesConfiguration) {
        const url = `${this.url}/${WIDGET_TYPE.Reconciliations}`

        return this.httpClient
            .get<Reconciliations>(url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    async paymentOrderReturns(params?: WidgetsServicePaymentOrderReturnsQueryOptions, config?: ServicesConfiguration) {
        const url = `${this.url}/${WIDGET_TYPE.PaymentOrderReturns}`

        return this.httpClient
            .get<PaymentOrderReturns>(url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }
}
