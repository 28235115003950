import { Form, FormPageFooter, FormSection } from '@/components'
import {
    DateInput,
    DirectDebitMandateDirectionInput,
    DirectDebitMandateSequenceInput,
    TextInput
} from '@/components/@inputs'
import { If } from '@/components/@misc'
import { DEFAULT_DATE_TEMPLATE_FORMAT } from '@/constants'
import { isDirectDebitMandateBacsDebitType } from '@/services'
import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { DirectDebitMandate } from '@webapps/numeral-ui-core'
import { format } from 'date-fns'
import { Field } from 'formik'
import { noop } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    DirectDebitMandateConnectedAccountInput,
    DirectDebitMandateOriginatingAccountSection,
    DirectDebitMandateReceivingAccountSection,
    DirectDebitMandateTypeInput
} from './@components'
import { DirectDebitMandateFormProps } from './DirectDebitMandate.form.types'
import { getDirectDebitMandateFormInitialValues } from './DirectDebitMandate.form.utils'

export const DirectDebitMandateForm: React.FC<DirectDebitMandateFormProps> = ({
    disabled,
    loading,
    onSubmit = noop
}) => {
    const intl = useIntl()
    const initialValues = useMemo(getDirectDebitMandateFormInitialValues, [])

    return (
        <Form<Partial<DirectDebitMandate>> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, dirty }) => {
                const hasBacsDebitType = isDirectDebitMandateBacsDebitType(values)
                const hasSequenceDisabled = disabled || hasBacsDebitType || !values.type
                const signatureDateMax = format(new Date(), DEFAULT_DATE_TEMPLATE_FORMAT)
                const hasTypeDisabled = disabled || !values.connected_account_id

                return (
                    <>
                        <FormSection titleId="app.common.form.sections.general_information.title">
                            <DirectDebitMandateConnectedAccountInput
                                name="connected_account_id"
                                isDisabled={disabled}
                                isRequired={true}
                            />
                            <DirectDebitMandateTypeInput
                                name="type"
                                customPlaceholderKey="app.counterparties.direct_debit_mandates.create.form.inputs.type.placeholder"
                                isDisabled={hasTypeDisabled}
                                isRequired={!values.connected_account_id}
                            />
                            <DirectDebitMandateDirectionInput
                                name="direction"
                                customPlaceholderKey="app.counterparties.direct_debit_mandates.create.form.inputs.direction.placeholder"
                                isDisabled={true}
                                isRequired={true}
                            />
                            <If condition={hasBacsDebitType}>
                                <FormControl>
                                    <FormLabel htmlFor="sequence">
                                        <FormattedMessage id="app.common.form.input.sequence.label" />
                                    </FormLabel>
                                    <Field
                                        placeholder={intl.formatMessage({
                                            id: 'app.counterparties.direct_debit_mandates.create.form.inputs.sequence.placeholder.alternative'
                                        })}
                                        as={Input}
                                        id="sequence"
                                        name="sequence"
                                        isDisabled={true}
                                    />
                                </FormControl>
                            </If>
                            <If condition={!hasBacsDebitType}>
                                <DirectDebitMandateSequenceInput
                                    name="sequence"
                                    customPlaceholderKey="app.counterparties.direct_debit_mandates.create.form.inputs.sequence.placeholder"
                                    isDisabled={hasSequenceDisabled}
                                    isRequired={true}
                                />
                            </If>
                            <TextInput
                                name="reference"
                                customPlaceholderKey="app.counterparties.direct_debit_mandates.create.form.inputs.reference.placeholder"
                                maxLength={35}
                                isDisabled={disabled}
                                isRequired={true}
                            />
                            <DateInput
                                name="signature_date"
                                max={signatureDateMax}
                                isDisabled={disabled}
                                isRequired={true}
                            />
                        </FormSection>
                        <DirectDebitMandateOriginatingAccountSection isDisabled={disabled} />
                        <DirectDebitMandateReceivingAccountSection isDisabled={disabled} />
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.counterparties.direct_debit_mandates.create.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
