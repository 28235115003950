import { useAuth, useServices } from '@/hooks'
import { APIKey, filterUserAccessAPIQueryParams } from '@/services'
import { APIError } from '@/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { UseMutationOptions } from '@tanstack/react-query/src/types'
import { AxiosError } from 'axios'
import { noop } from 'lodash'

export function useMutationCreateAPIKey(options?: UseMutationOptions<APIKey, AxiosError<APIError<APIKey>>, APIKey>) {
    const { selectedUserAccess } = useAuth()
    const { apiKeysService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = { ...filterUserAccessAPIQueryParams(selectedUserAccess) }

    return useMutation<APIKey, AxiosError<APIError<APIKey>>, APIKey>({
        ...options,
        mutationKey: [apiKeysService.url, paramsWithUserAccess],
        mutationFn(data) {
            return apiKeysService.createAPIKey(data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [apiKeysService.url]
                })
                .catch(noop)
        }
    })
}
