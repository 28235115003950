import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { addTransformRequestsToHttpClientInstance, markedFieldsTransform } from '@/services/@transformRequests'
import { APIError } from '@/types'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { ExternalAccount } from '@webapps/numeral-ui-core'
import { AxiosError } from 'axios'
import { noop } from 'lodash'

export function useMutationCreateExternalAccount(
    options?: MutationOptions<ExternalAccount, AxiosError<APIError<ExternalAccount>>, ExternalAccount>
) {
    const { selectedUserAccess } = useAuth()
    const { externalAccountsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<ExternalAccount, AxiosError<APIError<ExternalAccount>>, ExternalAccount>({
        ...options,
        mutationKey: [externalAccountsService.url],
        mutationFn(data) {
            return externalAccountsService?.createExternalAccount(data, {
                params: paramsWithUserAccess,
                transformRequest: addTransformRequestsToHttpClientInstance(
                    externalAccountsService,
                    markedFieldsTransform
                )
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [externalAccountsService.url]
                })
                .catch(noop)
        }
    })
}
