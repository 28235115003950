import { useAuth, usePermissions, useServices } from '@/hooks'
import {
    Balances,
    filterUserAccessAPIQueryParams,
    PERMISSION,
    WIDGET_TYPE,
    WidgetsServiceBalancesQueryOptions
} from '@/services'
import { APIError } from '@/types'
import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'

export function useQueryBalances(
    params: WidgetsServiceBalancesQueryOptions,
    options?: Partial<UndefinedInitialDataOptions<Balances, APIError>>
) {
    const { widgetsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()
    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.HOME_VIEW_BALANCES) && options?.enabled

    const paramsWithUserAccess: typeof params = {
        ...params,
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<Balances, APIError>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [widgetsService.url, paramsWithUserAccess, WIDGET_TYPE.Balances],
        queryFn({ signal }) {
            return widgetsService.balances(paramsWithUserAccess, { signal })
        }
    })
}
