import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { isEmptyRecord } from '@/utils'
import { Text } from '@chakra-ui/react'
import { AccountHolderAddress } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { addressTemplateBuilderWithIntl } from './EntityDetailCellHolderAddress.utils'

interface EntityDetailCellHolderAddressProps {
    value?: AccountHolderAddress
}

export const EntityDetailCellHolderAddress: React.FC<EntityDetailCellHolderAddressProps> = ({ value }) => {
    const intl = useIntl()
    const hasEmptyValue = useMemo(() => isEmptyRecord(value), [value])
    const addressTemplateBuilder = useMemo(() => {
        return addressTemplateBuilderWithIntl(intl)
    }, [intl, value])
    const formattedValue = useMemo<string>(() => addressTemplateBuilder(value), [intl, value])

    if (hasEmptyValue) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return <Text whiteSpace="pre-wrap">{formattedValue}</Text>
}
