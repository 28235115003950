import { FormSection, InternalAccountDetailsSummary } from '@/components'
import { AccountDetailsForm, OptionalAccountHolderAddressForm } from '@/components/@forms'
import { InternalAccountInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import {
    createAccountFromConnectedAccount,
    enabledOwnInternalAccountFilter,
    isFinancialInstitutionConnectedAccount
} from '@/services'
import { Switch } from '@chakra-ui/react'
import { DirectDebitMandate } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

export interface DirectDebitMandateOriginatingAccountSection {
    isDisabled?: boolean
}

export const DirectDebitMandateOriginatingAccountSection: React.FC<DirectDebitMandateOriginatingAccountSection> = ({
    isDisabled
}) => {
    const { getFieldProps, setFieldValue } = useFormikContext<DirectDebitMandate>()
    const { value: connectedAccountId } = getFieldProps('connected_account_id')
    const { value: originatingAccountId } = getFieldProps('originating_account_id')
    const [useExistingInternalAccount, setUseExistingInternalAccount] = useState(false)

    const hasConnectedAccountId = useMemo(() => globalThis.Boolean(connectedAccountId), [connectedAccountId])
    const queryConnectedAccount = useQueryFindByIdConnectedAccount(connectedAccountId, {
        enabled: hasConnectedAccountId && !isDisabled
    })
    const hasFinancialInstitutionConnectedAccount = useMemo(
        () => isFinancialInstitutionConnectedAccount(queryConnectedAccount.data),
        [queryConnectedAccount]
    )

    const onToggleUseExistingInternalAccount = useCallback(() => {
        setUseExistingInternalAccount((prevState) => {
            return !prevState
        })
    }, [])

    useEffect(() => {
        if (useExistingInternalAccount) {
            setFieldValue('originating_account', undefined)
        }

        if (!useExistingInternalAccount) {
            const originatingAccount = hasFinancialInstitutionConnectedAccount
                ? createAccountFromConnectedAccount(queryConnectedAccount.data, true)
                : undefined

            setFieldValue('originating_account_id', undefined)
            setFieldValue('originating_account', originatingAccount)
        }
    }, [
        connectedAccountId,
        useExistingInternalAccount,
        hasFinancialInstitutionConnectedAccount,
        queryConnectedAccount.data
    ])

    return (
        <If condition={queryConnectedAccount.isLoading || hasFinancialInstitutionConnectedAccount}>
            <FormSection
                titleId="app.counterparties.direct_debit_mandates.create.form.sections.originating_account.title"
                title={
                    <Switch
                        data-testid="toggle-existing-originating-account-switch"
                        size="sm"
                        isChecked={useExistingInternalAccount}
                        onChange={onToggleUseExistingInternalAccount}
                        disabled={isDisabled}>
                        <FormattedMessage id="app.counterparties.direct_debit_mandates.create.form.sections.originating_account_id.use_existing.label" />
                    </Switch>
                }>
                <If condition={useExistingInternalAccount}>
                    <InternalAccountInput
                        name="originating_account_id"
                        isPlaceholderDisplayed={true}
                        isAccountVerificationStatusDisplayed={true}
                        customPlaceholderKey="app.counterparties.direct_debit_mandates.create.form.inputs.originating_account_id.placeholder"
                        queryDataFilter={enabledOwnInternalAccountFilter}
                        isDisabled={isDisabled}
                        isRequired={true}
                    />
                    <InternalAccountDetailsSummary internalAccountId={originatingAccountId} />
                </If>
                <If condition={!useExistingInternalAccount}>
                    <AccountDetailsForm
                        inputsNamePrefix="originating_account"
                        isCreditorIdentifierInputDisplayed={true}
                        isBankCodeInputReadOnly={true}
                        isDisabled={isDisabled}
                        isRequired={true}
                    />
                    <OptionalAccountHolderAddressForm sectionName="originating_account" isDisabled={isDisabled} />
                </If>
            </FormSection>
        </If>
    )
}
