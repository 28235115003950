import { Form, FormPageFooter } from '@/components'
import { TextareaInput } from '@/components/@inputs'
import { ExternalAccountDenyData } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { useRef } from 'react'

interface ExternalAccountDenyFormProps {
    loading?: boolean
    disabled?: boolean
    readonly?: boolean

    onSubmit?(data: ExternalAccountDenyData): void
}

export const ExternalAccountDenyForm: React.FC<ExternalAccountDenyFormProps> = ({
    disabled,
    loading,
    onSubmit = noop
}) => {
    const initialValues = useRef<ExternalAccountDenyData>({
        reason: ''
    }).current

    return (
        <Form<ExternalAccountDenyData> initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit }) => {
                return (
                    <>
                        <TextareaInput
                            name="reason"
                            customLabelKey="app.counterparties.external_accounts.deny.form.inputs.reason.label"
                            customPlaceholderKey="app.counterparties.external_accounts.deny.form.inputs.reason.placeholder"
                        />
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isLoading={loading}
                            isDisabled={disabled}
                            submitLabelMessageId="app.counterparties.external_accounts.deny.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
