import { EMPTY_STATE_IMAGES, EmptyState } from '@/components'
import { PageLayout } from '@/layouts'
import { Flex } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

export const NotFoundPage: React.FC = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.common.not_found.title' }), [intl])
    const onClickGoBack = useCallback(() => {
        navigate(-1)
    }, [navigate])

    return (
        <PageLayout className="NotFound">
            <Flex justifyContent="center" flexGrow="2" height="inherit">
                <EmptyState
                    imageSrc={EMPTY_STATE_IMAGES.SETTINGS}
                    title={title}
                    propsLink={{
                        children: intl.formatMessage({ id: 'app.common.actions.back' }),
                        onClick: onClickGoBack
                    }}
                />
            </Flex>
        </PageLayout>
    )
}
