import { SupportedQueryParamValue } from './QueryParamsProvider.types'

export function serializeQueryParamValue(value: SupportedQueryParamValue): string | undefined {
    if (value === null || value === undefined) {
        return undefined
    } else if (typeof value === 'object') {
        return JSON.stringify(value)
    } else {
        return String(value)
    }
}

export function deserializeQueryParams(serializedValue: string): SupportedQueryParamValue {
    try {
        return JSON.parse(serializedValue)
    } catch (e) {
        return serializedValue
    }
}
