import { QueryFallback, RestrictedSection } from '@/components'
import { useMutationUpdateByIdWebhook, useNavigationRoutes, useQueryFindByIdWebhook, useToasts } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { WebhookCreateOrEditForm } from '../@components'

export const WebhookEditPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const { onAdd } = useToasts()
    const { paths } = useNavigationRoutes()
    const title = useRef(intl.formatMessage({ id: 'app.developers.webhooks.edit.title' })).current
    const query = useQueryFindByIdWebhook(uuid)
    const mutation = useMutationUpdateByIdWebhook(uuid, {
        onSuccess() {
            const path = [paths.DEVELOPERS.WEB_HOOKS, uuid].join('/')
            navigate(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.developers.webhooks.edit.success.toast.title" />,
                description: <FormattedMessage id="app.developers.webhooks.edit.success.toast.description" />
            })
        },
        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.developers.webhooks.edit.error.toast.title" />,
                description: <FormattedMessage id="app.developers.webhooks.edit.error.toast.description" />
            })
        }
    })
    const isDataEmpty = useMemo<boolean>(() => isEmpty(query.data), [query.data])

    return (
        <PageLayout className="WebhookEdit">
            <RestrictedSection permission={PERMISSION.DEVELOPERS_CONFIGURE_WEBHOOKS}>
                <PageHeaderLayout title={title} />
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.webhook}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <WebhookCreateOrEditForm
                        data={query.data}
                        onSubmit={mutation.mutate}
                        loading={mutation.isPending}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
