import { COMMA_SYMBOL, NEW_LINE_SYMBOL, SPACE_SYMBOL } from '@/constants'
import { AccountHolderAddress } from '@webapps/numeral-ui-core'
import { IntlShape } from 'react-intl'

export function addressTemplateBuilderWithIntl(intl: IntlShape) {
    return function addressTemplateBuilder(value?: AccountHolderAddress) {
        const addressParts = [
            value?.line_1,
            value?.line_2,
            value?.department,
            value?.sub_department,
            value?.room
                ? intl.formatMessage(
                      { id: 'api.account_holder_address.room' },
                      {
                          room: value?.room
                      }
                  )
                : null,
            value?.floor
                ? intl.formatMessage(
                      { id: 'api.account_holder_address.floor' },
                      {
                          floor: value?.floor
                      }
                  )
                : null,
            [value?.building_name, value?.building_number, value?.street_name]
                .filter(globalThis.Boolean)
                .join(SPACE_SYMBOL),
            [
                value?.postal_box
                    ? intl.formatMessage(
                          { id: `api.account_holder_address.postal_box` },
                          {
                              postalBox: value?.postal_box
                          }
                      )
                    : null,
                value?.city_location_name
            ]
                .filter(globalThis.Boolean)
                .join(`${COMMA_SYMBOL}${SPACE_SYMBOL}`),
            value?.district_name,
            [value?.postal_code, value?.city].filter(globalThis.Boolean).join(SPACE_SYMBOL),
            value?.region_state,
            value?.country
                ? intl.formatDisplayName(value?.country, {
                      type: 'region'
                  })
                : null
        ]

        return addressParts.filter(globalThis.Boolean).join(NEW_LINE_SYMBOL)
    }
}
