import { TableCellShortDate, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { Webhook } from '@/services'
import { getWebhookStatusMapper } from '@/services/Webhooks'
import { FormattedTextIntl, StatusLabel } from '@/components/@misc'

export const WEBHOOKS_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/webhooks`

export const WEBHOOKS_TABLE_STATIC_STATE: TableStateWithEntity<Webhook> = {
    columnOrder: Object.freeze(['created_at', 'name', 'url', 'enabled'])
}

export const WEBHOOKS_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<Webhook> = {
    created_at: {
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    enabled: {
        size: COLUMN_WIDTH_RATIO.STATUS,
        minSize: MIN_COLUMN_WIDTH.STATUS,
        header(info) {
            return <FormattedTextIntl messageId="app.table.header.status" />
        },
        cell(info) {
            const value = getWebhookStatusMapper(info.row.original)

            return <StatusLabel value={value} />
        }
    }
}
