import { InternalAccount, InternalAccountStatusSchema, InternalAccountTypeSchema } from '@webapps/numeral-ui-core'

export function enabledInternalAccountFilter(internalAccount?: InternalAccount): boolean {
    return internalAccount?.status !== InternalAccountStatusSchema.enum.disabled
}

export function virtualInternalAccountFilter(internalAccount?: InternalAccount): boolean {
    return internalAccount?.type === InternalAccountTypeSchema.enum.virtual
}

export function ownInternalAccountFilter(internalAccount?: InternalAccount): boolean {
    return internalAccount?.type === InternalAccountTypeSchema.enum.own
}

export function enabledOwnInternalAccountFilter(internalAccount?: InternalAccount): boolean {
    return enabledInternalAccountFilter(internalAccount) && ownInternalAccountFilter(internalAccount)
}

export function enabledVirtualInternalAccountFilter(internalAccount?: InternalAccount): boolean {
    return enabledInternalAccountFilter(internalAccount) && virtualInternalAccountFilter(internalAccount)
}
