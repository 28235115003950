import { FieldValidator } from 'formik'
import { IntlShape } from 'react-intl'
import { z } from 'zod'

const MIN_LENGTH_VALUE = 1
const MinValueStringSchema = z.string().min(MIN_LENGTH_VALUE)
const MinValueArraySchema = z.array(z.any()).min(MIN_LENGTH_VALUE)
const MinValueRecordSchema = z.record(z.string(), z.string().min(MIN_LENGTH_VALUE))

const MinValueSchema = z.union([MinValueStringSchema, MinValueArraySchema, MinValueRecordSchema])

/**
 * @description
 * Pass through validator, used to validate required inputs that don't need any specific validation
 */
export function anythingValidator(
    intl: IntlShape,
    isRequired?: boolean,
    name?: string,
    values?: unknown
): FieldValidator {
    return (value: string): string | void => {
        if (isRequired && !MinValueSchema.safeParse(value).success) {
            return intl.formatMessage({ id: 'app.common.form.validation.required' })
        }
    }
}
