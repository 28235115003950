import { Icon, IconProps } from '@chakra-ui/react'

export const DevelopersIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.0624 17.8375L2.2124 11.9875L8.0499 6.15002L9.3999 7.50002L4.8749 12.025L9.3749 16.525L8.0624 17.8375ZM15.9499 17.85L14.5999 16.5L19.1249 11.975L14.6249 7.47502L15.9374 6.16252L21.7874 12.0125L15.9499 17.85Z"
        />
    </Icon>
)
