import { APIQueryParamStateFilterBy } from '@/hooks'
import { BulkActions, BulkActionsActiveContext } from '@/providers'
import { PERMISSION } from '@/services'
import { setTimeout } from '@/utils'
import { Query } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'
import { chain, get, isEqual, isObject } from 'lodash'

/**
 * @description
 * - Checks if one of the defined bulk actions is applied simultaneously to currentActive and filterBy state.
 */
export function isBulkActionsActive<T = any>(
    actions?: BulkActions,
    activeContext?: BulkActionsActiveContext<any>,
    filterByState?: APIQueryParamStateFilterBy<any>
): boolean {
    const availableActions = chain(actions).keys().value()
    const hasIntersectionWithBulkActionContext = chain(activeContext)
        .values()
        .intersection(availableActions)
        .size()
        .gt(0)
        .value()
    const hasIntersectionWithFilterByContext = chain(filterByState)
        .values()
        .intersection(availableActions)
        .size()
        .gt(0)
        .value()

    return hasIntersectionWithBulkActionContext && hasIntersectionWithFilterByContext
}

export function getResolvedIds<T extends { id?: Uuid }>(resolvedItems?: T[]): Set<Uuid> {
    const data = resolvedItems?.map((item) => item.id).filter((id): id is Uuid => id !== undefined)

    return new Set(data)
}

/**
 * @todo
 * Find a better predicate name for its usage.
 */
export function isBulkActionPermissionEnabled(
    hasPermission: (permission: PERMISSION) => boolean,
    actions?: BulkActions
) {
    const actionsWrapper = chain(actions)

    return actionsWrapper
        .keys()
        .map((actionName) => {
            return actionsWrapper.get(actionName).get('configuration').get('permissions').every(hasPermission).value()
        })
        .head()
        .value()
}

/**
 * @usage
 * Utility to be used in `react-query` for very specific cache updates.
 * eg:
 * 1. queryClient.invalidateQueries({ predicate: queryClientPredicate });
 * 2. queryClient.refetchQueries({ predicate: queryClientPredicate });
 *
 * @description
 * Finds all queries matching a `state` object.
 */
export function getQueriesMatchingQuickFiltersPredicate<T>(state?: APIQueryParamStateFilterBy<T>) {
    return function queriesMatchingQuickFiltersPredicate(query: Query) {
        return query.queryKey.filter(isObject).some((queryKey) => {
            return chain(state)
                .map((value, key) => isEqual(value, get(queryKey, key)))
                .every()
                .value()
        })
    }
}

/**
 * @usage
 * Utility function
 */
export function wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}
