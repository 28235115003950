import { PaymentType, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { FormattedTextIntl } from '@/components/@misc'

export const ReconciliationPaymentType: React.FC<{ value?: PaymentType }> = ({ value }) => {
    if (PaymentTypeSchema.safeParse(value).success) {
        return <FormattedTextIntl messageId={`api.object.${value}`} />
    }

    return <FormattedTextIntl messageId="app.table.cell.payment_type_not_applicable" />
}
