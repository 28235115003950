import { EntityDetailRow } from '@/components'
import { Account } from '@webapps/numeral-ui-core'
import { CounterpartyAccountCommonCustomRows } from './Counterparties.page.types'

export const COUNTERPARTIES_COMMON_ACCOUNT_DETAIL_ROWS: EntityDetailRow<
    Account,
    CounterpartyAccountCommonCustomRows
>[] = ['related_objects', 'holder_name', 'account_number', 'bank_code', 'holder_address']

export const COUNTERPARTIES_COMMON_ULTIMATE_ORIGINATOR_DETAILS_ROWS = [
    'holder_name',
    'organization_identification',
    'holder_address'
]
