import { EntityDetailRowCustomizers } from '@/components'
import { COUNTERPARTIES_COMMON_ACCOUNT_DETAIL_ROWS, getCounterpartyAccountCommonRowCustomizers } from '@/pages/Common'
import { isDirectDebitMandateIncoming } from '@/services'
import { ApiObjectTypeSchema, DirectDebitMandate } from '@webapps/numeral-ui-core'

export function getDirectDebitMandateCounterpartiesRowCustomizers(
    directDebitMandate?: DirectDebitMandate
): EntityDetailRowCustomizers<DirectDebitMandate> {
    const isIncoming = isDirectDebitMandateIncoming(directDebitMandate)
    return {
        originating_account: {
            nested: true,
            rows: COUNTERPARTIES_COMMON_ACCOUNT_DETAIL_ROWS.toSpliced(3, 0, 'creditor_identifier'),
            rowCustomizers: getCounterpartyAccountCommonRowCustomizers(
                isIncoming ? ApiObjectTypeSchema.enum.external_account : ApiObjectTypeSchema.enum.internal_account,
                directDebitMandate?.originating_account_id
            )
        },
        receiving_account: {
            nested: true,
            rows: COUNTERPARTIES_COMMON_ACCOUNT_DETAIL_ROWS,
            rowCustomizers: getCounterpartyAccountCommonRowCustomizers(
                isIncoming ? ApiObjectTypeSchema.enum.internal_account : ApiObjectTypeSchema.enum.external_account,
                directDebitMandate?.receiving_account_id
            )
        }
    }
}
