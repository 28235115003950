import { EntityDetailRowCustomizers } from '@/components'
import { InternalAccountType } from '@/components/@misc'
import { getCounterpartyAccountCommonRowCustomizers } from '@/pages/Common'
import { ApiObjectTypeSchema, ExpectedPayment } from '@webapps/numeral-ui-core'

export function getExpectedPaymentCounterpartiesRowCustomizers(
    expectedPayment?: ExpectedPayment
): EntityDetailRowCustomizers<ExpectedPayment> {
    return {
        internal_account: {
            nested: true,
            rows: ['related_objects', 'name', 'type', 'account_number'],
            rowCustomizers: {
                ...getCounterpartyAccountCommonRowCustomizers(
                    ApiObjectTypeSchema.enum.internal_account,
                    expectedPayment?.internal_account_id
                ),
                type: {
                    cell() {
                        return <InternalAccountType type={expectedPayment?.internal_account?.type} />
                    }
                }
            }
        },
        external_account: {
            nested: true,
            rows: ['related_objects', 'holder_name', 'account_number'],
            rowCustomizers: getCounterpartyAccountCommonRowCustomizers(
                ApiObjectTypeSchema.enum.external_account,
                expectedPayment?.external_account_id
            )
        }
    }
}
