import React, { useMemo, useRef } from 'react'
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    type ChartOptions,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { ChartLegend } from '../ChartLegend'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { chain } from 'lodash'
import { useIntl } from 'react-intl'
import { CHART_MIXED_VERTICAL_STACK_CONFIGURATION } from './ChartMixedVerticalStack.const'
import { ChartMixedVerticalStackProps } from './ChartMixedVerticalStack.types'
import { If } from '@/components/@misc'
import { Box, Stack, useToken } from '@chakra-ui/react'
import { CHART_BASE_COLORS, getChartBarScalesColors } from '@/components/@charts'
import { useChartJSCleanupChartInstance } from '../@hooks'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement)

/**
 * @todo
 * Write test
 */
export function ChartMixedVerticalStack<T = void>({
    data,
    options,
    showLegend,
    isDisabled,
    isLoading,
    ...props
}: ChartMixedVerticalStackProps<T>) {
    const intl = useIntl()
    const chartRef = useRef<ChartJSOrUndefined<'line' & 'bar', T>>()
    const chartId = useChartJSCleanupChartInstance(chartRef)
    const fallbackColors = [
        CHART_BASE_COLORS.WHITE,
        CHART_BASE_COLORS.NUMERAL_LIGHT_GRAY,
        CHART_BASE_COLORS.NUMERAL_GRAY
    ]
    const [white, gray100, gray500] = useToken('colors', ['white', 'gray.100', 'gray.500'], fallbackColors)
    const optionsWithDefaults = useMemo<ChartOptions<'line' | 'bar'>>(() => {
        const scales = getChartBarScalesColors(white, gray100, gray500)

        return chain(options)
            .merge(CHART_MIXED_VERTICAL_STACK_CONFIGURATION, {
                scales,
                locale: intl.locale
            })
            .value()
    }, [white, gray100, gray500, options, intl])

    return (
        <Stack className="ChartMixedVerticalStack" justifyContent="space-between" height="inherit">
            <Box className="ChartMixedVerticalStack-Chart" width="100%" height="100%" {...props}>
                <Line id={chartId} options={optionsWithDefaults} data={data} ref={chartRef} />
            </Box>
            <If condition={showLegend}>
                <ChartLegend<T> chartRef={chartRef} dataset={data?.datasets} isDisabled={true} isLoading={isLoading} />
            </If>
        </Stack>
    )
}
