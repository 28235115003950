import { MenuProps } from 'react-select/dist/declarations/src/components/Menu'
import { ChangeEvent, useCallback, useEffect, useRef } from 'react'
import { InputActionMeta } from 'react-select/dist/declarations/src/types'
import { setTimeout } from '@/utils'
import { components } from 'react-select'
import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { SearchIcon } from '@/components/@icons'

export function SelectMenuWithSearchInput<T>({ children, innerRef, innerProps, ...props }: MenuProps<T, any, any>) {
    const searchInputRef = useRef<HTMLInputElement>(null)
    const onChangeInput = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            props.selectProps.onInputChange?.(event.target.value, Object.create(null) as InputActionMeta)
        },
        [props.selectProps.onInputChange]
    )

    useEffect(() => {
        const cancel = setTimeout(() =>
            searchInputRef.current?.focus?.({
                preventScroll: true
            })
        )

        return () => {
            searchInputRef.current?.blur?.()
            cancel()
        }
    }, [searchInputRef.current])

    return (
        <components.Menu innerRef={innerRef} innerProps={innerProps} {...props}>
            <Box data-testid="select-menu">
                <Box paddingX="8px" paddingTop="8px" data-testid="select-menu-search">
                    <InputGroup>
                        <InputLeftElement color="gray.400">
                            <SearchIcon />
                        </InputLeftElement>
                        <Input
                            ref={searchInputRef}
                            type="text"
                            autoComplete="off"
                            autoCapitalize="off"
                            value={props.selectProps.inputValue}
                            onChange={onChangeInput}
                            onBlur={props.selectProps.onBlur}
                            onFocus={props.selectProps.onFocus}
                            data-testid="select-menu-search-input"
                        />
                    </InputGroup>
                </Box>
                {children}
            </Box>
        </components.Menu>
    )
}
