import { GroupByTable, QueryFallback, useTableRowNavigation } from '@/components'
import { useNavigationRoutes } from '@/hooks'
import { BalanceExpanded, BalanceGroup } from '@/services'
import { ApiObjectTypeSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { BALANCE_TABLE_STATIC_STATE } from '../../Balances.page.const'
import { BalancesGroupByHeader } from '../BalancesGroupByHeader'
import { BALANCE_TABLE_COLUMN_CUSTOMIZERS_GROUP_BY } from './BalancesGroupByTable.const'
import { BalancesGroupByTableProps } from './BalancesGroupByTable.types'

export const BalancesGroupByTable: React.FC<BalancesGroupByTableProps> = ({
    queryItem,
    isFiltering,
    isSearching,
    ...props
}) => {
    const { relativePaths } = useNavigationRoutes()
    const onTableRowClick = useTableRowNavigation<BalanceExpanded>('id', relativePaths.ACCOUNTS.DETAILS)
    const isDataEmpty = useMemo<boolean>(() => isEmpty(queryItem.data?.groups), [queryItem])

    return (
        <QueryFallback
            objectType={ApiObjectTypeSchema.enum.balance}
            isLoading={queryItem.isLoading}
            isSuccess={queryItem.isSuccess}
            isError={queryItem.isError}
            isDataEmpty={isDataEmpty}
            isFilteringOrSearching={isSearching || isFiltering}>
            <GroupByTable<BalanceExpanded, BalanceGroup>
                {...props}
                data={queryItem.data}
                onRowClick={onTableRowClick}
                groupHeader={BalancesGroupByHeader}
                state={BALANCE_TABLE_STATIC_STATE}
                columnCustomizers={BALANCE_TABLE_COLUMN_CUSTOMIZERS_GROUP_BY}
            />
        </QueryFallback>
    )
}
