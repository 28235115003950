import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { InternalAccountType as InternalAccountTypeCore } from '@webapps/numeral-ui-core'
import { FormattedMessage } from 'react-intl'

interface InternalAccountTypeProps {
    type?: InternalAccountTypeCore
}

export const InternalAccountType: React.FC<InternalAccountTypeProps> = ({ type }) => {
    if (!type) {
        return EMPTY_VALUE_PLACEHOLDER
    }

    return <FormattedMessage id={`api.internal_account.type.${type}`} defaultMessage={type} />
}
