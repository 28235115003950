import {
    FilterInputs,
    TableCellShortDate,
    TableCellText,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import { BooleanInput, DateInput, TextInput } from '@/components/@inputs'
import { EnabledStatus } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { AccountHolder, AccountHoldersServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export const ACCOUNT_HOLDER_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/account-holder-object`

export const ACCOUNT_HOLDER_TABLE_STATIC_STATE: TableStateWithEntity<AccountHolder> = {
    columnOrder: Object.freeze(['created_at', 'name', 'disabled_at'])
}

export const ACCOUNT_HOLDER_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<AccountHolder> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    name: {
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    disabled_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_STATUS,
        cell(info) {
            const isDisabled = !!info.getValue()
            return <EnabledStatus isDisabled={isDisabled} />
        }
    }
}

export const ACCOUNT_HOLDER_FILTERS: FilterInputs<AccountHoldersServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    name: <TextInput name="name" customPlaceholderKey="app.common.form.input.account_holder_name.placeholder" />,
    disabled: <BooleanInput name="disabled" />
}
