import { PaymentCounters, WidgetsServiceLookBehindOffsets } from '@/services'
import {
    ApiObjectType,
    ApiObjectTypeSchema,
    IncomingPaymentStatusSchema,
    PaymentOrderStatusSchema,
    PaymentType,
    PaymentTypeSchema,
    ReturnStatusSchema
} from '@webapps/numeral-ui-core'
import { chain, includes } from 'lodash'
import { endOfYesterday, startOfToday, startOfYesterday, subDays } from 'date-fns'

export function filterPaymentCountersByPaymentType(paymentType: PaymentType, records?: PaymentCounters[]) {
    return chain(records)
        .filter((record) => {
            switch (paymentType) {
                case PaymentTypeSchema.enum.payment_order:
                    return includes(
                        [
                            PaymentOrderStatusSchema.enum.pending_approval,
                            PaymentOrderStatusSchema.enum.approved,
                            PaymentOrderStatusSchema.enum.processing,
                            PaymentOrderStatusSchema.enum.sent,
                            PaymentOrderStatusSchema.enum.executed,
                            PaymentOrderStatusSchema.enum.returned
                        ],
                        record.status
                    )

                case PaymentTypeSchema.enum.incoming_payment:
                    return includes(
                        [IncomingPaymentStatusSchema.enum.pending_return, IncomingPaymentStatusSchema.enum.rejected],
                        record.status
                    )

                case PaymentTypeSchema.enum.return:
                    return includes([ReturnStatusSchema.enum.pending_approval], record.status)

                default:
                    return includes(
                        [
                            PaymentOrderStatusSchema.enum.returned,
                            PaymentOrderStatusSchema.enum.approved,
                            PaymentOrderStatusSchema.enum.sent
                        ],
                        record.status
                    )
            }
        })
        .value()
}

export function computeDateRangeFilterByLookBehindOffset(
    lookBehindOffset: WidgetsServiceLookBehindOffsets = 0
): [Date, Date] {
    if (lookBehindOffset < 1) {
        const today = startOfToday()
        return [today, today]
    }

    if (lookBehindOffset === 1) {
        const yesterdayStart = startOfYesterday()
        const yesterdayEnd = endOfYesterday()
        return [yesterdayStart, yesterdayEnd]
    }

    const endDate = subDays(startOfToday(), 1)
    const startDate = subDays(endDate, lookBehindOffset - 1)

    return [startDate, endDate]
}

export function computeIntervalQueryKeysByObjectType(objectType: ApiObjectType) {
    switch (objectType) {
        case ApiObjectTypeSchema.enum.expected_payment: {
            return ['start_expected_date', 'end_expected_date']
        }

        default: {
            return ['start_value_date', 'end_value_date']
        }
    }
}
