import { CustomField } from '@webapps/numeral-ui-core'
import { chain, isString } from 'lodash'
import { useMemo } from 'react'

interface EntityDetailLabelCustomFieldValuesProps {
    data?: CustomField[]
    value?: Record<string, string>
}

export const EntityDetailLabelCustomFieldValues: React.FC<EntityDetailLabelCustomFieldValuesProps> = ({
    data,
    value
}) => {
    const transformedValue = useMemo(() => {
        return chain(value)
            .keys()
            .map((key) => {
                const keyFinder = (item: CustomField) => item?.key === key
                return chain(data).find(keyFinder).defaultTo(key).value()
            })
            .value()
    }, [data, value])

    return (
        <>
            {transformedValue.map((item, index) => {
                const value = isString(item) ? item : item?.name

                return (
                    <tr key={index}>
                        <td style={{ padding: '8px 0', border: 'unset' }} title={value}>
                            {value}
                        </td>
                    </tr>
                )
            })}
        </>
    )
}
