import { Select } from '@/components/@misc'
import { FormikInput } from '@/types'
import { prettyPrintCasedWords } from '@/utils'
import { anythingValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { ExternalAccount, ExternalAccountType, ExternalAccountTypeSchema } from '@webapps/numeral-ui-core'
import { useField, useFormikContext } from 'formik'
import { isUndefined } from 'lodash'
import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface ExternalAccountTypeInputProps extends Omit<FormikInput, 'value'> {
    value?: ExternalAccountType
}

export const ExternalAccountTypeInput: React.FC<ExternalAccountTypeInputProps> = ({
    name,
    value,
    customLabelKey,
    customPlaceholderKey,
    customPlaceholderValues,
    isRequired,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const { setFieldValue } = useFormikContext<ExternalAccount>()
    const [field, meta, helpers] = useField<typeof value>({
        name,
        value,
        validate: validator?.(intl, isRequired)
    })
    const onChange = useCallback(
        (value: ExternalAccountType) => {
            helpers.setValue(value)

            /**
             * @description
             * Resets:
             * 1. - `company_registration_number_type`
             * 2. - `company_registration_number`
             */
            switch (true) {
                case isUndefined(value):
                case value === ExternalAccountTypeSchema.enum.legal_entity: {
                    setFieldValue('company_registration_number_type', undefined)
                    setFieldValue('company_registration_number', undefined)
                    break
                }
            }
        },
        [setFieldValue, helpers]
    )
    const labelKey = useMemo(() => customLabelKey || `app.common.form.input.${name}.label`, [customLabelKey, name])
    const placeholder = useMemo(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey }, customPlaceholderValues)
        }
        return intl.formatMessage({ id: 'app.common.form.input.select.placeholder' })
    }, [customPlaceholderKey, customPlaceholderValues, intl])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={labelKey} />
            </FormLabel>
            <Select<ExternalAccountType>
                {...inputProps}
                {...field}
                name={name}
                placeholder={placeholder}
                isInvalid={isInvalid}
                onChange={onChange}
                options={ExternalAccountTypeSchema.options}
                getOptionLabel={prettyPrintCasedWords}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
