export const USER_IDENTITY_PROVIDERS_PATTERN = /google|windowslive|linkedin|github/

export enum USER_ACCESS_CONFIGURATION_ENTITY_TYPE {
    CORPORATE = 'corporate',
    FINANCIAL_INSTITUTION = 'financial_institution'
}

export enum ENTITY_FEATURE {
    PAYMENT_ORDER = 'payment_order',
    EXPECTED_PAYMENT = 'expected_payment',
    INCOMING_PAYMENT = 'incoming_payment',
    RETURN = 'return',
    RETURN_REQUEST = 'return_request',
    BALANCE = 'balance',
    TRANSACTION = 'transaction',
    COUNTERPARTY = 'counterparty',
    EXTERNAL_ACCOUNT_VERIFICATION = 'account_verification',
    DIRECT_DEBIT_MANDATE = 'direct_debit_mandate',
    RECONCILIATION = 'reconciliation',
    SIMULATOR = 'simulator',
    INQUIRY = 'inquiry',
    PAYMENT_CAPTURE = 'payment_capture',
    PAYMENT_RETRY_RULE = 'payment_retry_rule',
    BANK_CODE_LOOKUP = 'bank_code_lookup',
    CUSTOM_ROLE = 'custom_role'
}

export enum PERMISSION {
    // Home
    HOME_ADMIN = 'home.admin',
    HOME_VIEW_PROCESSED_PAYMENTS_TOTAL_NUMBER = 'home.view_processed_payments_total_number',
    HOME_VIEW_PROCESSED_PAYMENTS_TOTAL_AMOUNTS = 'home.view_processed_payments_total_amount',
    HOME_VIEW_PROCESSED_PAYMENTS = 'home.view_processed_payments',
    HOME_VIEW_PAYMENT_COUNTERS = 'home.view_payment_counters',
    HOME_VIEW_BALANCES = 'home.view_balances',
    HOME_VIEW_RECONCILIATIONS = 'home.view_reconciliations',
    HOME_VIEW_RETURNS = 'home.view_returns',

    // Accounts
    ACCOUNTS_ADMIN = 'accounts.admin',
    // Transactions
    ACCOUNTS_VIEW_TRANSACTIONS = 'accounts.view_transactions',
    ACCOUNTS_EDIT_TRANSACTIONS = 'accounts.edit_transactions',

    // Balances
    ACCOUNTS_VIEW_BALANCES = 'accounts.view_balances',

    // Payments
    PAYMENTS_ADMIN = 'payments.admin',

    // Payment orders
    PAYMENTS_VIEW_PAYMENT_ORDERS = 'payments.view_payment_orders',
    PAYMENTS_CREATE_PAYMENT_ORDERS = 'payments.create_payment_orders',
    PAYMENTS_APPROVE_PAYMENT_ORDERS = 'payments.approve_payment_orders',
    PAYMENTS_EDIT_PAYMENT_ORDERS = 'payments.edit_payment_orders',

    // Incoming payments
    PAYMENTS_VIEW_INCOMING_PAYMENTS = 'payments.view_incoming_payments',
    PAYMENTS_CONFIRM_INCOMING_PAYMENTS = 'payments.confirm_incoming_payments',

    // Returns
    PAYMENTS_VIEW_RETURNS = 'payments.view_returns',
    PAYMENTS_CREATE_RETURNS = 'payments.create_returns',
    PAYMENTS_APPROVE_RETURNS = 'payments.approve_returns', //was out of order

    // Return requests
    PAYMENTS_VIEW_RETURN_REQUESTS = 'payments.view_return_requests',
    PAYMENTS_CREATE_RETURN_REQUESTS = 'payments.create_return_requests',
    PAYMENTS_APPROVE_RETURN_REQUESTS = 'payments.approve_return_requests',

    // Inquiries
    PAYMENTS_VIEW_INQUIRIES = 'payments.view_inquiries',
    PAYMENTS_APPROVE_INQUIRIES = 'payments.approve_inquiries',

    // Expected payments
    PAYMENTS_VIEW_EXPECTED_PAYMENTS = 'payments.view_expected_payments',
    PAYMENTS_CREATE_EXPECTED_PAYMENTS = 'payments.create_expected_payments',
    PAYMENTS_EDIT_EXPECTED_PAYMENTS = 'payments.edit_expected_payments',

    // Payment captures
    PAYMENTS_VIEW_PAYMENT_CAPTURES = 'payments.view_payment_captures',
    PAYMENTS_CREATE_PAYMENT_CAPTURES = 'payments.create_payment_captures',
    PAYMENTS_EDIT_PAYMENT_CAPTURES = 'payments.edit_payment_captures',

    // Reconciliations
    RECONCILIATIONS_ADMIN = 'reconciliations.admin',
    RECONCILIATIONS_VIEW_RECONCILIATIONS = 'reconciliations.view_reconciliations',
    RECONCILIATIONS_CONFIGURE_RECONCILIATIONS = 'reconciliations.configure_reconciliations',

    // Counterparties
    // Account holders
    COUNTERPARTIES_ADMIN = 'counterparties.admin',
    COUNTERPARTIES_VIEW_ACCOUNT_HOLDERS = 'counterparties.view_account_holders',
    COUNTERPARTIES_CREATE_ACCOUNT_HOLDERS = 'counterparties.create_account_holders',
    COUNTERPARTIES_EDIT_ACCOUNT_HOLDERS = 'counterparties.edit_account_holders',
    COUNTERPARTIES_APPROVE_ACCOUNT_HOLDERS = 'counterparties.approve_account_holders',

    // External accounts
    COUNTERPARTIES_VIEW_EXTERNAL_ACCOUNTS = 'counterparties.view_external_accounts',
    COUNTERPARTIES_CREATE_EXTERNAL_ACCOUNTS = 'counterparties.create_external_accounts',
    COUNTERPARTIES_EDIT_EXTERNAL_ACCOUNTS = 'counterparties.edit_external_accounts',
    COUNTERPARTIES_APPROVE_EXTERNAL_ACCOUNTS = 'counterparties.approve_external_accounts',
    COUNTERPARTIES_VERIFY_EXTERNAL_ACCOUNTS = 'counterparties.verify_external_accounts',

    // Internal accounts
    COUNTERPARTIES_VIEW_INTERNAL_ACCOUNTS = 'counterparties.view_internal_accounts',
    COUNTERPARTIES_CREATE_INTERNAL_ACCOUNTS = 'counterparties.create_internal_accounts',
    COUNTERPARTIES_EDIT_INTERNAL_ACCOUNTS = 'counterparties.edit_internal_accounts',

    // Direct debit mandates
    COUNTERPARTIES_VIEW_DIRECT_DEBIT_MANDATES = 'counterparties.view_direct_debit_mandates',
    COUNTERPARTIES_CREATE_DIRECT_DEBIT_MANDATES = 'counterparties.create_direct_debit_mandates',
    COUNTERPARTIES_EDIT_DIRECT_DEBIT_MANDATES = 'counterparties.edit_direct_debit_mandates',

    // Files
    FILES_ADMIN = 'files.admin',
    FILES_VIEW_FILES = 'files.view_files',
    FILES_APPROVE_FILES = 'files.approve_files',
    FILES_DOWNLOAD_FILES = 'files.download_files',

    // Developers
    DEVELOPERS_ADMIN = 'developers.admin',

    // API Keys
    DEVELOPERS_VIEW_API_KEYS = 'developers.view_api_keys',
    DEVELOPERS_CONFIGURE_API_KEYS = 'developers.configure_api_keys',

    // Webhooks
    DEVELOPERS_VIEW_WEBHOOKS = 'developers.view_webhooks',
    DEVELOPERS_CONFIGURE_WEBHOOKS = 'developers.configure_webhooks',

    // Events
    DEVELOPERS_VIEW_EVENTS = 'developers.view_events',
    DEVELOPERS_RESEND_EVENTS = 'developers.resend_events',
    DEVELOPERS_VIEW_LOGS = 'developers.view_logs',
    DEVELOPERS_TRIGGER_BANK_SIMULATOR = 'developers.trigger_bank_simulator',

    // Settings
    SETTINGS_ADMIN = 'settings.admin',

    // Connected accounts
    SETTINGS_VIEW_CONNECTED_ACCOUNTS = 'settings.view_connected_accounts',

    // Users
    SETTINGS_VIEW_USERS = 'settings.view_users',
    SETTINGS_INVITE_USERS = 'settings.invite_users',
    SETTINGS_EDIT_USERS = 'settings.edit_users',
    SETTINGS_DISABLE_USERS = 'settings.disable_users',

    // Roles
    SETTINGS_VIEW_ROLES = 'settings.view_roles',
    SETTINGS_CONFIGURE_ROLES = 'settings.configure_roles',
    SETTINGS_CREATE_ROLES = 'settings.create_roles',

    // Custom fields
    SETTINGS_VIEW_CUSTOM_FIELDS = 'settings.view_custom_fields',
    SETTINGS_CREATE_CUSTOM_FIELDS = 'settings.create_custom_fields',
    SETTINGS_EDIT_CUSTOM_FIELDS = 'settings.edit_custom_fields',
    SETTINGS_DISABLE_CUSTOM_FIELDS = 'settings.disable_custom_fields',

    // Reconciliations rules
    SETTINGS_VIEW_RECONCILIATION_RULES = 'settings.view_reconciliation_rules',
    SETTINGS_CONFIGURE_RECONCILIATION_RULES = 'settings.configure_reconciliation_rules',

    // Legal entity
    SETTINGS_VIEW_LEGAL_ENTITY = 'settings.view_legal_entity',
    SETTINGS_CONFIGURE_LEGAL_ENTITY = 'settings.configure_legal_entity',

    // Billing
    SETTINGS_VIEW_BILLING = 'settings.view_billing',
    SETTINGS_CONFIGURE_BILLING = 'settings.configure_billing',

    // Exports
    SETTINGS_EXPORT_INVOICES = 'settings.export_invoices',

    SETTINGS_VIEW_AUDIT_TRAILS = 'settings.view_audit_trails',

    // Approval rules
    SETTINGS_VIEW_APPROVAL_RULES = 'settings.view_approval_rules',
    SETTINGS_CONFIGURE_APPROVAL_RULES = 'settings.configure_approval_rules'
}

export enum PERMISSIONS_GROUPS {
    HOME = 'home',
    PAYMENTS = 'payments',
    ACCOUNTS = 'accounts',
    RECONCILIATIONS = 'reconciliations',
    COUNTERPARTIES = 'counterparties',
    FILES = 'files',
    DEVELOPERS = 'developers',
    SETTINGS = 'settings'
}
