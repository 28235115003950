import { useMemo } from 'react'
import { Text } from '@chakra-ui/react'
import { get } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { APIError } from '@/types'
import { DASH_SEPARATOR } from '@/constants'
import { AxiosError } from 'axios'
import { Role } from '@/services'

//@todo Reuse the general notification body component instead.
export const RoleCreateErrorNotification: React.FC<{
    error: AxiosError<APIError<Role>>
}> = ({ error }) => {
    const errorDetails = useMemo(() => get(error, 'response.data.details', []), [error])

    return (
        <>
            <Text>
                <FormattedMessage id="app.settings.roles.create.form.validation.create.error.toast.description" />
            </Text>
            {errorDetails.map((detail, index: number) => {
                return (
                    <Text whiteSpace="normal" key={index}>
                        {`${DASH_SEPARATOR} ${detail?.field}: ${detail?.reason}`}
                    </Text>
                )
            })}
        </>
    )
}
