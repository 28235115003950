import { getFormSelectPlaceholderByReactQueryState } from '@/components'
import { If, Select } from '@/components/@misc'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import { isSCTPayment } from '@/services'
import { FormikInput } from '@/types'
import { anythingValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import {
    ConfidentialityOption,
    ConfidentialityOptionSchema,
    PaymentOrder,
    ServiceNameSchema
} from '@webapps/numeral-ui-core'
import { useField, useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const PaymentOrderConfidentialityOptionInput: React.FC<FormikInput> = ({
    name,
    value,
    customPlaceholderKey,
    isPlaceholderDisplayed,
    isRequired,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()

    const { setFieldValue, getFieldProps } = useFormikContext<PaymentOrder>()
    const { value: connectedAccountId } = getFieldProps('connected_account_id')
    const { value: type } = getFieldProps('type')
    const { value: direction } = getFieldProps('direction')

    const [field, meta, helper] = useField({ name, value, validate: validator(intl, isRequired, name) })
    const hasConnectedAccountId = useMemo<boolean>(() => globalThis.Boolean(connectedAccountId), [connectedAccountId])
    const query = useQueryFindByIdConnectedAccount(connectedAccountId, {
        enabled: hasConnectedAccountId
    })
    const isDisabled = useMemo<boolean>(() => {
        return query.isLoading || !hasConnectedAccountId
    }, [query, hasConnectedAccountId])
    const placeholder = useMemo<string | undefined>(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey })
        }
        return getFormSelectPlaceholderByReactQueryState(intl, query, isPlaceholderDisplayed)
    }, [customPlaceholderKey, intl, query, isPlaceholderDisplayed])
    const hasSCTPayrollServiceActive = useMemo<boolean>(() => {
        const servicesActivated = query.data?.services_activated
        return !!servicesActivated?.includes(ServiceNameSchema.enum.sct_payroll)
    }, [query])
    const isVisible = useMemo(() => {
        return hasSCTPayrollServiceActive && isSCTPayment(type, direction)
    }, [hasSCTPayrollServiceActive, type, direction])

    const isInvalid = meta.touched && !!meta.error

    useEffect(() => {
        setFieldValue('confidentiality_option', undefined)
    }, [type])

    return (
        <If condition={isVisible}>
            <FormControl key={name} isInvalid={isInvalid}>
                <FormLabel htmlFor={name}>
                    <FormattedMessage id={`app.common.form.input.${name}.label`} />
                </FormLabel>
                <Select<ConfidentialityOption>
                    {...inputProps}
                    {...field}
                    name={name}
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                    isInvalid={isInvalid}
                    isRequired={isRequired}
                    isLoading={query.isLoading}
                    onChange={helper.setValue}
                    options={ConfidentialityOptionSchema.options}
                    getOptionLabel={(item) => intl.formatMessage({ id: `api.payment.confidentiality_option.${item}` })}
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
        </If>
    )
}
