import { memo, useMemo } from 'react'
import { ApiObjectType } from '@webapps/numeral-ui-core'
import { HOME_PAGE_STYLE_CONFIGURATION } from '@/pages/Home/Home.page.const'
import { Box, BoxProps, Text } from '@chakra-ui/react'
import { percentageFormatter } from '@/utils/@formatters'

interface TableCellReconciledStatusProps extends BoxProps {
    objectType: ApiObjectType
    reconciledRate: number
}

export const TableCellReconciledStatus: React.FC<TableCellReconciledStatusProps> = memo(
    ({ reconciledRate, ...boxProps }) => {
        const formattedReconciledRate = useMemo(() => {
            return percentageFormatter(reconciledRate, true)
        }, [reconciledRate])

        return (
            <Box {...boxProps} textAlign="right">
                <Text
                    color="gray.500"
                    title={formattedReconciledRate}
                    aria-label={formattedReconciledRate}
                    fontSize={HOME_PAGE_STYLE_CONFIGURATION.FONT_SIZE}>
                    {formattedReconciledRate}
                </Text>
            </Box>
        )
    }
)
