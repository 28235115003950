import { WidgetLoading, WidgetNoDataAvailable } from './'
import { PropsWithChildren } from 'react'
import { WidgetVariants } from '../'

interface WidgetQueryFallbackProps extends PropsWithChildren {
    isLoading?: boolean
    isError?: boolean
    isDataEmpty?: boolean
    variant?: WidgetVariants
}

export const WidgetQueryFallback: React.FC<WidgetQueryFallbackProps> = ({
    isLoading,
    isError,
    isDataEmpty,
    variant,
    children
}) => {
    switch (true) {
        case isLoading: {
            return <WidgetLoading variant={variant} />
        }

        case isError:
        case isDataEmpty: {
            return <WidgetNoDataAvailable variant={variant} />
        }

        default: {
            return children
        }
    }
}
