import { QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdRole } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { RoleDetails } from '../@components'
import { useMemo } from 'react'

export const RoleDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdRole(uuid)
    const isDataEmpty = useMemo(() => isEmpty(query?.data), [query.data])

    return (
        <PageLayout className="RoleDetails">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_ROLES}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.role}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <RoleDetails data={query.data} />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
