import { LinkWithFilterBy } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useNavigationRoutes } from '@/hooks'
import { ExternalAccount, PaymentOrdersServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

interface ExternalAccountDetailsRelatedObjectsProps {
    externalAccount?: ExternalAccount
}

export const ExternalAccountDetailsRelatedObjects: React.FC<ExternalAccountDetailsRelatedObjectsProps> = ({
    externalAccount
}) => {
    const { paths } = useNavigationRoutes()

    if (!externalAccount?.id) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <LinkWithFilterBy<PaymentOrdersServiceFindAllQueryOptions>
            path={paths.PAYMENTS.PAYMENT_ORDERS}
            filterBy={{ external_account_id: externalAccount.id }}
            labelKey="app.page.details.cells.related_objects.payment_orders.link"
        />
    )
}
