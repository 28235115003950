import { ApiObjectType, ApiObjectTypeSchema, WildcardSchema } from '@webapps/numeral-ui-core'

export const enum WEBHOOK_RULES_MODES {
    ALL = '*',
    INDIVIDUAL = 'individual'
}

export const WEBHOOK_ALL_RULES = {
    [WildcardSchema.value]: [WildcardSchema.value]
}

export const WEBHOOK_RULE_OPTIONS: Partial<Record<ApiObjectType, string[]>> = {
    [ApiObjectTypeSchema.enum.payment_order]: [
        'approved',
        'processing',
        'sent',
        'executed',
        'canceled',
        'processing_canceled',
        'pending',
        'pending_retry',
        'retried',
        'rejected',
        'returned',
        'reconciled',
        'partially_reconciled',
        'unreconciled'
    ],
    [ApiObjectTypeSchema.enum.transaction]: ['new', 'updated', 'reconciled', 'partially_reconciled', 'unreconciled'],
    [ApiObjectTypeSchema.enum.file]: ['processed', 'received', 'created', 'approved', 'canceled', 'sent', 'rejected'],
    [ApiObjectTypeSchema.enum.incoming_payment]: [
        'received',
        'pending_confirmation',
        'confirmed',
        'pending_return',
        'returned',
        'rejected',
        'reconciled',
        'partially_reconciled',
        'unreconciled'
    ],
    [ApiObjectTypeSchema.enum.return]: [
        'pending',
        'sent',
        'processing',
        'executed',
        'received',
        'rejected',
        'pending_retry',
        'retried',
        'reconciled',
        'partially_reconciled',
        'unreconciled'
    ],
    [ApiObjectTypeSchema.enum.return_request]: [
        'received',
        'sent',
        'accepted',
        'denied',
        'pending_retry',
        'retried',
        'rejected'
    ],
    [ApiObjectTypeSchema.enum.inquiry]: ['received', 'denied'],
    [ApiObjectTypeSchema.enum.balance]: ['new'],
    [ApiObjectTypeSchema.enum.expected_payment]: [
        'created',
        'canceled',
        'updated',
        'reconciled',
        'partially_reconciled',
        'unreconciled'
    ],
    [ApiObjectTypeSchema.enum.external_account]: ['created', 'updated', 'disabled'],
    [ApiObjectTypeSchema.enum.internal_account]: ['created', 'updated', 'disabled'],
    [ApiObjectTypeSchema.enum.reconciliation]: ['created', 'canceled']
}
