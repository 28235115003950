import { Select } from '@/components/@misc'
import { useCustomFormikField, useQueryFindByIdConnectedAccount } from '@/hooks'
import { getPaymentOrderReturnRequestReasonOptions } from '@/services'
import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { IncomingPaymentReturnReason, PaymentOrder, SepaReturnReason } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface ReturnRequestReturnReasonSchemaInputProps extends FormikInput {
    data?: PaymentOrder
    isPlaceholderDisplayed?: boolean
}

export const ReturnRequestReturnReasonInput: React.FC<ReturnRequestReturnReasonSchemaInputProps> = ({
    name,
    data,
    value,
    isPlaceholderDisplayed,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<IncomingPaymentReturnReason>({ name, value })
    const placeholder = useMemo<string | undefined>(() => {
        if (!isPlaceholderDisplayed) {
            return
        }
        return intl.formatMessage({ id: `app.common.form.input.${name}.placeholder` })
    }, [intl, isPlaceholderDisplayed, name])
    const query = useQueryFindByIdConnectedAccount(data?.connected_account_id, {
        enabled: globalThis.Boolean(data?.connected_account_id)
    })
    const options = useMemo<SepaReturnReason[]>(() => {
        return getPaymentOrderReturnRequestReasonOptions(data, query.data)
    }, [data, query])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Select<SepaReturnReason>
                {...inputProps}
                {...field}
                id={name}
                placeholder={placeholder}
                onChange={helpers.setValue}
                isInvalid={isInvalid}
                options={options}
                getOptionLabel={(option) => {
                    return `${option} - ${intl.formatMessage({ id: `api.sepa_return_reason.${option}` })}`
                }}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
