import { getFormButtonLoadingLeftIcon, StickyFooter } from '@/components'
import { useMutationVerifyExternalAccount, useToasts } from '@/hooks'
import { TOAST_VARIANTS } from '@/providers'
import { checkExternalAccountVerificationResult, isExternalAccountPendingVerificationResult } from '@/services'
import { Alert, Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { ExternalAccountVerificationResultSchema, Uuid } from '@webapps/numeral-ui-core'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface ExternalAccountApprovalProps {
    externalAccountId?: Uuid
}

export const ExternalAccountVerificationFooter: React.FC<ExternalAccountApprovalProps> = ({ externalAccountId }) => {
    const { onAdd } = useToasts()
    const mutationVerify = useMutationVerifyExternalAccount(externalAccountId, {
        onSuccess({ account_verification }) {
            const { invalid, impossible_to_verify } = ExternalAccountVerificationResultSchema.enum
            const hasSomeInvalidResults = checkExternalAccountVerificationResult(account_verification, [invalid])
            const hasSomeImpossibleToVerifyResults = checkExternalAccountVerificationResult(account_verification, [
                impossible_to_verify
            ])
            const hasPendingVerificationResult = isExternalAccountPendingVerificationResult(account_verification)

            switch (true) {
                case hasSomeInvalidResults: {
                    onAdd({
                        variant: TOAST_VARIANTS.WARNING,
                        status: 'warning',
                        title: (
                            <FormattedMessage id="app.counterparties.external_accounts.verify.warning.toast.title" />
                        ),
                        description: (
                            <FormattedMessage id="app.counterparties.external_accounts.verify.warning.toast.description" />
                        )
                    })

                    break
                }

                case hasSomeImpossibleToVerifyResults: {
                    onAdd({
                        variant: TOAST_VARIANTS.ERROR,
                        status: 'error',
                        title: <FormattedMessage id="app.counterparties.external_accounts.verify.error.toast.title" />,
                        description: (
                            <FormattedMessage id="app.counterparties.external_accounts.verify.error.toast.description" />
                        )
                    })
                    break
                }

                case hasPendingVerificationResult: {
                    onAdd({
                        variant: TOAST_VARIANTS.INFO,
                        status: 'info',
                        title: <FormattedMessage id="app.counterparties.external_accounts.verify.info.toast.title" />,
                        description: (
                            <FormattedMessage id="app.counterparties.external_accounts.verify.info.toast.description" />
                        )
                    })
                    break
                }

                default: {
                    onAdd({
                        variant: TOAST_VARIANTS.SUCCESS,
                        status: 'success',
                        title: (
                            <FormattedMessage id="app.counterparties.external_accounts.verify.success.toast.title" />
                        ),
                        description: (
                            <FormattedMessage id="app.counterparties.external_accounts.verify.success.toast.description" />
                        )
                    })
                }
            }
        },
        onError(response) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.error.title" />,
                description: (
                    <FormattedMessage id="app.counterparties.external_accounts.verify.error.toast.description" />
                )
            })
        }
    })
    const onVerify = useCallback(() => {
        mutationVerify.mutate()
    }, [mutationVerify])

    const [marginBottom, setMarginBottom] = useState<number>(0)

    if (!externalAccountId) {
        return null
    }

    return (
        <Box className="ExternalAccountVerification" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Alert status="info" variant="numeralAccent">
                    <Flex alignItems="center" justifyContent="space-between" flex="1">
                        <Stack>
                            <Text fontWeight="bold">
                                <FormattedMessage id="app.counterparties.external_accounts.tabs.verifications.footer.title" />
                            </Text>
                            <Text>
                                <FormattedMessage id="app.counterparties.external_accounts.tabs.verifications.footer.label.description" />
                            </Text>
                        </Stack>
                        <Flex gap="16px">
                            <Button
                                onClick={onVerify}
                                leftIcon={getFormButtonLoadingLeftIcon(mutationVerify.isPending)}
                                isDisabled={mutationVerify.isPending}
                                variant="solid">
                                <FormattedMessage id="app.counterparties.external_accounts.tabs.verifications.footer.actions.verify.label" />
                            </Button>
                        </Flex>
                    </Flex>
                </Alert>
            </StickyFooter>
        </Box>
    )
}
