import { Icon, IconProps } from '@chakra-ui/react'

export const CounterpartiesIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 21.4V6.40492H6.40492V2H16.9954V10.8098H21.4V21.4H13.0214V16.9954H10.3786V21.4H2ZM3.78086 19.6194H6.40492V16.9954H3.78086V19.6194ZM3.78086 15.2145H6.40492V12.5904H3.78086V15.2145ZM3.78086 10.8098H6.40492V8.18551H3.78086V10.8098ZM8.18551 15.2145H10.8098V12.5904H8.18551V15.2145ZM8.18551 10.8098H10.8098V8.18551H8.18551V10.8098ZM8.18551 6.40492H10.8098V3.78086H8.18551V6.40492ZM12.5904 15.2145H15.2145V12.5904H12.5904V15.2145ZM12.5904 10.8098H15.2145V8.18551H12.5904V10.8098ZM12.5904 6.40492H15.2145V3.78086H12.5904V6.40492ZM16.9954 19.6194H19.6194V16.9954H16.9954V19.6194ZM16.9954 15.2145H19.6194V12.5904H16.9954V15.2145Z"
        />
    </Icon>
)
