import { SuccessIcon } from '@/components/@icons'
import { TextInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { useActivatedFeatures, useMutationBankCodeLookup, usePermissions, useToasts } from '@/hooks'
import { TOAST_VARIANTS } from '@/providers'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { bankCodeValidator } from '@/utils/@validators'
import { Button, Tooltip } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { BANK_CODE_VALIDATION_STATE } from './AccountDetails.form.types'
import { getInputNameWithPrefix } from './AccountDetails.form.utils'

interface AccountDetailsFormProps {
    isDisabled?: boolean
    isRequired?: boolean
    inputsNamePrefix?: string
    isLookupAvailable?: boolean
    isBankCodeInputReadOnly?: boolean
    isCreditorIdentifierInputDisplayed?: boolean
}

export const AccountDetailsForm: React.FC<AccountDetailsFormProps> = ({
    isDisabled,
    isRequired,
    inputsNamePrefix,
    isLookupAvailable,
    isBankCodeInputReadOnly,
    isCreditorIdentifierInputDisplayed
}) => {
    const { getFieldProps, setFieldValue } = useFormikContext()
    const { hasPermission } = usePermissions()
    const { isFeatureActivated } = useActivatedFeatures()
    const { onAdd } = useToasts()

    const [bankCodeValidationState, setBankCodeValidationState] = useState<BANK_CODE_VALIDATION_STATE>(
        BANK_CODE_VALIDATION_STATE.UNCHECKED
    )
    const [verifiedBankCode, setVerifiedBankCode] = useState<string | undefined>(undefined)

    const holderNameInputName = getInputNameWithPrefix('holder_name', inputsNamePrefix)
    const accountNumberInputName = getInputNameWithPrefix('account_number', inputsNamePrefix)
    const bankCodeInputName = getInputNameWithPrefix('bank_code', inputsNamePrefix)
    const creditorIdentifierInputName = getInputNameWithPrefix('creditor_identifier', inputsNamePrefix)

    const { value: accountNumber } = getFieldProps(accountNumberInputName)
    const { value: bankCode } = getFieldProps(bankCodeInputName)

    const lookupMutation = useMutationBankCodeLookup({
        onError() {
            onAdd({
                variant: TOAST_VARIANTS.WARNING,
                status: 'warning',
                title: <FormattedMessage id="app.common.form.account_details.bank_code_lookup.error.toast.title" />,
                description: (
                    <FormattedMessage id="app.common.form.account_details.bank_code_lookup.error.toast.description" />
                )
            })
        }
    })

    const isLookupDisplayed =
        isLookupAvailable &&
        isFeatureActivated(ENTITY_FEATURE.BANK_CODE_LOOKUP) &&
        hasPermission(PERMISSION.COUNTERPARTIES_VERIFY_EXTERNAL_ACCOUNTS)

    const isLookupDisabled =
        !isLookupDisplayed ||
        isDisabled ||
        !accountNumber ||
        bankCodeValidationState !== BANK_CODE_VALIDATION_STATE.UNCHECKED

    const onLookup = useCallback(() => {
        if (lookupMutation.isPending) {
            return
        }

        lookupMutation.mutate({ account_number: accountNumber })
    }, [lookupMutation])

    const lookupButton = (
        <If condition={isLookupDisplayed}>
            <Tooltip
                placement="right-end"
                hasArrow={true}
                label={<FormattedMessage id="app.common,form.account_details.bank_code_lookup.tooltip.label" />}>
                <Button variant="link" isDisabled={isLookupDisabled} marginLeft="12px" onClick={onLookup}>
                    <FormattedMessage id="app.common,form.account_details.bank_code_lookup.label" />
                </Button>
            </Tooltip>
        </If>
    )

    useEffect(() => {
        if (lookupMutation.isError) {
            setBankCodeValidationState(BANK_CODE_VALIDATION_STATE.ERROR)
        } else if (lookupMutation.isSuccess) {
            setVerifiedBankCode(lookupMutation?.data?.bank_code)
            setBankCodeValidationState(BANK_CODE_VALIDATION_STATE.VALID)
            setFieldValue(bankCodeInputName, lookupMutation?.data?.bank_code)
        }
    }, [lookupMutation.isError, lookupMutation.isSuccess])

    useEffect(() => {
        setVerifiedBankCode(undefined)
        setBankCodeValidationState(BANK_CODE_VALIDATION_STATE.UNCHECKED)
    }, [accountNumber])

    useEffect(() => {
        if (verifiedBankCode && verifiedBankCode === bankCode) {
            setBankCodeValidationState(BANK_CODE_VALIDATION_STATE.VALID)
        } else {
            setBankCodeValidationState(BANK_CODE_VALIDATION_STATE.UNCHECKED)
        }
    }, [bankCode])

    return (
        <>
            <TextInput
                name={holderNameInputName}
                isDisabled={isDisabled}
                isRequired={isRequired}
                data-testid={holderNameInputName}
            />
            <TextInput
                name={accountNumberInputName}
                isDisabled={isDisabled}
                isRequired={isRequired}
                data-testid={accountNumberInputName}
            />
            <TextInput
                name={bankCodeInputName}
                validator={bankCodeValidator}
                isDisabled={isBankCodeInputReadOnly || isDisabled}
                isRequired={isRequired}
                labelRightElement={lookupButton}
                inputRightElement={
                    <If condition={bankCodeValidationState === BANK_CODE_VALIDATION_STATE.VALID}>
                        <SuccessIcon color="green.500" />
                    </If>
                }
                data-testid={bankCodeInputName}
            />
            <If condition={isCreditorIdentifierInputDisplayed}>
                <TextInput
                    name={creditorIdentifierInputName}
                    isDisabled={isDisabled}
                    isRequired={isRequired}
                    data-testid={creditorIdentifierInputName}
                />
            </If>
        </>
    )
}
