import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import { PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { ApiObjectTypeSchema, ConnectedAccount } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
    CONNECTED_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
    CONNECTED_ACCOUNT_DETAILS_ROWS
} from './ConnectedAccountDetails.page.const'
import { ConnectedAccountDetailsCustomRows } from './ConnectedAccountDetails.types'

export const ConnectedAccountDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdConnectedAccount(uuid)
    const isDataEmpty = useMemo(() => {
        return isEmpty(query?.data)
    }, [query.data])

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_CONNECTED_ACCOUNTS}>
                <QueryFallback
                    objectType={ApiObjectTypeSchema.enum.connected_account}
                    isLoading={query.isLoading}
                    isSuccess={query.isSuccess}
                    isError={query.isError || isDataEmpty}
                    isDataEmpty={isDataEmpty}>
                    <EntityDetail<ConnectedAccount, ConnectedAccountDetailsCustomRows>
                        data={query.data}
                        rows={CONNECTED_ACCOUNT_DETAILS_ROWS}
                        rowCustomizers={CONNECTED_ACCOUNT_DETAILS_ROW_CUSTOMIZERS}
                    />
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
