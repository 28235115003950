import { WidgetSeriesReservedLabels, WidgetTimeSeries } from '@/services'
import { BorderOptions, ChartData, type ChartOptions, GridLineOptions, TickOptions, TooltipItem } from 'chart.js'
import { chain, get } from 'lodash'
import { z } from 'zod'
import { IntlShape } from 'react-intl'
import { ChartTypeRegistry } from 'chart.js/dist/types'
import { COLON_SYMBOL, SPACE_SYMBOL } from '@/constants'
import { CHART_BASE_COLORS, CHART_DATASET_COLORS, CHART_HUE_SETTINGS, CHART_MAX_BAR_WIDTH } from './Chart.const'

export function formatTimeSeries<T>(data: WidgetTimeSeries<T>, intl?: IntlShape) {
    const YEAR_DATE_PATTERN = /^\d{4}$/
    const QUARTER_DATE_PATTERN = /^\d{4}-Q[1-4]$/
    const MONTH_DATE_PATTERN = /^\d{4}-\d{2}$/
    const WEEK_DATE_PATTERN = /^\d{4}-W\d{2}$/
    const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}$/

    const YearDateFormatSchema = z.string().regex(YEAR_DATE_PATTERN)
    const QuarterDateFormatSchema = z.string().regex(QUARTER_DATE_PATTERN)
    const MonthDateFormatSchema = z.string().regex(MONTH_DATE_PATTERN)
    const WeekDateFormatSchema = z.string().regex(WEEK_DATE_PATTERN)
    const DateFormatSchema = z.string().regex(DATE_PATTERN)

    function formatTimeSeriesDateMapper(value: string): string {
        const formatYear = (value: string) => value
        const formatQuarter = (value: string) => {
            const [year, quarter] = value.split('-Q')
            const parsedYear = globalThis.parseInt(year)
            const parsedQuarter = globalThis.parseInt(quarter)

            if (intl) {
                return intl?.formatMessage(
                    {
                        id: 'app.common.charts.time_series.labels.quarter'
                    },
                    {
                        year: parsedYear,
                        quarter: parsedQuarter
                    }
                )
            }

            return `${year} Q${quarter}`
        }
        const formatMonth = (value: string) => {
            const [year, month] = value.split('-')
            const parsedYear = globalThis.parseInt(year)
            const parsedMonth = globalThis.parseInt(month)
            const date = new Date(parsedYear, parsedMonth - 1)
            const dateOptions = { year: 'numeric', month: 'short' } as Intl.DateTimeFormatOptions

            if (intl) {
                return intl?.formatDate(date, dateOptions)
            }

            return date.toLocaleString('default', dateOptions)
        }
        const formatWeek = (value: string) => {
            const [year, week] = value.split('-W')
            const parsedYear = globalThis.parseInt(year)
            const parsedWeek = globalThis.parseInt(week)

            if (intl) {
                return intl?.formatMessage(
                    {
                        id: 'app.common.charts.time_series.labels.week'
                    },
                    {
                        year: parsedYear,
                        week: parsedWeek
                    }
                )
            }

            return `${year} Week ${parsedWeek}`
        }
        const formatFullDate = (fullDate: string) => {
            const [year, month, day] = fullDate.split('-')
            const parsedYear = globalThis.parseInt(year)
            const parsedMonth = globalThis.parseInt(month)
            const parsedDay = globalThis.parseInt(day)
            const date = new Date(parsedYear, parsedMonth - 1, parsedDay)
            const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' } as Intl.DateTimeFormatOptions

            if (intl) {
                return intl?.formatDate(date, dateOptions)
            }

            return date.toLocaleString('default', dateOptions)
        }

        switch (true) {
            case YearDateFormatSchema.safeParse(value).success: {
                return formatYear(value)
            }

            case QuarterDateFormatSchema.safeParse(value).success: {
                return formatQuarter(value)
            }

            case MonthDateFormatSchema.safeParse(value).success: {
                return formatMonth(value)
            }

            case WeekDateFormatSchema.safeParse(value).success: {
                return formatWeek(value)
            }

            case DateFormatSchema.safeParse(value).success: {
                return formatFullDate(value)
            }

            default: {
                return value
            }
        }
    }

    return chain(data).get('time_series', []).map(formatTimeSeriesDateMapper).value()
}

export function transformWidgetTimeSeriesToChartData<T>(
    data?: WidgetTimeSeries<T>,
    intl?: IntlShape,
    formatters?: Partial<{
        dataset(value: string): string
    }>,
    colorSet?: Record<string, string>
): ChartData<'bar'> {
    if (!data) {
        return {
            labels: [],
            datasets: []
        }
    }

    return {
        labels: formatTimeSeries<T>(data, intl),
        datasets: formatDataSeries<T>(data, formatters?.dataset, colorSet)
    }
}

export function formatDataSeries<T>(
    data: WidgetTimeSeries<T>,
    formatter?: (value: string) => string,
    colorSet?: Record<string, string>
) {
    return chain(data)
        .get('data_series', [])
        .map(({ label, data }, index) => {
            const formattedLabel = formatter?.(label as string) || (label as string)
            const backgroundColor = (() => {
                switch (label) {
                    case WidgetSeriesReservedLabels.Other: {
                        return CHART_BASE_COLORS.NUMERAL_GRAY
                    }

                    case WidgetSeriesReservedLabels.Empty: {
                        return CHART_BASE_COLORS.NUMERAL_LIGHT_GRAY
                    }

                    default: {
                        const backgroundColor = generateChartDatasetColorByIndex(index)

                        return get(colorSet, label as string, backgroundColor)
                    }
                }
            })()

            return {
                label: formattedLabel,
                data,
                backgroundColor,
                maxBarThickness: CHART_MAX_BAR_WIDTH
            }
        })
        .value()
}

/**
 * @description
 * - Used for consistent data reproduction  (during data transition)
 * - Spread hues between 240 and 300 (can be controlled)
 */
export function generateChartDatasetColorByIndex(index: number) {
    const baseColorsWrapper = chain(CHART_DATASET_COLORS)

    function calculateHue(index: number, start: number, end: number): number {
        return chain(index)
            .multiply(end - start)
            .add(start)
            .divide(CHART_HUE_SETTINGS.ARC)
            .value()
    }

    if (index > baseColorsWrapper.size().value()) {
        const hue = calculateHue(index, CHART_HUE_SETTINGS.START, CHART_HUE_SETTINGS.END)
        return hslToRgba(hue, CHART_HUE_SETTINGS.SATURATION, CHART_HUE_SETTINGS.LIGHTNESS, CHART_HUE_SETTINGS.ALPHA)
    }

    return baseColorsWrapper.values().get(index).value()
}
/**
 * @description
 * Used for random color generation.
 */
export function hslToRgba(hue: number, saturation: number, lightness: number, alpha: number): string {
    const maxRGB = 255
    let red: number
    let green: number
    let blue: number

    if (saturation === 0) {
        red = green = blue = lightness
    } else {
        const hue2rgb = (p: number, q: number, t: number): number => {
            if (t < 0) {
                t += 1
            }

            if (t > 1) {
                t -= 1
            }

            if (t < 1 / 6) {
                return p + (q - p) * 6 * t
            }

            if (t < 1 / 2) {
                return q
            }

            if (t < 2 / 3) {
                return p + (q - p) * (2 / 3 - t) * 6
            }

            return p
        }

        const q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation
        const p = 2 * lightness - q
        red = hue2rgb(p, q, hue + 1 / 3)
        green = hue2rgb(p, q, hue)
        blue = hue2rgb(p, q, hue - 1 / 3)
    }

    const roundedRed = Math.round(red * maxRGB)
    const roundedGreen = Math.round(green * maxRGB)
    const roundedBlue = Math.round(blue * maxRGB)

    return `rgba(${roundedRed}, ${roundedGreen}, ${roundedBlue}, ${alpha})`
}

export function getChartBarScalesColors(
    white: string,
    gray100: string,
    gray500: string
): Partial<ChartOptions<'bar'>['scales']> {
    const borderOptions: Partial<BorderOptions> = {
        color: white
    }

    const ticksOptions: Partial<TickOptions> = {
        color: gray500
    }

    const gridOptions: Partial<GridLineOptions> = {
        color: gray100
    }

    return {
        x: {
            border: borderOptions,
            ticks: ticksOptions
        },
        y: {
            border: borderOptions,
            grid: gridOptions,
            ticks: ticksOptions
        }
    }
}

/**
 * @todo
 * Add test
 */
export function getChartTooltipLabelFormatterForAxis(axis: 'x' | 'y', formatter: (value: number) => string) {
    return function chartTooltipFormatter(context: TooltipItem<keyof ChartTypeRegistry>): string | void {
        const contextWrapper = chain(context)
        let label = contextWrapper.get('dataset.label', '').value()
        const datasetPoint = contextWrapper.get(`parsed.${axis}`, 0).toNumber().value()

        if (label) {
            label += `${COLON_SYMBOL}${SPACE_SYMBOL}`
        }

        if (datasetPoint) {
            label += formatter?.(datasetPoint)
        }

        return label
    }
}
