import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import {
    addTransformRequestsToHttpClientInstance,
    bulkActionHiddenTrueTransform,
    bulkActionsIdsMapperTransform
} from '@/services/@transformRequests'
import { MutationOptions, useMutation } from '@tanstack/react-query'
import { BulkAction, BulkActionCreateData } from '@webapps/numeral-ui-core'

export function useMutationCreateBulkAction<T>(options?: MutationOptions<BulkAction, Error, BulkActionCreateData<T>>) {
    const { selectedUserAccess } = useAuth()
    const { bulkActionsService } = useServices()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<BulkAction, Error, BulkActionCreateData<T>>({
        ...options,
        mutationKey: [bulkActionsService.url],
        mutationFn(data) {
            return bulkActionsService.createBulkAction<T>(data, {
                params: paramsWithUserAccess,
                transformRequest: addTransformRequestsToHttpClientInstance(
                    bulkActionsService,
                    bulkActionsIdsMapperTransform<T>,
                    bulkActionHiddenTrueTransform<T>
                )
            })
        }
    })
}
