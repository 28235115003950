import {
    computePaymentOrderCurrency,
    computePaymentPropertiesByServiceName,
    createAccountFromConnectedAccount,
    generatePaymentOrderDefaultDirectDebitMandate,
    isFinancialInstitutionConnectedAccount,
    isSDDPayment
} from '@/services'
import { Account, ConnectedAccount, PaymentOrder, ServiceName } from '@webapps/numeral-ui-core'
import { chain } from 'lodash'

export function computePartialPaymentOrderFormFields(account?: ConnectedAccount): Partial<PaymentOrder> {
    const item: Partial<PaymentOrder> = Object.create(null)
    const serviceName = chain(account).get('services_activated').head().value() as ServiceName
    const { type, direction } = computePaymentPropertiesByServiceName(serviceName)
    const currency = computePaymentOrderCurrency(type)
    const directDebitMandate = generatePaymentOrderDefaultDirectDebitMandate(type, direction)
    const hasSDDPaymentType = isSDDPayment(type, direction)
    const hasFinancialInstitutionConnectedAccount = isFinancialInstitutionConnectedAccount(account)
    const originatingAccount = hasFinancialInstitutionConnectedAccount
        ? createAccountFromConnectedAccount(account, hasSDDPaymentType)
        : undefined

    item.type = type
    item.direction = direction
    item.currency = currency
    item.connected_account_id = account?.id
    item.direct_debit_mandate = directDebitMandate

    item.originating_account = originatingAccount as Account
    return item
}

export function getPaymentOrderFormInitialValues(): Partial<PaymentOrder> {
    const item: Partial<PaymentOrder> = Object.create(null)

    item.connected_account_id = undefined
    item.type = undefined
    item.direction = undefined
    item.currency = undefined
    item.direct_debit_mandate = undefined
    item.originating_account_id = undefined
    item.originating_account = undefined
    item.receiving_account_id = undefined
    item.receiving_account = undefined
    item.amount = undefined
    item.reference = undefined
    item.auto_approval = false

    // Optionals
    item.fee_option = undefined
    item.treasury_option = undefined
    item.confidentiality_option = undefined

    return item
}
