import { PERMISSION, WidgetsServiceFindAllQueryOptions } from '@/services'
import { useIntl } from 'react-intl'
import { If } from '@/components/@misc'
import { Text } from '@chakra-ui/react'
import { memo, useMemo } from 'react'
import { Widget, WidgetQueryFallback, WidgetVariants } from '@/components'
import { usePermissions, useQueryProcessedPaymentsTotalAmounts } from '@/hooks'
import { isEmpty } from 'lodash'
import { NEW_LINE_SYMBOL } from '@/constants'
import { getFormatTotalValuePaymentsProcessedWithIntl } from './TotalValuePaymentsProcessedWidget.utils'

export const TotalValuePaymentsProcessedWidget: React.FC<WidgetsServiceFindAllQueryOptions> = memo((props) => {
    const { hasPermission } = usePermissions()
    const intl = useIntl()
    const query = useQueryProcessedPaymentsTotalAmounts(props)
    const title = useMemo(
        () =>
            intl.formatMessage({
                id: 'app.widgets.total_value_payments_processed.title'
            }),
        [intl]
    )
    const isDataEmpty = useMemo(() => isEmpty(query.data?.records), [query])
    const hasViewProcessedPaymentsTotalAmountPermission = useMemo(() => {
        return hasPermission(PERMISSION.HOME_VIEW_PROCESSED_PAYMENTS_TOTAL_AMOUNTS)
    }, [hasPermission])
    const formatter = useMemo(() => {
        return getFormatTotalValuePaymentsProcessedWithIntl(intl)
    }, [intl])
    const formattedValue = useMemo(() => formatter(query.data), [query.data])
    const formattedTooltip = useMemo(() => formatter(query.data, NEW_LINE_SYMBOL), [query.data])

    return (
        <If condition={hasViewProcessedPaymentsTotalAmountPermission}>
            <Widget title={title} variant={WidgetVariants.Lite}>
                <WidgetQueryFallback
                    isLoading={query.isLoading}
                    isDataEmpty={isDataEmpty}
                    variant={WidgetVariants.Lite}>
                    <Text
                        noOfLines={1}
                        wordBreak="break-all"
                        fontSize="24px"
                        aria-label={formattedValue}
                        title={formattedTooltip}>
                        {formattedValue}
                    </Text>
                </WidgetQueryFallback>
            </Widget>
        </If>
    )
})
