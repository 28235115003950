import { EntityDetail, QueryFallback, RestrictedSection } from '@/components'
import { useQueryFindAllPaymentOrders, useQueryFindByIdPaymentOrder, useQueryFindByIdPaymentRetryRule } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE } from '@/services'
import { queryDataAggregation } from '@/utils'
import { Box, Text } from '@chakra-ui/react'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
    NON_ORIGINAL_PAYMENT_RETRY_SUMMARY_DETAIL_ROWS,
    ORIGINAL_PAYMENT_RETRY_SUMMARY_DETAIL_ROWS,
    PAYMENT_RETRY_SUMMARY_DETAIL_ROW_CUSTOMIZERS
} from './PaymentOrderRetries.page.const'
import { RetriedPaymentSummary } from './PaymentOrderRetries.page.type'
import {
    getOriginalPaymentOrderId,
    getPaymentOrderRetriesSummary,
    getPaymentRetrySummaryTitle
} from './PaymentOrderRetries.page.utils'

export const PaymentOrderRetriesPage: React.FC = () => {
    const { uuid } = useParams()

    const queryCurrentPaymentOrder = useQueryFindByIdPaymentOrder(uuid)
    const originalPaymentOrderId = getOriginalPaymentOrderId(queryCurrentPaymentOrder.data)

    const queryOriginalPaymentOrder = useQueryFindByIdPaymentOrder(originalPaymentOrderId, {
        enabled: !!originalPaymentOrderId
    })
    const queryNonOriginalPaymentOrders = useQueryFindAllPaymentOrders(
        { 'retry_details.original_payment_id': originalPaymentOrderId },
        { enabled: !!originalPaymentOrderId }
    )

    const nonOriginalPaymentOrders = useMemo<PaymentOrder[]>(() => {
        return queryDataAggregation<PaymentOrder>(queryNonOriginalPaymentOrders.data)
    }, [queryNonOriginalPaymentOrders])

    const queryPaymentRetryRule = useMemo(() => {
        const retryRuleId = queryCurrentPaymentOrder.data?.retry_details?.payment_retry_rule_id
        return useQueryFindByIdPaymentRetryRule(retryRuleId, {
            enabled: !!retryRuleId
        })
    }, [queryCurrentPaymentOrder])

    const paymentRetriesSummary = useMemo(() => {
        return getPaymentOrderRetriesSummary(
            queryOriginalPaymentOrder.data,
            nonOriginalPaymentOrders,
            queryPaymentRetryRule.data
        )
    }, [nonOriginalPaymentOrders, queryPaymentRetryRule])

    const hasQueryLoading =
        queryCurrentPaymentOrder.isLoading ||
        queryOriginalPaymentOrder.isLoading ||
        queryNonOriginalPaymentOrders.isLoading ||
        queryPaymentRetryRule.isLoading

    const hasQueryError =
        queryCurrentPaymentOrder.isError ||
        queryOriginalPaymentOrder.isError ||
        queryNonOriginalPaymentOrders.isError ||
        queryPaymentRetryRule.isError

    return (
        <PageLayout>
            <RestrictedSection feature={ENTITY_FEATURE.PAYMENT_RETRY_RULE}>
                <QueryFallback
                    objectType={ApiObjectTypeExtendedSchema.enum.payment_order}
                    isLoading={hasQueryLoading}
                    isSuccess={!hasQueryLoading && !hasQueryError}
                    isError={hasQueryError}
                    isDataEmpty={!paymentRetriesSummary || !paymentRetriesSummary.length}
                    customEmptyStateTitleKey="app.payments.payment_orders.tabs.retries.empty_state.title"
                    customEmptyStateDescriptionKey="app.payments.payment_orders.tabs.retries.empty_state.description">
                    {paymentRetriesSummary.map((paymentRetrySummary, index) => {
                        return (
                            <Box key={index}>
                                <Text margin="8px 0 18px 0">
                                    {getPaymentRetrySummaryTitle(
                                        paymentRetrySummary,
                                        index,
                                        originalPaymentOrderId,
                                        paymentRetrySummary.retried_payment_id === uuid
                                    )}
                                </Text>
                                <EntityDetail<RetriedPaymentSummary>
                                    data={paymentRetrySummary}
                                    rows={
                                        paymentRetrySummary.retried_payment_id === originalPaymentOrderId
                                            ? ORIGINAL_PAYMENT_RETRY_SUMMARY_DETAIL_ROWS
                                            : NON_ORIGINAL_PAYMENT_RETRY_SUMMARY_DETAIL_ROWS
                                    }
                                    rowCustomizers={PAYMENT_RETRY_SUMMARY_DETAIL_ROW_CUSTOMIZERS}
                                />
                            </Box>
                        )
                    })}
                </QueryFallback>
            </RestrictedSection>
        </PageLayout>
    )
}
