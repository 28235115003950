import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { MutationOptions, useMutation } from '@tanstack/react-query'
import { ExpectedPaymentsServiceFindAllQueryOptions, ExportPayload } from '@webapps/numeral-ui-core'

export function useMutationExportExpectedPayments(
    options?: MutationOptions<void, Error, ExportPayload<ExpectedPaymentsServiceFindAllQueryOptions>>
) {
    const { selectedUserAccess } = useAuth()
    const { expectedPaymentsService } = useServices()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<void, Error, ExportPayload<ExpectedPaymentsServiceFindAllQueryOptions>>({
        ...options,
        mutationKey: [expectedPaymentsService.url],
        mutationFn(data) {
            return expectedPaymentsService.export(data, {
                params: paramsWithUserAccess
            })
        }
    })
}
