import { QueryFallback, Table, useTableRowNavigation } from '@/components'
import { useNavigationRoutes } from '@/hooks'
import { queryDataAggregation } from '@/utils'
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import { ApiObjectTypeSchema, Balance, List } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { BALANCE_TABLE_COLUMN_CUSTOMIZERS, BALANCE_TABLE_STATIC_STATE } from '../Balances.page.const'

interface BalancesTableProps {
    queryItem: UseInfiniteQueryResult<InfiniteData<List<Balance>>>
    isFiltering?: boolean
    isSearching?: boolean
}

export const BalancesTable: React.FC<BalancesTableProps> = ({ queryItem, isFiltering, isSearching }) => {
    const { relativePaths } = useNavigationRoutes()
    const data = useMemo(() => queryDataAggregation(queryItem.data), [queryItem])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const onTableRowClick = useTableRowNavigation<Balance>('id', relativePaths.ACCOUNTS.DETAILS)

    return (
        <QueryFallback
            objectType={ApiObjectTypeSchema.enum.balance}
            isLoading={queryItem.isLoading}
            isSuccess={queryItem.isSuccess}
            isError={queryItem.isError}
            isDataEmpty={isDataEmpty}
            isFilteringOrSearching={isSearching || isFiltering}>
            <Table<Balance>
                data={data}
                onRowClick={onTableRowClick}
                onScrollToBottom={queryItem.fetchNextPage}
                isLoading={queryItem.hasNextPage}
                state={BALANCE_TABLE_STATIC_STATE}
                columnCustomizers={BALANCE_TABLE_COLUMN_CUSTOMIZERS}
            />
        </QueryFallback>
    )
}
