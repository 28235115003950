import { useActivatedFeatures, useAuth, useServices } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessAPIQueryParams } from '@/services'
import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { List, PaymentRetryRule, PaymentRetryRulesServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export function useQueryFindAllPaymentRetryRules(
    params?: Partial<PaymentRetryRulesServiceFindAllQueryOptions>,
    options?: Partial<UndefinedInitialDataOptions<List<PaymentRetryRule>, Error>>
) {
    const { paymentRetryRulesService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { isFeatureActivated } = useActivatedFeatures()

    const isQueryEnabled = isUserLoggedIn && options?.enabled && isFeatureActivated(ENTITY_FEATURE.PAYMENT_RETRY_RULE)

    const paramsWithUserAccess: typeof params = {
        ...params,
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useQuery<List<PaymentRetryRule>, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [paymentRetryRulesService.url, paramsWithUserAccess],
        queryFn({ signal }) {
            return paymentRetryRulesService.findAll({ ...paramsWithUserAccess }, { signal })
        }
    })
}
