import { FormSection } from '@/components'
import {
    DateInput,
    DirectDebitMandateSequenceInput,
    DirectDebitMandatesInput,
    DirectDebitMandateTypeInput,
    TextInput
} from '@/components/@inputs'
import { If } from '@/components/@misc'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import { getPaymentOrderDirectDebitMandateSectionAPIQueryParams } from '@/pages/Payments/PaymentOrders/PaymentOrderCreate/@components/PaymentOrderCreateForm/@components/PaymentOrderDirectDebitMandateSection/PaymentOrderDirectDebitMandateSection.utils'
import { isCorporateConnectedAccount } from '@/services'
import { Fade, Switch } from '@chakra-ui/react'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PAYMENT_ORDER_MANDATE_REFERENCE_MAX_LENGTH } from '../../PaymentOrderCreate.form.const'
import { PaymentOrderSEPACreditorIdentifierInput } from '../PaymentOrderSEPACreditorIdentifierInput'
import { PaymentOrderDirectDebitMandateSectionAutoReset } from './PaymentOrderDirectDebitMandateSectionAutoReset'
import { PaymentOrderDirectDebitMandateSectionDetails } from './PaymentOrderDirectDebitMandateSectionDetails'

export interface PaymentOrderDirectDebitMandateSectionProps {
    isDisabled?: boolean
}

export const PaymentOrderDirectDebitMandateSection: React.FC<PaymentOrderDirectDebitMandateSectionProps> = ({
    isDisabled
}) => {
    const { getFieldProps } = useFormikContext<PaymentOrder>()
    const { value: connectedAccountId } = getFieldProps('connected_account_id')
    const { value: directDebitMandateId } = getFieldProps('direct_debit_mandate_id')
    const { value: receivingAccountId } = getFieldProps('receiving_account_id')
    const { value: type } = getFieldProps('type')

    const hasConnectedAccountId = useMemo(() => globalThis.Boolean(connectedAccountId), [connectedAccountId])
    const hasReceivingAccountId = useMemo(() => globalThis.Boolean(receivingAccountId), [receivingAccountId])

    const connectedAccountQuery = useQueryFindByIdConnectedAccount(connectedAccountId, {
        enabled: hasConnectedAccountId
    })
    const hasCorporateConnectedAccount = useMemo<boolean>(
        () => isCorporateConnectedAccount(connectedAccountQuery.data),
        [connectedAccountQuery]
    )
    const [useExistingDirectDebitMandate, setUseExistingDirectDebitMandate] = useState(!hasReceivingAccountId)
    const onToggleUseExistingDirectDebitMandate = useCallback(() => {
        setUseExistingDirectDebitMandate((prevState) => {
            return !prevState
        })
    }, [])

    const APIQueryParams = useMemo(() => {
        return getPaymentOrderDirectDebitMandateSectionAPIQueryParams(connectedAccountId, receivingAccountId, type)
    }, [connectedAccountId, receivingAccountId, type])

    useEffect(() => {
        if (hasReceivingAccountId) {
            setUseExistingDirectDebitMandate(false)
        }
    }, [hasReceivingAccountId])

    return (
        <FormSection
            titleId="app.payments.payment_orders.create.form.sections.direct_debit_mandate.title"
            title={
                <Switch
                    data-testid="toggle-existing-direct-debit-mandate-switch"
                    size="sm"
                    isChecked={useExistingDirectDebitMandate}
                    onChange={onToggleUseExistingDirectDebitMandate}
                    disabled={isDisabled || hasReceivingAccountId}>
                    <FormattedMessage id="app.payments.payment_orders.create.form.sections.direct_debit_mandate.title.alternative" />
                </Switch>
            }>
            <Fade in={hasCorporateConnectedAccount} unmountOnExit={true}>
                <PaymentOrderSEPACreditorIdentifierInput value={connectedAccountId} isDisabled={isDisabled} />
            </Fade>
            <If condition={useExistingDirectDebitMandate}>
                <DirectDebitMandatesInput
                    name="direct_debit_mandate_id"
                    value={directDebitMandateId}
                    isPlaceholderDisplayed={true}
                    customPlaceholderKey="app.payments.payment_orders.create.form.inputs.direct_debit_mandate_id.placeholder"
                    isDisabled={isDisabled}
                    isRequired={true}
                    APIQueryParams={APIQueryParams}
                />
                <PaymentOrderDirectDebitMandateSectionDetails id={directDebitMandateId} />
            </If>
            <If condition={!useExistingDirectDebitMandate}>
                <DirectDebitMandateTypeInput
                    name="direct_debit_mandate.type"
                    isDisabled={isDisabled}
                    isRequired={true}
                    isPlaceholderDisplayed={false}
                />
                <DirectDebitMandateSequenceInput
                    name="direct_debit_mandate.sequence"
                    isDisabled={isDisabled}
                    isRequired={true}
                    isPlaceholderDisplayed={false}
                />
                <TextInput
                    name="direct_debit_mandate.reference"
                    isDisabled={isDisabled}
                    maxLength={PAYMENT_ORDER_MANDATE_REFERENCE_MAX_LENGTH}
                />
                <DateInput name="direct_debit_mandate.signature_date" isDisabled={isDisabled} isRequired={true} />
            </If>
            <PaymentOrderDirectDebitMandateSectionAutoReset
                useExistingDirectDebitMandate={useExistingDirectDebitMandate}
            />
        </FormSection>
    )
}
