import { isDefaultRole, Role } from '@/services'
import { memo, useMemo } from 'react'
import { Tag, Text } from '@chakra-ui/react'
import { EMPTY_VALUE_PLACEHOLDER, SPACE_SYMBOL } from '@/constants'
import { FormattedMessage } from 'react-intl'

interface RoleNameProps {
    role?: Role
}

export const RoleName: React.FC<RoleNameProps> = ({ role }) => {
    const hasDefaultRole = useMemo(() => isDefaultRole(role), [role])

    switch (true) {
        case !role?.name: {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        case hasDefaultRole: {
            return <>{role?.name}</>
        }

        default: {
            return (
                <Text lineHeight="1.5">
                    {role?.name}
                    {SPACE_SYMBOL}
                    <Tag variant="subtle" backgroundColor="gray.100">
                        <FormattedMessage id="app.settings.roles.form.type.custom.label" defaultMessage="Custom" />
                    </Tag>
                </Text>
            )
        }
    }
}
