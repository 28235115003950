import { FilterIcon } from '@/components/@icons'
import { APIQueryParamStateFilterBy } from '@/hooks'
import { Button, ButtonProps, Fade, Spinner, Tag, TagLabel } from '@chakra-ui/react'
import { noop } from 'lodash'
import { ReactElement, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { countActiveFiltersValues } from './TableHeaderFilterBy.utils'

interface TableHeaderFilterByButtonProps<T> {
    value?: APIQueryParamStateFilterBy<T>
    isVisible?: boolean
    isLoading?: boolean
    onToggle?(): void
}

export function TableHeaderFilterByButton<T>({
    onToggle = noop,
    value,
    isVisible,
    isLoading
}: TableHeaderFilterByButtonProps<T>) {
    const intl = useIntl()
    const activeItems = useMemo(() => {
        return countActiveFiltersValues<T>(value)
    }, [value])
    const isActive = useMemo(() => {
        return globalThis.Boolean(activeItems)
    }, [activeItems])
    const toggleFilterButtonLabel = useMemo<string>(() => {
        switch (true) {
            case isActive: {
                return intl.formatMessage({ id: 'app.table.header.filters.actions.toggle_on_with_values.label' })
            }

            case isVisible && !isActive: {
                return intl.formatMessage({ id: 'app.table.header.filters.actions.toggle_off.label' })
            }

            default: {
                return intl.formatMessage({ id: 'app.table.header.filters.actions.toggle_on.label' })
            }
        }
    }, [isActive, isVisible, intl])

    const iconNumberOfActiveItems = useMemo<ReactElement | undefined>(() => {
        if (activeItems === 0) {
            return
        }

        return (
            <Fade in={true}>
                <Tag backgroundColor="numeralAccent.500" color="white" borderRadius="full">
                    <TagLabel>{activeItems}</TagLabel>
                </Tag>
            </Fade>
        )
    }, [activeItems])

    const toggleButtonProps = useMemo<ButtonProps>(() => {
        const showAlternativeColor = activeItems > 0
        const color = showAlternativeColor ? undefined : 'gray.700'
        const leftIcon = isVisible && isLoading ? <Spinner color={color} size="sm" /> : <FilterIcon color={color} />

        return {
            colorScheme: showAlternativeColor ? undefined : 'gray',
            variant: showAlternativeColor ? 'solidAlternative' : 'outline',
            color,
            leftIcon
        }
    }, [activeItems, isLoading, isVisible])

    return (
        <Button
            {...toggleButtonProps}
            paddingX="16px"
            minWidth="min-content"
            onClick={onToggle}
            rightIcon={iconNumberOfActiveItems}
            aria-label={toggleFilterButtonLabel}>
            {toggleFilterButtonLabel}
        </Button>
    )
}
