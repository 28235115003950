import { EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { FormattedDateTime } from '@/components/@misc'
import { ExternalAccount, ExternalAccountVerificationServiceSchema } from '@webapps/numeral-ui-core'
import { ExternalAccountVerificationResultDetails } from './@components'
import { ExternalAccountVerificationCustomRows } from './ExternalAccountVerifications.types'

export const EXTERNAL_ACCOUNT_VERIFICATIONS_ROWS: EntityDetailRow<
    ExternalAccount,
    ExternalAccountVerificationCustomRows
>[] = [
    ExternalAccountVerificationServiceSchema.enum.active_account,
    ExternalAccountVerificationServiceSchema.enum.valid_account_number,
    ExternalAccountVerificationServiceSchema.enum.matching_account_number_and_bank_code,
    ExternalAccountVerificationServiceSchema.enum.matching_account_holder,
    'account_verification_created_at'
]

export const EXTERNAL_ACCOUNT_VERIFICATIONS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    ExternalAccount,
    ExternalAccountVerificationCustomRows
> = {
    [ExternalAccountVerificationServiceSchema.enum.active_account]: {
        cell(item) {
            return (
                <ExternalAccountVerificationResultDetails
                    verificationService={ExternalAccountVerificationServiceSchema.enum.active_account}
                    externalAccount={item}
                />
            )
        }
    },
    [ExternalAccountVerificationServiceSchema.enum.valid_account_number]: {
        cell(item) {
            return (
                <ExternalAccountVerificationResultDetails
                    verificationService={ExternalAccountVerificationServiceSchema.enum.valid_account_number}
                    externalAccount={item}
                />
            )
        }
    },
    [ExternalAccountVerificationServiceSchema.enum.matching_account_number_and_bank_code]: {
        cell(item) {
            return (
                <ExternalAccountVerificationResultDetails
                    verificationService={
                        ExternalAccountVerificationServiceSchema.enum.matching_account_number_and_bank_code
                    }
                    externalAccount={item}
                />
            )
        }
    },
    [ExternalAccountVerificationServiceSchema.enum.matching_account_holder]: {
        cell(item) {
            return (
                <ExternalAccountVerificationResultDetails
                    verificationService={ExternalAccountVerificationServiceSchema.enum.matching_account_holder}
                    externalAccount={item}
                />
            )
        }
    },
    account_verification_created_at: {
        cell(item) {
            return <FormattedDateTime value={item.account_verification?.created_at} />
        }
    }
}
