import { useAuth, useServices } from '@/hooks'
import { filterUserAccessAPIQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { ExpectedPayment, Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationCancelByIdExpectedPayment(id?: Uuid, options?: MutationOptions<ExpectedPayment, Error>) {
    const { selectedUserAccess } = useAuth()
    const { expectedPaymentsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useMutation<ExpectedPayment, Error>({
        ...options,
        mutationKey: [expectedPaymentsService.url, { id }],
        mutationFn(data) {
            return expectedPaymentsService.cancelExpectedPayment(id as string, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            queryClient.setQueryData([expectedPaymentsService.url, { id }], args[0])

            await queryClient
                .invalidateQueries({
                    queryKey: [expectedPaymentsService.url]
                })
                .catch(noop)
        }
    })
}
