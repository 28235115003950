import { If, Select, useSearchQuerySelect } from '@/components/@misc'
import { anythingValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { useField } from 'formik'
import { chain } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { COUNTRIES } from './CountryInput.const'
import { CountriesInputProps, CountryOption } from './CountryInput.types'
import { getCountryOptionWithIntlMapper } from './CountryInput.utils'

export const CountryInput: React.FC<CountriesInputProps> = ({
    name,
    value,
    searchOnMount = false,
    customCountryCodes,
    isRequired,
    isDisabled = false,
    customLabelKey,
    customLabelValues,
    customPlaceholderKey,
    isPlaceholderDisplayed = false,
    isLabelDisplayed = true,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField({
        name,
        value,
        validate: validator?.(intl, isRequired)
    })
    const { onFocus } = useSearchQuerySelect(searchOnMount)

    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.${name}.label`
        return intl.formatMessage({ id: labelKey }, customLabelValues)
    }, [name, customLabelKey, intl, customLabelValues])
    const placeholder = useMemo<string | undefined>(() => {
        if (!isPlaceholderDisplayed) {
            return
        }
        const messageKey = customPlaceholderKey ?? 'app.common.form.input.select.placeholder'
        return intl.formatMessage({ id: messageKey })
    }, [intl, customPlaceholderKey, isPlaceholderDisplayed])

    const countriesWithFormattedRegionName = useMemo<CountryOption[]>(() => {
        const optionMapper = getCountryOptionWithIntlMapper(intl)
        return chain(COUNTRIES).map(optionMapper).sortBy('name').value()
    }, [intl])

    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <If condition={isLabelDisplayed}>
                <FormLabel htmlFor={name}>
                    <FormattedMessage id={label} />
                </FormLabel>
            </If>
            <Select<CountryOption>
                {...inputProps}
                {...field}
                id={name}
                options={countriesWithFormattedRegionName}
                placeholder={placeholder}
                isInvalid={isInvalid}
                isRequired={isRequired}
                isDisabled={isDisabled}
                onChange={helpers.setValue}
                onFocus={onFocus}
                isClearable={true}
                getOptionLabel={(item) => item.name}
                getOptionValue={(item) => item.code}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
