import { Icon, IconProps } from '@chakra-ui/react'

export const AccountsIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M5.2498 17V9.82502H7.1248V17H5.2498ZM11.0748 17V9.82502H12.9498V17H11.0748ZM2.2998 20.75V18.875H21.6998V20.75H2.2998ZM16.8748 17V9.82502H18.7498V17H16.8748ZM2.2998 7.95002V6.10002L11.9998 1.27502L21.6998 6.10002V7.95002H2.2998ZM6.4998 6.07502H17.4998L11.9998 3.37502L6.4998 6.07502Z"
        />
    </Icon>
)
