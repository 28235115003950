import { noop } from 'lodash'
import { Form, FormPageFooter, FormPageFooterProps } from '@/components'
import { hasNonAdminPermissionFilter, PERMISSION, PERMISSIONS_GROUPS, Role } from '@/services'
import { useIntl } from 'react-intl'
import { If } from '@/components/@misc'
import { GroupAccordionInput, TextInput } from '@/components/@inputs'
import { usePermissions } from '@/hooks'
import { RoleAssignedUsers } from '../'
import { useMemo, useRef } from 'react'
import { EMPTY_CHAR_SYMBOL } from '@/constants'

interface RoleFormProps extends Pick<FormPageFooterProps, 'submitLabelMessageId'> {
    data?: Role
    loading?: boolean
    disabled?: boolean
    readonly?: boolean

    onSubmit?(data: Role): void
}

export const RoleForm: React.FC<RoleFormProps> = ({
    data,
    disabled,
    loading,
    readonly,
    submitLabelMessageId,
    onSubmit = noop
}) => {
    const intl = useIntl()
    const isEditMode = useRef(globalThis.Boolean(data?.id)).current
    const { hasPermission } = usePermissions()
    const initialValues = useMemo<Role>(() => {
        return Object.assign({ name: EMPTY_CHAR_SYMBOL, description: EMPTY_CHAR_SYMBOL, permissions: [] }, data)
    }, [data])
    const showUsersAssignToRole = hasPermission(PERMISSION.SETTINGS_VIEW_USERS)

    const availablePermissions = useMemo(() => Object.values(PERMISSION), [])
    const availablePermissionsGroups = useMemo(() => Object.values(PERMISSIONS_GROUPS), [])
    const getGroupLabel = (group: string) => {
        return intl.formatMessage({
            id: `app.settings.roles.form.permissions.groups.${group}`,
            defaultMessage: group
        })
    }
    const getGroupOptionLabel = (group: string, value: string, selected: number, available: number) => {
        return intl.formatMessage({
            id: `app.settings.roles.form.permissions.${group}`,
            defaultMessage: value
        })
    }
    const getGroupCounterLabel = (group: string, selected: number, available: number) => {
        return intl.formatMessage(
            {
                id: 'app.settings.roles.form.permissions.group_number_of_permissions.label',
                defaultMessage: group
            },
            {
                selected,
                available
            }
        )
    }

    return (
        <Form<Role> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, dirty }) => {
                return (
                    <>
                        <TextInput
                            name="name"
                            customPlaceholderKey="app.settings.roles.form.name.placeholder"
                            value={values?.name}
                            isDisabled={disabled}
                            isLoading={loading}
                            isReadOnly={readonly}
                            isRequired={true}
                        />
                        <TextInput
                            name="description"
                            customPlaceholderKey="app.settings.roles.form.description.placeholder"
                            value={values?.description}
                            isDisabled={disabled}
                            isLoading={loading}
                            isReadOnly={readonly}
                            isRequired={isEditMode}
                        />
                        <GroupAccordionInput<PERMISSION>
                            name="permissions"
                            value={values.permissions}
                            options={availablePermissions}
                            groupOrder={availablePermissionsGroups}
                            getGroupLabel={getGroupLabel}
                            getGroupOptionLabel={getGroupOptionLabel}
                            getGroupCounterLabel={getGroupCounterLabel}
                            optionsFilter={hasNonAdminPermissionFilter}
                            isDisabled={disabled}
                            isReadOnly={readonly}
                            isRequired={true}
                        />
                        <If condition={isEditMode && showUsersAssignToRole}>
                            <RoleAssignedUsers roleId={data?.id} />
                        </If>
                        <If condition={!readonly}>
                            <FormPageFooter
                                onSubmit={handleSubmit}
                                isDisabled={disabled || !dirty}
                                submitLabelMessageId={submitLabelMessageId}
                                isLoading={loading}
                                showUnsavedChangesWarning={true}
                                margin="unset"
                            />
                        </If>
                    </>
                )
            }}
        </Form>
    )
}
