import { ExternalAccountDetailsSummary, FormSection } from '@/components'
import { ExternalAccountsInput, TextInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { approvedExternalAccountsFilter } from '@/services'
import { Switch } from '@chakra-ui/react'
import { ExpectedPayment } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

export interface ExpectedPaymentExternalAccountSectionProps {
    isDisabled?: boolean
}

export const ExpectedPaymentExternalAccountSection: React.FC<ExpectedPaymentExternalAccountSectionProps> = ({
    isDisabled
}) => {
    const { getFieldProps, setFieldValue } = useFormikContext<ExpectedPayment>()
    const { value: receivingAccountId } = getFieldProps('external_account_id')
    const { value: externalAccountHolderName } = getFieldProps('external_account.holder_name')
    const { value: externalAccountNumber } = getFieldProps('external_account.account_number')

    const [useExistingExternalAccount, setUseExistingExternalAccount] = useState(false)

    const onToggleUseExistingExternalAccount = useCallback(() => {
        setFieldValue('external_account_id', undefined)
        setFieldValue('external_account', undefined)

        setUseExistingExternalAccount((prevState) => {
            return !prevState
        })
    }, [])

    const areExternalAccountInfoMandatory = useMemo(() => {
        // if either of the field is filled, they both become mandatory
        return !isEmpty(externalAccountHolderName) || !isEmpty(externalAccountNumber)
    }, [externalAccountHolderName, externalAccountNumber])

    return (
        <FormSection
            titleId="app.payments.expected_payment.create.form.sections.external_account.title"
            title={
                <Switch
                    data-testid="toggle-existing-receiving-account-switch"
                    size="sm"
                    isChecked={useExistingExternalAccount}
                    onChange={onToggleUseExistingExternalAccount}
                    disabled={isDisabled}>
                    <FormattedMessage id="app.payments.expected_payment.create.form.sections.external_account.use_existing.label" />
                </Switch>
            }>
            <If condition={useExistingExternalAccount}>
                <ExternalAccountsInput
                    name="external_account_id"
                    value={receivingAccountId}
                    isPlaceholderDisplayed={true}
                    isAccountVerificationStatusDisplayed={true}
                    customPlaceholderKey="app.payments.expected_payment.create.form.inputs.external_account_id.placeholder"
                    queryDataFilter={approvedExternalAccountsFilter}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <ExternalAccountDetailsSummary externalAccountId={receivingAccountId} />
            </If>
            <If condition={!useExistingExternalAccount}>
                <TextInput
                    name="external_account.holder_name"
                    isDisabled={isDisabled}
                    isRequired={areExternalAccountInfoMandatory}
                />
                <TextInput
                    name="external_account.account_number"
                    isDisabled={isDisabled}
                    isRequired={areExternalAccountInfoMandatory}
                />
            </If>
        </FormSection>
    )
}
