import { useActivatedFeatures, useAuth, useConfiguration, usePermissions, useServices } from '@/hooks'
import { ENTITY_FEATURE, PERMISSION, filterUserAccessAPIQueryParams } from '@/services'
import { UndefinedInitialDataInfiniteOptions, useInfiniteQuery } from '@tanstack/react-query'
import { List, PaymentFile, PaymentFilesServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { paginationQueryOptions } from '../paginationQueryOptions.const'

export function useQueryFindAllPaymentFiles(
    params?: Partial<PaymentFilesServiceFindAllQueryOptions>,
    options?: Partial<UndefinedInitialDataInfiniteOptions<List<PaymentFile>, Error>>
) {
    const { paymentFilesService } = useServices()
    const { pagination } = useConfiguration()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isUserLoggedIn &&
        options?.enabled &&
        isFeatureActivated(ENTITY_FEATURE.PAYMENT_ORDER) &&
        hasPermission(PERMISSION.FILES_VIEW_FILES)

    const paramsWithUserAccess: typeof params = {
        limit: pagination.limit,
        ...params,
        ...filterUserAccessAPIQueryParams(selectedUserAccess)
    }

    return useInfiniteQuery<List<PaymentFile>, Error>({
        ...options,
        enabled: isQueryEnabled,
        initialPageParam: undefined,
        queryKey: [paymentFilesService.url, paramsWithUserAccess],
        queryFn({ signal, pageParam }) {
            return paymentFilesService.findAll({ ...paramsWithUserAccess, ...(pageParam as typeof params) }, { signal })
        },
        getNextPageParam(lastPage) {
            return paginationQueryOptions.getNextPageParam(lastPage, pagination.limit)
        }
    })
}
