import { Icon, IconProps } from '@chakra-ui/react'

export const PaymentsIcon: React.FC<IconProps> = (props) => (
    <Icon height="16px" width="16px" viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.1125 20.75L2.25 15.8875L7.1125 11.025L8.4375 12.35L5.8375 14.95H20.75V16.825H5.8375L8.4375 19.425L7.1125 20.75ZM16.8875 12.975L15.5625 11.65L18.1625 9.05H3.25V7.175H18.1625L15.5625 4.575L16.8875 3.25L21.75 8.1125L16.8875 12.975Z"
        />
    </Icon>
)
