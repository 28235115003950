import React, { memo, useMemo } from 'react'
import { PaymentType } from '@webapps/numeral-ui-core'
import { HOME_PAGE_STYLE_CONFIGURATION } from '@/pages/Home/Home.page.const'
import { LinkWithFilterBy } from '@/components/@misc'
import { useIntl } from 'react-intl'
import { WidgetsServiceLookBehindOffsets } from '@/services'
import { formatNumber } from 'chart.js/helpers'
import { Box, BoxProps } from '@chakra-ui/react'
import { computePaymentCountersFilterByOptions, getNavigationRouteByPaymentType } from '../PaymentCountersWidget.utils'
import { ExtractType } from '@/types'
import { PaymentCountersStatusQueryOptionsMap } from '../PaymentCountersWidget.types'

interface TableCellPaymentsLinkProps extends BoxProps {
    value: number
    objectType: PaymentType
    status: ExtractType<PaymentType, PaymentCountersStatusQueryOptionsMap>
    lookBehindOffset?: WidgetsServiceLookBehindOffsets
}

export const TableCellPaymentsLink: React.FC<TableCellPaymentsLinkProps> = memo(
    ({ value, objectType, status, lookBehindOffset, ...boxProps }) => {
        const intl = useIntl()
        const labelKey = useMemo(() => {
            const valueWithDefault = value ?? 0
            return formatNumber(valueWithDefault, intl.locale)
        }, [value, intl])
        const filterByState = computePaymentCountersFilterByOptions(objectType, status, lookBehindOffset)
        const path = getNavigationRouteByPaymentType(objectType)

        return (
            <Box {...boxProps} textAlign="right">
                <LinkWithFilterBy<typeof filterByState>
                    filterBy={filterByState}
                    labelKey={labelKey}
                    path={path}
                    aria-label={labelKey}
                    title={labelKey}
                    fontSize={HOME_PAGE_STYLE_CONFIGURATION.FONT_SIZE}
                />
            </Box>
        )
    }
)
