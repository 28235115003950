import { FormattedAmount } from '@/components/@misc'
import { BULLET_SEPARATOR } from '@/constants'
import { useBulkActions, usePage } from '@/hooks'
import { RECONCILE_ENTITY_PAGE_CONTEXT, enrichReconciliationTargetObjectsSelection } from '@/pages'
import { useBulkActionsPluralization } from '@/providers'
import {
    computeTotalReconcilableAmount,
    computeTotalRemainingReconciliationAmount,
    getReconcilableAmount
} from '@/services'
import { Payment } from '@/types'
import { Box, Heading, Text } from '@chakra-ui/react'
import { Amount, ApiObjectType, Transaction } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getReconcileEntityFooterContentWithIntl } from './ReconcileEntityFooter.utils'

export const ReconcileEntityFooterContent: React.FC<{ targetObject: ApiObjectType }> = ({ targetObject }) => {
    const intl = useIntl()
    const { getNonPersistedState } = usePage<Payment | Transaction>()
    const reconcileEntityNonPersistedState = getNonPersistedState(RECONCILE_ENTITY_PAGE_CONTEXT)
    const { selection, data } = useBulkActions<typeof reconcileEntityNonPersistedState>()
    const enrichedSelection = useMemo(() => {
        return enrichReconciliationTargetObjectsSelection<typeof reconcileEntityNonPersistedState>(selection, data)
    }, [selection, data])
    const selectedItem = enrichedSelection?.at(0)

    const { formattedObjectType } = useBulkActionsPluralization(targetObject, selection?.size, false)
    const footerDescription = useMemo(() => {
        return intl.formatMessage(
            { id: 'app.reconcile.footer.description' },
            {
                size: selection?.size,
                targetObjectType: formattedObjectType
            }
        )
    }, [selection, formattedObjectType])

    if (reconcileEntityNonPersistedState) {
        const reconcilableAmount: Amount = computeTotalReconcilableAmount<typeof reconcileEntityNonPersistedState>(
            reconcileEntityNonPersistedState,
            enrichedSelection
        )
        const remainingAmount: Amount = computeTotalRemainingReconciliationAmount<
            typeof reconcileEntityNonPersistedState
        >(reconcileEntityNonPersistedState, enrichedSelection)
        const { title, description } = getReconcileEntityFooterContentWithIntl(intl)<
            typeof reconcileEntityNonPersistedState
        >(reconcileEntityNonPersistedState, enrichedSelection)

        const formattedReconcilableAmount = (
            <FormattedAmount amount={reconcilableAmount} currency={reconcileEntityNonPersistedState?.currency} />
        )
        const formattedRemainingAmount = (
            <FormattedAmount amount={remainingAmount} currency={reconcileEntityNonPersistedState?.currency} />
        )
        const hasTitle = globalThis.Boolean(title)
        const hasSelection = globalThis.Boolean(selection?.size)

        return (
            <>
                <Heading as="h3">
                    {(() => {
                        switch (true) {
                            case hasTitle: {
                                return title
                            }
                            case !hasTitle && hasSelection: {
                                return (
                                    <FormattedMessage
                                        id="app.reconcile.footer.reconcilable.title.alternative"
                                        values={{
                                            reconcilableAmount: formattedReconcilableAmount,
                                            remainingAmount: formattedRemainingAmount,
                                            separator: BULLET_SEPARATOR
                                        }}
                                    />
                                )
                            }

                            default: {
                                return (
                                    <FormattedMessage
                                        id="app.reconcile.footer.reconcilable.title"
                                        values={{ reconcilableAmount: formattedReconcilableAmount }}
                                    />
                                )
                            }
                        }
                    })()}
                </Heading>
                <Text color="gray.400">{description || footerDescription}</Text>
            </>
        )
    }

    if (!reconcileEntityNonPersistedState && selectedItem) {
        const reconcilableAmount: Amount = getReconcilableAmount(selectedItem)
        const currency = selectedItem?.currency
        const formattedReconcilableAmount = <FormattedAmount amount={reconcilableAmount} currency={currency} />

        return (
            <>
                <Box fontWeight="bold" fontSize="lg">
                    <FormattedMessage
                        id="app.reconcile.footer.title"
                        values={{ reconcilableAmount: formattedReconcilableAmount }}
                    />
                    <Text>{footerDescription}</Text>
                </Box>
            </>
        )
    }

    return null
}
