import { FormSection, InternalAccountDetailsSummary } from '@/components'
import { InternalAccountInput, TextInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { enabledVirtualInternalAccountFilter } from '@/services'
import { ibanValidator } from '@/utils/@validators'
import { Switch } from '@chakra-ui/react'
import { ExpectedPayment } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

export interface ExpectedPaymentInternalAccountSectionProps {
    isDisabled?: boolean
}

export const ExpectedPaymentInternalAccountSection: React.FC<ExpectedPaymentInternalAccountSectionProps> = ({
    isDisabled
}) => {
    const { getFieldProps, setFieldValue } = useFormikContext<Partial<ExpectedPayment>>()
    const { value: internalAccountId } = getFieldProps('internal_account_id')

    const [useExistingInternalAccount, setUseExistingInternalAccount] = useState(false)

    const onToggleUseExistingInternalAccount = useCallback(() => {
        setFieldValue('internal_account_id', undefined)
        setFieldValue('internal_account', undefined)

        setUseExistingInternalAccount((prevState) => {
            return !prevState
        })
    }, [])

    return (
        <FormSection
            titleId="app.payments.expected_payments.create.form.sections.internal_account.title"
            title={
                <Switch
                    data-testid="toggle-existing-originating-account-switch"
                    size="sm"
                    isChecked={useExistingInternalAccount}
                    onChange={onToggleUseExistingInternalAccount}
                    disabled={isDisabled}>
                    <FormattedMessage id="app.payments.expected_payments.create.form.sections.internal_account.use_existing.label" />
                </Switch>
            }>
            <If condition={useExistingInternalAccount}>
                <InternalAccountInput
                    name="internal_account_id"
                    isPlaceholderDisplayed={true}
                    isAccountVerificationStatusDisplayed={true}
                    customPlaceholderKey="app.payments.expected_payments.create.form.inputs.internal_account.placeholder"
                    queryDataFilter={enabledVirtualInternalAccountFilter}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <InternalAccountDetailsSummary internalAccountId={internalAccountId} />
            </If>

            <If condition={!useExistingInternalAccount}>
                <TextInput
                    name="internal_account.account_number"
                    validator={ibanValidator}
                    customPlaceholderKey="app.common.form.input.internal_account.account_number.placeholder"
                    isDisabled={isDisabled}
                    isRequired={true}
                />
            </If>
        </FormSection>
    )
}
