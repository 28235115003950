import { If, Select, useSearchQuerySelect } from '@/components/@misc'
import { anythingValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { useField } from 'formik'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { usePermissions, useQueryFindAllConnectedAccountsBankNames } from '@/hooks'
import { BankNameInputProps } from './BankNameInput.types'
import { getFormSelectPlaceholderByReactQueryState } from '@/components'
import { BankNameInputSelectOption } from './@components'
import { PERMISSION } from '@/services'
import { TextInput } from '@/components/@inputs'

export const BankNameInput: React.FC<BankNameInputProps> = ({
    name,
    value,
    isRequired,
    isDisabled,
    customLabelKey,
    customLabelValues,
    customPlaceholderKey,
    customPlaceholderValues,
    searchOnMount = false,
    isPlaceholderDisplayed = false,
    isLabelDisplayed = true,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const [field, meta, helpers] = useField({
        name,
        value,
        validate: validator?.(intl, isRequired)
    })
    const { enabled, onFocus } = useSearchQuerySelect(searchOnMount)
    const query = useQueryFindAllConnectedAccountsBankNames({
        enabled: !isDisabled && enabled
    })
    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.${name}.label`
        return intl.formatMessage({ id: labelKey }, customLabelValues)
    }, [name, customLabelKey, intl, customLabelValues])
    const placeholder = useMemo<string | undefined>(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey }, customPlaceholderValues)
        }
        return getFormSelectPlaceholderByReactQueryState(intl, query, isPlaceholderDisplayed)
    }, [customPlaceholderKey, customPlaceholderValues, intl, query, isPlaceholderDisplayed])

    const onChange = useCallback(
        (value: string) => {
            inputProps.onChange?.(value)
            helpers.setValue(value)
        },
        [helpers, inputProps.onChange]
    )

    if (hasPermission(PERMISSION.SETTINGS_VIEW_CONNECTED_ACCOUNTS)) {
        const isInvalid = meta.touched && !!meta.error

        return (
            <FormControl key={name} isInvalid={isInvalid}>
                <If condition={isLabelDisplayed}>
                    <FormLabel htmlFor={name}>
                        <FormattedMessage id={label} />
                    </FormLabel>
                </If>
                <Select<string>
                    {...inputProps}
                    {...field}
                    id={name}
                    options={query.data?.records}
                    placeholder={placeholder}
                    isInvalid={isInvalid}
                    isRequired={isRequired}
                    isDisabled={isDisabled}
                    onFocus={onFocus}
                    isLoading={query.isLoading}
                    isClearable={true}
                    onChange={onChange}
                    components={{
                        Option: BankNameInputSelectOption
                    }}
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
        )
    }

    return <TextInput {...field} {...inputProps} id={name} placeholder={placeholder} validator={validator} />
}
