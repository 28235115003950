import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '@/components/@icons'
import { ExternalAccountVerificationResult, ExternalAccountVerificationResultSchema } from '@webapps/numeral-ui-core'
import { ReactNode } from 'react'

export function getVerificationMessageIconFromResult(result?: ExternalAccountVerificationResult): ReactNode {
    switch (result) {
        case ExternalAccountVerificationResultSchema.enum.valid:
            return <SuccessIcon color="green.500" />
        case ExternalAccountVerificationResultSchema.enum.invalid:
            return <ErrorIcon color="orange.500" />
        case ExternalAccountVerificationResultSchema.enum.impossible_to_verify:
            return <WarningIcon color="red.500" />
        case ExternalAccountVerificationResultSchema.enum.not_verified:
            return <InfoIcon color="blue.500" />
        default:
            return null
    }
}
