import { memo, PropsWithChildren } from 'react'
import { useSearchParams } from 'react-router-dom'
import { QueryParamContext } from './QueryParamsProvider.context'
import { SupportedQueryParam, SupportedQueryParamValue } from './QueryParamsProvider.types'
import { deserializeQueryParams, serializeQueryParamValue } from './QueryParamsProvider.utils'

export const QueryParamsProvider: React.FC<PropsWithChildren> = memo(({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const getQueryParam = (key: SupportedQueryParam): SupportedQueryParamValue => {
        const serializedValue = searchParams.get(key)
        if (!serializedValue) {
            return undefined
        }
        return deserializeQueryParams(serializedValue)
    }

    const setQueryParam = (key: SupportedQueryParam, value: SupportedQueryParamValue): void => {
        const newSearchParams = new URLSearchParams(searchParams)
        const serializedValue = serializeQueryParamValue(value)

        if (!serializedValue) {
            newSearchParams.delete(key)
        } else {
            newSearchParams.set(key, serializedValue)
        }
        setSearchParams(newSearchParams)
    }

    const removeQueryParam = (key: SupportedQueryParam): void => {
        setSearchParams(key)
    }

    return (
        <QueryParamContext.Provider value={{ getQueryParam, setQueryParam, removeQueryParam }}>
            {children}
        </QueryParamContext.Provider>
    )
})
