import { TableHeaderSorter, TableScrollToTop } from '@/components'
import { TABLE_DEFAULT_OPTIONS } from '@/components/Table/Table.const'
import { Flex, Spinner, Table as ChakraUITable, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import {
    Cell,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    Header,
    HeaderGroup,
    TableOptions,
    useReactTable
} from '@tanstack/react-table'
import classNames from 'classnames'
import { merge } from 'lodash'
import { SyntheticEvent, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import './Table.scss'
import { TableProps } from './Table.types'
import { getColumnWidthPercentage, getTableColumnsWithCustomizer, shouldRowGetInViewRef } from './Table.utils'

export function Table<T, C = void>({
    columnCustomizers,
    state,
    onRowClick,
    onScrollToBottom,
    propsTableContainer,
    propsTable,
    propsHeader,
    propsTableBodyProps,
    hasStickyHeader = true,
    hasSmallHeader = false,
    hasRowOptionsEnabled = false,
    hideTableHeader = false,
    isLoading = false,
    ...tableOptions
}: TableProps<T, C>) {
    const { ref: TRowRef, inView: inViewTRow } = useInView({ skip: !hasStickyHeader })
    const allTableOptions = useMemo<TableOptions<T>>(() => {
        return merge(Object.create(null), TABLE_DEFAULT_OPTIONS, tableOptions)
    }, [])

    const tableClassName = classNames('Table', {
        StickyTableHeader: hasStickyHeader,
        NormalTableHeader: !hasStickyHeader
    })

    const tableHeadClassName = classNames('Table-Header', {
        Sorting: allTableOptions.enableSorting,
        Small: hasSmallHeader
    })

    const generatedColumns = useMemo(() => {
        return getTableColumnsWithCustomizer<T, C>(
            state?.columnOrder as string[],
            columnCustomizers,
            hasRowOptionsEnabled
        )
    }, [state, columnCustomizers, hasRowOptionsEnabled])

    const table = useReactTable({
        ...allTableOptions,
        data: tableOptions.data || [],
        columns: generatedColumns,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel()
    })

    const tableSpinner = useMemo(() => {
        if (!isLoading) {
            return null
        }
        return (
            <Flex margin="32px" justifyContent="center">
                <Spinner size="lg" />
            </Flex>
        )
    }, [isLoading])

    useEffect(() => {
        if (inViewTRow) {
            onScrollToBottom?.()
        }
    }, [inViewTRow])

    return (
        <TableContainer className={tableClassName} {...propsTableContainer}>
            <ChakraUITable colorScheme="gray" {...propsTable}>
                {!hideTableHeader && (
                    <Thead className={tableHeadClassName} {...propsHeader}>
                        {table.getHeaderGroups().map((headerGroup: HeaderGroup<T>) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header: Header<T, any>) => {
                                    const isSorted = header.column.getIsSorted()
                                    const classNameTableHeaderText = classNames('Table-Header-Text', {
                                        Sorted: isSorted
                                    })
                                    const onClick = header.column.getToggleSortingHandler()
                                    const { size: widthRatio, minSize } = header.column.columnDef
                                    const numberOfColumns = headerGroup.headers.length
                                    const columnWidthPercentage = getColumnWidthPercentage(numberOfColumns, widthRatio)
                                    const width = columnWidthPercentage ? `${columnWidthPercentage}%` : undefined
                                    const minWidth = minSize ? `${minSize}px` : undefined

                                    return (
                                        <Th
                                            fontWeight="semibold"
                                            key={header.id}
                                            onClick={onClick}
                                            width={width}
                                            minWidth={minWidth}
                                            data-testid={header.id}>
                                            <Flex justifyContent="space-between" color="gray.500">
                                                <Text className={classNameTableHeaderText}>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </Text>
                                                <TableHeaderSorter<T>
                                                    header={header}
                                                    disabled={!allTableOptions.enableSorting}
                                                />
                                            </Flex>
                                        </Th>
                                    )
                                })}
                            </Tr>
                        ))}
                    </Thead>
                )}
                <Tbody {...propsTableBodyProps}>
                    {table.getRowModel().rows.map((row, index, rows) => {
                        const rowBackgroundColor = 'white'
                        const propsTr = {
                            onClick(event: SyntheticEvent) {
                                event.stopPropagation()
                                onRowClick?.(row)
                            },
                            cursor: onRowClick ? 'pointer' : 'default',
                            backgroundColor: rowBackgroundColor,
                            _hover: {
                                backgroundColor: onRowClick ? 'gray.50' : rowBackgroundColor
                            },
                            ref: shouldRowGetInViewRef(rows.length, index) ? TRowRef : null
                        }

                        return (
                            <Tr {...propsTr} key={index}>
                                {row.getVisibleCells().map((cell: Cell<T, any>) => {
                                    return (
                                        <Td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </Tbody>
            </ChakraUITable>
            {tableSpinner}
            {hasStickyHeader && <TableScrollToTop />}
        </TableContainer>
    )
}
